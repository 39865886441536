/* eslint-disable */
/**
 * Created by Alex Shvets on 15.03.2017.
 */

var getScrollBarWidth = function () {
  var outer = $('<div>')
    .css({ visibility: 'hidden', width: 100, overflow: 'scroll' })
    .appendTo('body');
  var widthWithScroll = $('<div>').css({ width: '100%' }).appendTo(outer).outerWidth();
  outer.remove();
  return 100 - widthWithScroll;
};

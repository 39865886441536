const locales = {
  en: {
    guest: {
      menu: {
        btns: {
          // used also on preview user modal
          signIn: 'Sign In',
          join: 'Join now',
          sendForgotPass: 'Send',
          forgotPass: 'Forgot Password',
        },
        titles: {
          forgotPass: 'Forgot password?',
        },
      },
      indexHeader: {
        // used also on preview user modal
        registration: 'Registration',
        agreement: {
          text: 'I agree with',
          link: 'terms of use',
        },
        btns: {
          register: 'Register',
          submit: 'Submit',
          facebookLogin: 'Login with Facebook',
          girlsOnline: 'Women Online',
          girlsGalleries: 'Women Galleries',
        },
        titles: {
          girlsOnline: 'Women Online',
          girlsGalleries: 'Women Galleries',
        },
      },
      headers: {
        topGirls: 'Top Women',
        girlsOnline: 'Women Online',
        lastJoinedGirls: 'Last Joined Women',
        testimonials: 'Those who found their destiny',
        forgotPassword: 'Forgot Password',
        reviews: 'Reviews',
      },
      banners: {
        topIndex: {
          caption: 'Buy credits with',
          discount: '<b>20%</b> discount',
          btn: 'Read More',
        },
        bottomIndex: {
          caption: 'Buy credits with',
          discount: '<b>20%</b> discount',
          btn: 'Read More',
        },
      },
      modals: {
        previewTitle: {
          start: 'Get access <br> to',
          end: "'s profile",
        },
        registerTitle: 'Registration',
        randomAva: 'Random avatar',
        btnOK: 'OK',
      },
      utils: {
        orLogin: 'or login',
      },
    },
    profile: {
      delHeader: 'This page has been deleted',
      delHeaderSub: 'Information is no more available',
      back: 'Back to previous page',
      uploadAva: 'Upload avatar',
      uploadAvaDevice: 'Upload from device',
      updateAva: 'Update avatar',
      uploading: 'Uploading...',
      sendLetter: 'Letter',
      noFill: 'Not filled',
      online: 'Online',
      country: 'Country',
      city: 'City',
      dateBirth: 'Date birth',
      dateBirthM: 'Date birth',
      dateBirthW: 'Date birth',
      marital: 'Marital',
      lvlEng: 'English',
      langs: 'Languages',
      lang: 'Language',
      years: 'years',
      aboutMe: 'About me',
      religion: 'Religion',
      hobbies: 'Interests',
      occupation: 'Occupation',
      flowers: 'Flowers',
      educations: 'Education',
      pet: 'Pets',
      smoke: 'Smoking',
      holidays: 'Holidays',
      alcohol: 'Alcohol',
      goals: 'Goals',
      children: 'Children',
      iLive: 'I live',
      appearance: 'Appearance',
      hairColor: 'Hair color',
      eyesColor: 'Eyes color',
      bodyType: 'Body type',
      height: 'Height',
      weight: 'Weight',
      perfMan: 'Perfect Man',
      perfWoman: 'Perfect woman',
      manType: 'Type of man',
      womanType: 'Type of woman',
      email: 'E-mail',
      phoneNumber: 'Phone Number',
      contacts: 'Contacts',
      editProfile: 'Edit profile',
      mainEdit: 'Main info',
      addPhotos: 'Add photos',
      lbs: 'lbs',
      no: 'Not chosen',
      save: 'Save',
      name: 'Name',
      ageFrom: 'Age from',
      to: 'To',
      weightFrom: 'Weight from',
      heightFrom: 'Height from',
      options: {
        show: 'Show options',
        hide: 'Hide options',
      },
      edit: 'Edit',
      emptyStatus: 'What are you thinking about now?',
      vBody: {
        no: 'Not chosen',
        thin: 'Thin',
        slender: 'Slender',
        athletic: 'Athletic',
        extra: 'Few extra pounds',
        plump: 'Plump',
      },
      vHair: {
        no: 'Not chosen',
        blond: 'Blond',
        blonde: 'Blonde',
        brown: 'Brown',
        redhead: 'Redhead',
        brunette: 'Brunette',
        colored: 'Colored',
      },
      vEyes: {
        no: 'Not chosen',
        black: 'Black',
        green: 'Green',
        gray: 'Gray',
        brown: 'Brown',
        lightBrown: 'Light-brown',
        blue: 'Blue',
      },
      vInches: {
        no: 'Not chosen',
        v: '{0} inches',
      },
      vFeet: {
        no: 'Not chosen',
        v: '{0} feet',
      },
      vMarital: {
        no: 'Not chosen',
        single: 'Single',
        never: 'Never been married',
        divorced: 'Divorced',
        widow: 'Widow',
      },
      vLvlLangs: {
        lvl: 'Lvl',
        no: 'Not chosen',
        basic: 'Basic',
        intermediate: 'Intermediate',
        good: 'Good',
        fluent: 'Fluent',
      },
      vLangs: {
        no: 'Not chosen',
        sp: 'Spanish',
        fr: 'French',
        pt: 'Portuguese',
        ge: 'German',
      },
      vReligions: {
        no: 'Not chosen',
        christianity: 'Christianity',
        catholicism: 'Catholicism',
        buddhism: 'Buddhism',
        hinduism: 'Hinduism',
        islam: 'Islam',
        other: 'Other',
      },
      vEducation: {
        no: 'Not chosen',
        mSchool: 'Middle School',
        hSchool: 'High school',
        student: 'Student',
        bachelor: "Bachelor's Degree",
        master: "Master's Degree",
        doctor: 'Doctoral Level',
      },
      vSmoking: {
        no: 'Not chosen',
        not: 'Not smoke',
        rarely: 'Rarely',
        often: 'Often',
      },
      vAlcohol: {
        no: 'Not chosen',
        not: 'Not drink',
        rarely: 'Rarely',
        often: 'Often',
        occasionally: 'Occasionally',
      },
      vChildren: {
        no: 'Not chosen',
        myNot: 'No children',
        not: 'No children',
        want: 'No but I want to have children',
        have: 'Have children',
        haveSep: 'Have children, but living separately',
        adult: 'Have adult children',
      },
      vAccommodation: {
        no: 'Not chosen',
        parents: 'With parents',
        neighbors: 'With neighbors',
        dorm: 'In dorm',
        rent: 'Separately (rented housing)',
        own: 'Separately (in own housing)',
      },
      vPets: {
        dog: 'Dog',
        cat: 'Cat',
        hamster: 'Hamster',
        fish: 'Aquarium fish',
        parrot: 'Parrot',
        turtle: 'Turtle',
        snake: 'Snake',
        iguana: 'Iguana',
        chinchilla: 'Chinchilla',
        rabbit: 'Rabbit',
      },
      vGoals: {
        friendship: 'Friendship',
        communication: 'Communication',
        journey: 'Journey',
        flirt: 'Flirtation',
        meeting: 'Meeting',
        family: 'Family',
        children: 'Children',
      },
      vFlowers: {
        redRoses: 'Red roses',
        whiteRoses: 'White roses',
        pinkRoses: 'Pink roses',
        gerbera: 'Gerberas',
        chrysanthemum: 'Chrysanthemums',
      },
      vHolidays: {
        birthday: 'Birthday',
        christmas: 'Christmas day',
        newYear: "New Year's day",
        easter: 'Easter',
        valentine: "Valentine's day",
        halloween: 'Halloween',
        womensDay: "Woman's day",
      },
      errors: {
        failHeight: 'Incorrect height. Fill or clear values',
        failCountry: 'Incorrect country. Verify your input',
        failCity: 'The City field may not be greater than 30 characters',
        failAge:
          'This fields must me greater than 18 and less than 100. Min age must me less then or equal to greater',
        failHeight2:
          'Fill fields with data or clear it. Min age must be less than or equal to max age',
        failWeight:
          'Fill fields with data or clear it. Min weight must be less than or equal to max weight and greater than or equal to 0',
        failStatus: 'Maximum characters for status is 160',
        failBuy: 'You can’t buy contacts for now',
        noPhotos: 'You have no photos yet.',
      },
      vContact: {
        buy: 'Buy contact',
        show: 'Show contact',
        letters: 'Write {0} letters to {1}', // num, name
        chat: 'Spend {0} minutes in chat with {1}', // num, name
        get: "to get {0}'s contact info", // name
        charge: '{0} credits will be charged', // amount
        process: 'In process',
        reject: 'Rejected',
        verification: 'Verification',
        accept: 'Accept',
      },
    },
    sidebar: {
      men: 'Men',
      women: 'Women',
      chat: 'Go to chat',
      mail: 'Mail',
      winks: 'Winks',
      favorites: 'Favorites',
      bookmarks: 'Bookmarks',
      guests: 'Guests',
      gifts: 'Gifts',
      news: 'News',
      notifications: 'Notifications',
      orders: 'Orders history',
      settings: 'Settings',
      payments: 'Payments',
      logout: 'Logout',
    },
    actionPages: {
      utils: {
        markAll: 'Mark all as viewed',
        all: 'All',
        minutes: 'min',
        serialNumber: 'S/N',
        spentOn: 'Spent on',
        type: 'Type',
        spentPay: 'Spent / Pay',
        date: 'Date',
        quantity: 'Quantity',
        joined: 'joined', // *Name* joined *RedBini*
        buy: 'Buy',
        pckName: 'Pack name',
        credits: 'Credits',
        price: 'Price',
        buyContacts: 'Buy Contacts',
        buyCredits: 'Buy {0} credits',
        startUpload: 'Start upload',
        addPhotos: 'Add <span>photos</span>',
        loadPhoto: 'Load photo',
        namedAlbum: '{0} album',
        allPhotos: 'All photos',
        albums: 'Albums',
        gift: 'Gift',
        quantityS: 'Q-ty',
        sum: 'Sum',
        receiver: 'Receiver',
        sender: 'Sender,',
        status: 'Status',
        total: 'Total',
        more: 'More',
        letter: 'Letter',
      },
      titles: {
        myWinks: 'My winks',
        winksIsent: 'Winks I sent',
        myFavs: 'My favorites',
        followers: 'Followers',
        addCredits: 'Add credits',
        orders: 'Orders',
        notifications: 'Notifications',
        news: 'News',
        mail: 'Mail',
        payments: 'Payments',
        paymentsHis: 'Payments history',
        photos: 'Photos',
        gallery: 'Gallery',
        uploadPhotos: 'Photos upload',
        gifts: 'Gifts',
        purchasedList: 'Saved collections',
        received_gift: 'Received',
        sent_gift: 'Sent',
      },
      errors: {
        noWinks: "For now, nobody doesn't send you wink",
        noSentWinks: "For now, you haven't send wink to somebody",
        noGuests: 'For now, nobody has visited your page',
        noBookmarks: "For now, you haven't added some user to bookmarks",
        noFavs: "For now, you haven't added some user to favorites",
        noFavsRequests: 'For now, no requests found',
        noSearchResults:
          'For now, no results found with your request. Check your request or write something else.',
        incorrectDateSettings: 'Incorrect date. Clear or fill with data',
        noPhotos: 'There are no photos in your albums | There are no photos in this album',
        maxFileSize: 'Max file size',
        noSelPhotos: 'No photos selected',
        noData: 'No data to display',
        noMatch: 'No match found. Check your input',
        errGift: '{0} can not get a gift at this moment. Credits was returned', // name
        noGifts: 'Your list of gifts is empty',
        noNews: 'No news for you available',
        noNotifications: 'No exist notifications yet',
        noOrders: 'No exist orders yet',
        noPayments: 'No exist payments history yet',
        limitReached: 'Limit reached',
      },
      search: {
        age: 'Age from {0} to {1}',
        height: 'Height from {0} to {1}',
        online: 'Online',
        new: 'Last joined',
        cam: 'With webcam',
        camHD: 'Cam HD',
        title: 'Search',
        advanced: 'Advanced',
        result: 'Search results',
        sWomen: 'Search women',
        sMen: 'Search men',
        submit: 'Search',
        noMatch: 'No match found',
        noLoad: 'No more data to load',
      },
      orderTypes: {
        chatText: 'Text chat',
        chatVideo: 'Video chat',
        chatPrivate: 'Private chat',
        letter: 'Write letter',
        info: 'Contacts',
        gift: 'Send gift',
        photoBuy: 'Buying photos',
      },
      news: {
        info: {
          uploaded: 'uploaded',
          newPhotos: 'new photo | new photos',
        },
        viewAll: 'View all',
        hide: 'Hide',
        birthdayIconAlt: 'Birthday Icon',
        bdCaption: 'They celebrate their birthday',
      },
    },
    toasts: {
      wink: '<span class="user-name">{0}</span> sent you a wink',
      favorite: '<span class="user-name">{0}</span> added you to favorites',
      bookmark: '<span class="user-name">{0}</span> added you to bookmarks',
      letter: '<span class="user-name">{0}</span> sent you a letter',
      guest: '<span class="user-name">{0}</span> visited your profile',
      gift: '<span class="user-name">{0}</span> sent you a gift',
    },
    alerts: {
      verificationError: 'Incorrect code. Please try one more time',
      paySystemLinkError: 'An error happened during pay link generation.',
      someErr: 'Something goes wrong during request execution',
      requestError: 'There is error happened during request execution. Please, contact our support',
      letterSuccess: 'Letter sent',
      delSuccess: 'Successful deleting',
      avatarSet: 'Avatar was successfully updated',
      deletePhoto: 'Photo was deleted',
      giftSentSuccess: 'Gift Was already sent',
      wrongFile: 'Wrong type of file',
      dispatchSuccess: 'Dispatch letter sent on moderation',
      dispatchEditSuccess: 'Dispatch letter edited successfully',
      dispatchDelSuccess: 'Dispatch letter deleted successfully',
      settingsSuccess: 'Settings saved',
      contactRequestSuccess: 'Request sent',
      contactRequestFail: 'You already sent request earlier',
      cannotLoadProfile: "Unable to load users's profile",
      cancelFBLogin: 'Login via Facebook canceled',
      canSetAvaFromPublic: 'Only photos from public album can be set up as avatar',
      notEnoughTrust: 'You have no enough trust level to create dispatch',
      incorrectImgResMax: 'Incorrect image resolution. Max resolution is {0}x{1} px',
      incorrectImgResMin: 'Incorrect image resolution. Min resolution is {0}x{1} px',
      corruptedFile: 'Corrupted file. Upload failed',
      incorrectImgExt: 'Incorrect image extension. Allowed extensions are: .png, .jpg, .jpeg',
      incorrectImgRatio: 'Incorrect image aspect ratio',
      failUpload: 'Upload failed. Please, try again later',
      incorrectFileSize: 'File {0} exceeded the maximum allowed file size ({1}MB)',
      selectDisabledMail: "You can't select unread mail",
      cantDelUnAnswMail: "You can't delete unanswered mail",
      defaultText:
        'The photo must have a resolution of at least 500x500px and no more than 3000x3000px. The size should not exceed 2Mb.',
    },
    gifts: {
      pack: 'Packaging',
      reject: 'Reject',
      progress: 'On the way',
      success: 'Delivered',
      payed: 'Payed',
      notPayed: 'Unpaid',
    },
    notifications: {
      wink: 'sent you a wink',
      favorite: 'added you to favorites',
      bookmark: 'added you to bookmarks',
      letter: 'sent you a letter',
      guest: 'visited your profile',
      gift: 'sent you a gift',
    },
    mail: {
      utils: {
        write: 'write',
        all: 'All',
        send: 'Send',
        delete: 'Delete',
        attach: 'Attach',
        frExist: 'From albums',
        frDevice: 'From device',
        back: 'Back',
        attachedImages: 'Attached image | Attached images',
        dispatch: 'Dispatch', // рассылка,
        dispCreated: 'Created',
        filter: 'Filter',
        online: 'Online',
        new: 'New',
        birthdays: 'Birthdays',
        blackList: 'Black list',
        writeLetter: 'Write a letter',
        response: 'My response',
        symbCount: 'Symbols count',
        startSearch: 'Search',
        reply: 'Reply',
        cancel: 'Cancel',
        createNew: 'Creating new letter',
        answerOn: 'Reply on letter',
      },
      sort: {
        default: 'Sort by',
        byNew: 'New',
        senderAZ: 'Sender - A-Z',
        senderZA: 'Sender - Z-A',
        dateASK: 'Date - Ascending',
        dateDESK: 'Date - Descending',
        nameAZ: 'Name - A-Z',
        nameZA: 'Name - Z-A',
        priceASC: 'Ascending price',
        priceDESC: 'Descending price',
      },
      select: {
        default: 'Select',
        all: 'Select all',
        unread: 'Select new',
        read: 'Select read',
        answered: 'Select answered',
        unanswered: 'Select unanswered',
        status: 'Status',
      },
      menu: {
        inbox: 'Inbox',
        sent: 'Sent',
        dialogs: 'Dialogs',
      },
      placeholders: {
        to: 'To',
        subject: 'Subject',
        message: 'Message',
        greetings: 'Greetings',
        searchById: 'Search by ID',
      },
      errors: {
        noUser: 'No user selected. Choose user from list',
        noMail: 'For now, you have no mail in this category',
      },
      dispatch: {
        date: 'Date',
        theme: 'Theme',
        quantity: 'Quantity',
        status: 'Status',
        sent: 'Sent',
        declined: 'Declined',
        edit: 'Edit',
        onModerate: 'On moderation',
        free: 'On the way',
        error: 'You have violated the rules of distribution',
        errNoFavs:
          'You have no added favorite users yet. And that\'s why option "Favorites" is disabled',
        errNoBookmark:
          'You have no added users in your bookmarks list yet. That\'s why option "Bookmarks" is disabled',
        cantSelectBirthday: 'You can\'t choose this option because of enabled "Online" option',
        cantSelectOnline: 'You can\'t choose this option because of enabled "Birthdays" option',
        cantDesc: 'You can\'t choose both "Online" and "Birthdays" options',
      },
    },
    shared: {
      common: {
        copyrights: 'All rights reserved',
        age: 'Age',
        city: 'City',
        winks: 'Winks',
        bookmarks: 'Bookmarks',
        favourites: 'Favorites',
        photos: 'Photos',
        myPhotos: 'My photos',
        allPhotos: 'All photos',
        guests: 'Guests',
        chat: 'Chat',
        letter: 'Letter',
        ordersHistory: 'Orders history',
        projectName: 'RedBini',
        goChat: 'Go to chat!',
        new: 'new',
        sortBy: 'Sort by',
        edit: 'Edit',
      },
      utils: {
        noResultMatch: 'No match with request {0}',
        photoPreview: 'Photo preview',
        text: 'Text',
        video: 'Video',
        kg: '{0} kg',
        cm: '{0} cm',
        lbs: '{0} lbs',
        hot: 'HOT',
      },
      btn: {
        lazyLoad: 'Load ({0}) more',
        chatText: 'Text chat',
        chatVideo: 'Video chat',
        invitations: 'Invitations',
      },
      alts: {
        photo: "'s photo",
        ava: "'s avatar",
      },
      links: {
        sendWink: 'Send Wink',
        addBookmark: 'Add to Bookmarks',
        addFavorites: 'Add to Favorites',
        startChat: 'Start Chat',
        sendMail: 'Send Mail',
        sendGift: 'Send Gift',
        viewProfile: 'View profile',
      },
      headers: {
        myPerfectMan: 'My perfect Man',
        aboutMe: 'About me',
        newMembers: 'New members',
        wantChat: 'They want to chat',
      },
      titles: {
        sendWink: 'Send Wink',
        addFavorites: 'Add to Favorites',
        addBookmark: 'Add to Bookmarks',
        startChat: 'Start Chat',
        sendMail: 'Send Mail',
        sendGift: 'Send Gift',
      },
      menu: {
        about: 'About',
        home: 'Home',
        cookie: 'Cookie Policy',
        contactUs: 'Contact Us',
        terms: 'Terms of use',
        faq: 'F.A.Q.',
        privacy: 'Privacy Policy',
        antiScam: 'Anti-Scam Policy',
        refund: 'Refund Policy',
        titles: {
          logo: 'RedBini',
          home: 'Home',
          about: 'About',
          faq: 'F.A.Q.',
          contactUs: 'Contact Us',
        },
        settings: 'Settings',
        myPage: 'My page',
        logout: 'Logout',
      },
      placeholders: {
        email: 'Email',
        password: 'Password',
        name: 'Name',
        license: 'License agreement',
        nameId: 'Name or ID',
      },
    },
    settings: {
      title: 'Settings',
      back: 'Back',
      general: 'General',
      notifications: 'Notifications',
      email: 'Email',
      password: 'Password',
      edit: 'Edit',
      newPassword: 'New password',
      repeatPswd: 'Repeat password',
      submit: 'Send',
      save: 'Save',
      sys: 'System',
      notifSys: 'System notifications',
      usrs: 'Users',
      notifWink: 'Wink notifications',
      notifFavs: 'Favorite notifications',
      notifGuest: 'Guest notifications',
      notifLetter: 'Letter notifications',
      chat: 'Chat',
      chatSounds: 'Chat sounds',
      notifChat: 'Notifications from chat (invites, etc...)',
      msgActChat: 'Current dialog',
    },
    gallery: {
      setAva: 'Set as avatar',
      del: 'Delete photo',
      rotate: 'Rotate',
      delConfirm: 'Are you sure you want to delete this photo?',
      startUpdate: 'Sending new photo',
      updated: 'Photo successfully updated',
      updateFailed: 'Update failed. Please, contact our support',
      preloader: 'Entering edit mode...',
    },
    feedback: {
      title: 'Feedback',
      submitMsg:
        'Your inquiry has been submitted. Customer inquiries submitted during business hours are' +
        ' normally answered in 24-48 hours after they were received. You will receive a response ' +
        'to your Email',
      email: 'Your email',
      subject: 'Subject',
      msg: 'Message',
      limitFiles: 'You can attach {0} files maximum', // amount
      attach: 'Attach',
      submit: 'Send',
      heading: 'We are social',
      address: 'Address',
    },
    giftModal: {
      categories: 'Categories',
      flowers: 'Flowers',
      jewelry: 'Jewelry',
      perfume: 'Perfumes',
      elDevices: 'Electronic devices',
      toys: 'Toys',
      food: 'Food & beverage',
      tickets: 'Tickets & certificates',
      baloons: 'Baloons',
      clothing: 'Clothing',
      other: 'Other',
      recommended: 'Recommended',
      all: 'All',
      giftName: 'Gift name',
      send: 'Send',
      tipHead: 'Price includes',
      air: 'Air delivery',
      courier: 'Courier delivery',
      clear: 'Customs clearance',
      insurance: 'Insurance',
      to: 'To',
    },
    creditsModal: {
      heading: 'Credits level is low',
      body: 'You can choose your credit plan on credits page',
      buy: 'Buy credits',
    },
    refundModal: {
      heading: 'Please fill the reason of refund',
      body:
        'You can make a request to refund {package} package price ({price} EUR). ' +
        '\nBut we have to know the reason of refund, to improve our systems and offers. \nThanks.',
      submit: 'Submit refund',
      cancel: 'Cancel',
    },
    paySystemModal: {
      heading: 'Choose pay system',
      accept: 'Accept',
    },
    searchGuest: {
      title: 'Search women',
      online: 'Online',
      cam: 'With webcam',
      camHD: 'Cam HD',
      nameId: 'Name or ID',
      submit: 'Search',
      newUsers: 'New Women',
      age: 'Age from {0} to {1}', // num, num
      height: 'Height from {0} to {1}', // num, num
      resultsTitle: 'Search results ({0})',
      noData: 'No more data to load',
    },
    notFound: {
      title: 'Page not found',
      err: '404',
      tip: 'Try to visit',
      link: 'Main page',
    },
    forgotPswd: {
      title: 'Password reset',
      tip: 'Enter password',
      pswd: 'New password',
      repPswd: 'Repeat password',
      submit: 'Send',
      notEqual: 'Passwords are not equal',
    },
    emailApprove: {
      title: 'Email confirmation',
      tip: 'Enter password',
      pswd: 'New password',
      repPswd: 'Repeat password',
      submit: 'Send',
      notEqual: 'Passwords are not equal',
    },
    uploadModal: {
      status: {
        uploading: 'Uploading image...',
        settingAva: 'Updating avatar...',
      },
    },
    zodiac: {
      aqu: 'Aquarius',
      pis: 'Pisces',
      ari: 'Aries',
      tau: 'Taurus',
      gem: 'Gemini',
      can: 'Cancer',
      leo: 'Leo',
      vir: 'Virgo',
      lib: 'Libra',
      sco: 'Scorpio',
      sag: 'Sagittarius',
      cap: 'Capricorn',
    },
  },
  ru: {
    guest: {
      menu: {
        btns: {
          // used also on preview user modal
          signIn: 'Вход',
          join: 'Регистрация',
          sendForgotPass: 'Отправить',
          forgotPass: 'Забыл пароль',
        },
        titles: {
          forgotPass: 'Забыли пароль?',
        },
      },
      indexHeader: {
        // used also on preview user modal
        registration: 'Регистрация',
        agreement: {
          text: 'Я соглашаюсь с',
          link: 'лицензионным соглашением',
        },
        btns: {
          register: 'Зарегистрироваться',
          submit: 'Отправить',
          facebookLogin: 'Войти с Facebook',
          girlsOnline: 'Девушки онлайн',
          girlsGalleries: 'Галерея',
        },
        titles: {
          girlsOnline: 'Девушки онлайн',
          girlsGalleries: 'Галерея',
        },
      },
      headers: {
        topGirls: 'ТОП девушек',
        girlsOnline: 'Девушки онлайн',
        lastJoinedGirls: 'Новые девушки',
        testimonials: 'Те, кто обрели свою судьбу',
        forgotPassword: 'Забыл пароль',
        reviews: 'Отзывы',
      },
      banners: {
        topIndex: {
          caption: 'Купить кредиты с',
          discount: '<b>20%</b> скидкой',
          btn: 'Подробнее',
        },
        bottomIndex: {
          caption: 'Купить кредиты с',
          discount: '<b>20%</b> скидкой',
          btn: 'Подробнее',
        },
      },
      modals: {
        previewTitle: {
          start: 'Покучить доступ <br> к',
          end: ' профилю',
        },
        registerTitle: 'Регистрация',
        randomAva: 'Случайный аватар',
        btnOK: 'ОК',
      },
      utils: {
        orLogin: 'или войдите',
      },
    },
    profile: {
      delHeader: 'Эта страница была удалена',
      delHeaderSub: 'Информация о пользователе больше недоступна',
      back: 'Перейти на предыдущую страницу',
      uploadAva: 'Загрузить аватар',
      uploadAvaDevice: 'Загрузить с устройства',
      updateAva: 'Обновить аватар',
      uploading: 'Загрузка...',
      sendLetter: 'Письмо',
      noFill: 'Не заполнено',
      online: 'Онлайн',
      country: 'Страна',
      city: 'Город',
      dateBirth: 'Дата рождения',
      dateBirthM: 'Родился',
      dateBirthW: 'Родилась',
      marital: 'Отношения',
      lvlEng: 'Английский',
      langs: 'Языки',
      lang: 'Язык',
      years: 'лет',
      aboutMe: 'Обо мне',
      religion: 'Регилия',
      hobbies: 'Интересы',
      occupation: 'Работа',
      flowers: 'Цветы',
      educations: 'Образование',
      pet: 'Питомцы',
      smoke: 'Курение',
      holidays: 'Праздники',
      alcohol: 'Алкоголь',
      goals: 'Цели',
      children: 'Дети',
      iLive: 'Я живу',
      appearance: 'Внешность',
      hairColor: 'Волосы',
      eyesColor: 'Глаза',
      bodyType: 'Фигура',
      height: 'Рост',
      weight: 'Вес',
      perfMan: 'Идеальный мужчина',
      perfWoman: 'Идеальная девушка',
      manType: 'Тип мужчины',
      womanType: 'Тип девушки',
      email: 'E-mail',
      phoneNumber: 'Номер телефона',
      contacts: 'Контакты',
      editProfile: 'Редактирование профиля',
      mainEdit: 'Основная информация',
      addPhotos: 'Добавить фотографии',
      lbs: 'фунты',
      no: 'Не выбрано',
      save: 'Save',
      name: 'Имя',
      ageFrom: 'Возраст от',
      to: 'До',
      weightFrom: 'Вес от',
      heightFrom: 'Рост от',
      options: {
        show: 'Показать варианты',
        hide: 'Спрятать',
      },
      edit: 'Редактировать',
      emptyStatus: 'О чем Вы сейчас думаете?',
      vBody: {
        no: 'Не выбрано',
        thin: 'Худой',
        slender: 'Стройное',
        athletic: 'Спортивное',
        extra: 'Несколько лишних кг',
        plump: 'Полный',
      },
      vHair: {
        no: 'Не выбрано',
        blond: 'Блонд',
        blonde: 'Блонд',
        brown: 'Коричнывые',
        redhead: 'Рыжие',
        brunette: 'Брюнет',
        colored: 'Цветные',
      },
      vEyes: {
        no: 'Не выбрано',
        black: 'Черные',
        green: 'Зелёные',
        gray: 'Серые',
        brown: 'Карие',
        lightBrown: 'Светло-карие',
        blue: 'Голубые',
      },
      vInches: {
        no: 'Не выбрано',
        v: '{0} дюймов',
      },
      vFeet: {
        no: 'Не выбрано',
        v: '{0} футов',
      },
      vMarital: {
        no: 'Не выбрано',
        single: 'Свободен',
        never: 'Никогда не был женат',
        divorced: 'В разводе',
        widow: 'Вдовец',
      },
      vLvlLangs: {
        lvl: 'Уровень',
        no: 'Не выбрано',
        basic: 'Базовый',
        intermediate: 'Средний',
        good: 'Хороший',
        fluent: 'Свободный',
      },
      vLangs: {
        no: 'Не выбрано',
        sp: 'Испанский',
        fr: 'Французский',
        pt: 'Португальский',
        ge: 'Немецкий',
      },
      vReligions: {
        no: 'Не выбрано',
        christianity: 'Христианство',
        catholicism: 'Католизм',
        buddhism: 'Буддизм',
        hinduism: 'Идуизм',
        islam: 'Ислам',
        other: 'Другое',
      },
      vEducation: {
        no: 'Не выбрано',
        mSchool: 'Средняя школа',
        hSchool: 'Высшая школа',
        student: 'Студент',
        bachelor: 'Степень бакалавра',
        master: 'Степень магистра',
        doctor: 'Докторская степень',
      },
      vSmoking: {
        no: 'Не выбрано',
        not: 'Не курю',
        rarely: 'Редко',
        often: 'Часто',
      },
      vAlcohol: {
        no: 'Не выбрано',
        not: 'Не пью',
        rarely: 'Редко',
        often: 'Часто',
        occasionally: 'Время от времени',
      },
      vChildren: {
        no: 'Не выбрано',
        myNot: 'Нет детей',
        not: 'Без детей',
        want: 'Нет, но я хочу',
        have: 'Есть',
        haveSep: 'Есть, но живут отдельно',
        adult: 'Уже взрослые',
      },
      vAccommodation: {
        no: 'Не выбрано',
        parents: 'С родителями',
        neighbors: 'С соседями',
        dorm: 'В общежитии',
        rent: 'Арендую жилье',
        own: 'Собственное жилье',
      },
      vPets: {
        dog: 'Собака',
        cat: 'Кот',
        hamster: 'Хомячок',
        fish: 'Аквариумные рыбки',
        parrot: 'Попугай',
        turtle: 'Черепашка',
        snake: 'Змея',
        iguana: 'Игуана',
        chinchilla: 'Шиншилла',
        rabbit: 'Кролик',
      },
      vGoals: {
        friendship: 'Дружба',
        communication: 'Общение',
        journey: 'Путешествие',
        flirt: 'Флирт',
        meeting: 'Встреча',
        family: 'Семья',
        children: 'Дети',
      },
      vFlowers: {
        redRoses: 'Красные розы',
        whiteRoses: 'Белые розы',
        pinkRoses: 'Розовые розы',
        gerbera: 'Герберы',
        chrysanthemum: 'Хризантемы',
      },
      vHolidays: {
        birthday: 'День рождения',
        christmas: 'Рождество',
        newYear: 'Новый Год',
        easter: 'Пасха',
        valentine: 'День св. Валентина',
        halloween: 'Хеллоуин',
        womensDay: 'Женский день',
      },
      errors: {
        failHeight: 'Неправильный рост. Заполните поля или очистите их',
        failCountry: 'Неправильная страна. Проверьте ввод',
        failCity: 'Поле Город не может содержать более 30 символов',
        failAge:
          'Эти поля должны быть больше 18 и меньше 100. Минимальный возраст должен быть меньше или равным максимальному',
        failHeight2:
          'Заполните поля иди очистите их. Минимальный рост должен быть меньше или равнным максимальному',
        failWeight:
          'Заполните поля или очистите их. Минимальный вес должен быть меньше или равен максимальному, и больше нуля.',
        failStatus: 'Максимальное количество символов для статуса - 160',
        failBuy: 'Вы не можете купить контакты сейчас',
        noPhotos: 'Вы не загрузили ни одной фотографии.',
      },
      vContact: {
        buy: 'Купить контакты',
        show: 'Показать контакты',
        letters: 'Напишите {0} писем к {1}', // num, name
        chat: 'Проведите {0} минут в чате с {1}', // num, name
        get: 'чтобы получить контакты {0}', // name
        charge: '{0} кредитов будет снято', // amount
        process: 'В процессе',
        reject: 'Отменено',
        verification: 'Утверждение',
        accept: 'Подтверждено',
      },
    },
    sidebar: {
      men: 'Мужчины',
      women: 'Девушки',
      chat: 'В чат',
      mail: 'Письма',
      winks: 'Подмигивания',
      favorites: 'Фавориты',
      bookmarks: 'Закладки',
      guests: 'Гости',
      gifts: 'Подарки',
      news: 'Новости',
      notifications: 'Уведомления',
      orders: 'История заказов',
      settings: 'Настройки',
      payments: 'Платежи',
      logout: 'Выход',
    },
    actionPages: {
      utils: {
        markAll: 'Отметить всё как просмотренные',
        all: 'Все',
        minutes: 'минуты',
        serialNumber: 'С/Н',
        spentOn: 'Потрачено на',
        type: 'Тип',
        spentPay: 'Уплачено',
        date: 'Дата',
        quantity: 'Количество',
        joined: 'присоединился к', // *Name* joined *RedBini*
        buy: 'купить',
        pckName: 'Название',
        credits: 'Кредиты',
        price: 'Цена',
        buyContacts: 'Купить контакты',
        buyCredits: 'Купить {0} кредитов',
        startUpload: 'Начать загрузку',
        addPhotos: 'Добавить фотографии',
        loadPhoto: 'Загрузить фотографии',
        namedAlbum: '{0} альбом',
        allPhotos: 'Все фотографии',
        albums: 'Альбомы',
        gift: 'Подарок',
        quantityS: 'Кол-во',
        sum: 'Сумма',
        receiver: 'Получатель',
        sender: 'Отправитель',
        status: 'Статус',
        total: 'Всего',
        more: 'Больше',
        letter: 'Письмо',
      },
      titles: {
        myWinks: 'Полученные',
        winksIsent: 'Отправленные',
        myFavs: 'Фавориты',
        followers: 'Подписчики',
        addCredits: 'Добавить кредиты',
        orders: 'Заказы',
        notifications: 'Уведомления',
        news: 'Новости',
        mail: 'Письма',
        payments: 'Платежи',
        paymentsHis: 'История платежей',
        photos: 'Фотографии',
        gallery: 'Галерея',
        uploadPhotos: 'Загрузка фотографий',
        gifts: 'Подарки',
        purchasedList: 'Сохраненные фотографии',
      },
      errors: {
        noWinks: 'Пока что никто не отправил Вам подмигивание',
        noSentWinks: 'Пока что Вы никому не подмигнули',
        noGuests: 'Пока что никто не посетил Ващу страницу',
        noBookmarks: 'Вы не добавили ни одного пользователя в закладки',
        noFavs: 'Вы не добавили ни одного пользователя в Фавориты',
        noFavsRequests: 'Заявок не найдено',
        noSearchResults:
          'Нет результатов по Вашему запросу. Проверьте запрос или напишите что-нибудь другое',
        incorrectDateSettings: 'Неправильная дата Очистите поле или заполните полностью',
        noPhotos: 'В альбомах нет фотографий | В этом альбоме еще нет фотографий',
        maxFileSize: 'Максимальный размер файла',
        noSelPhotos: 'Фотографии не выбраны',
        noData: 'Нет данных для отображения',
        noMatch: 'Совпадений не найдено',
        errGift: '{0} не может получить подарок. Кредиты возвращены', // name
        noGifts: 'Ваш список подарков пуст',
        noNews: 'Новостей нет',
        noNotifications: 'Вы не получали никаких уведомлений',
        noOrders: 'Вы ничего не заказывали',
        noPayments: 'У вас нет истории пополнений',
        limitReached: 'Достигнут лимит',
      },
      search: {
        age: 'Возраст от {0} до {1}',
        height: 'Рост от {0} до {1}',
        online: 'В сети',
        new: 'Новые',
        cam: 'С камерой',
        camHD: 'HD камера',
        title: 'Поиск',
        advanced: 'Расширенный',
        result: 'Результаты поиска',
        sWomen: 'Поиск девушек',
        sMen: 'Поиск мужчин',
        submit: 'Искать',
        noMatch: 'Совпадений не найдено',
        noLoad: 'Загружать больше нечего',
      },
      orderTypes: {
        chatText: 'Текстовый чат',
        chatVideo: 'Видеочат',
        chatPrivate: 'Приватный чат',
        letter: 'Написание письма',
        info: 'Контакты',
        gift: 'Отправка подарка',
        photoBuy: 'Покупка фотографии',
      },
      news: {
        info: {
          uploaded: 'загружено',
          newPhotos: 'новая фотография | новых фотографий',
        },
        viewAll: 'Просмотреть всё',
        hide: 'Спрятать',
        birthdayIconAlt: 'Иконка дня рождения',
        bdCaption: 'Сегодня у них день рождения',
      },
    },
    toasts: {
      wink: '<span class="user-name">{0}</span> подмигнул Вам',
      favorite: '<span class="user-name">{0}</span> добавил Вас в фавориты',
      bookmark: '<span class="user-name">{0}</span> добавил Вас в закладки',
      letter: '<span class="user-name">{0}</span> отправил Вам письмо',
      guest: '<span class="user-name">{0}</span> посетил Вашу страницу',
      gift: '<span class="user-name">{0}</span> отправил Вам подарок',
    },
    alerts: {
      verificationError: 'Неверный код. Пожалуйста, попробуйте еще раз',
      paySystemLinkError: 'Случилась ошибка во время создания ссылки на оплату.',
      someErr: 'Что-то пошло не так во время выполнения запроса',
      requestError:
        'Во время выполнения запроса произошла ошибка. Пожалуйста, обратитесь в нашу службу поддержки',
      letterSuccess: 'Письмо отправлено',
      delSuccess: 'Успешно удалено',
      avatarSet: 'Аватар успешно обновлен',
      deletePhoto: 'Фотография удалена',
      giftSentSuccess: 'Подарок уже отправлен',
      wrongFile: 'Неподдерживаемый тип файла',
      dispatchSuccess: 'Рассылочное письмо отправлено на модерацию',
      dispatchEditSuccess: 'Рассылочное письмо успешно отредактировано',
      dispatchDelSuccess: 'Рассылочное письмо успешно удалено',
      settingsSuccess: 'Настройки сохранены',
      contactRequestSuccess: 'Запрос отправлен',
      contactRequestFail: 'Вы уже отправляли запрос на получение контактов',
      cannotLoadProfile: 'Невозможно загрузить профиль пользователя',
      cancelFBLogin: 'Вход через Facebook отменен',
      canSetAvaFromPublic:
        'Только фотографии из публичного альбома могут быть установлены как аватар',
      notEnoughTrust: 'Ваш траст не позволяет делать рассылки!',
      incorrectImgResMax:
        'Неправильное разрешение изображения. Максимальное ращрешение: {0}x{1} px',
      incorrectImgResMin: 'Неправильное разрешение изображения. Минимальное ращрешение:{0}x{1} px',
      corruptedFile: 'Поврежденный файл. Загрузка отменена',
      incorrectImgExt: 'Неправильное расширение файла. Допустимые расширения: .png, .jpg, .jpeg',
      incorrectImgRatio: 'Неправильное соотношение сторон изображения',
      failUpload: 'Во время загрузки произошла ошибка. Пожалуйста, попробуйте позже',
      incorrectFileSize: 'Файл {0} превысил максимально допустимый размер файла ({1}MB)',
      selectDisabledMail: 'Вы не можете выбрать неотвеченное письмо для удаления',
      cantDelUnAnswMail: 'Вы не можете удалить неотвеченное письмо',
      defaultText:
        'Фото должно иметь разрешение не менее 500x500px и не более 3000х3000px. Размер не должен привышать 2Мб',
    },
    gifts: {
      pack: 'Упаковывается',
      reject: 'Отменено',
      progress: 'В пути',
      success: 'Доставлено',
      payed: 'Оплачено',
      notPayed: 'Не оплачено',
    },
    notifications: {
      wink: 'подмигнул Вам',
      favorite: 'добавил Вас в фавориты',
      bookmark: 'добавил Вас в закладки',
      letter: 'отправил Вам письмо',
      guest: 'посетил Ваш профиль',
      gift: 'отправил Вам подарок',
    },
    mail: {
      utils: {
        write: 'написать',
        all: 'Все',
        send: 'Отправить',
        delete: 'Удалить',
        attach: 'Прикрепить',
        frExist: 'С альбомов',
        frDevice: 'С устройства',
        back: 'Назад',
        attachedImages: 'Прикрепленное изобрабражение | Прикрепленные изобрабражения',
        dispatch: 'Рассылка', // рассылка,
        dispCreated: 'Рассылки',
        online: 'Онлайн',
        new: 'Новые',
        filter: 'Фильр',
        birthdays: 'Дни рождения',
        blackList: 'Черный список',
        writeLetter: 'Написать письмо',
        response: 'Мой ответ',
        startSearch: 'Искать',
        symbCount: 'Количество введенных символов',
        reply: 'Ответить',
        cancel: 'Отменить',
        createNew: 'Создание нового письма',
        answerOn: 'Ответ на письмо',
      },
      sort: {
        default: 'Сортировка',
        byNew: 'Новые',
        senderAZ: 'Отправитель - А-Я',
        senderZA: 'Отправитель - Я-А',
        dateASK: 'Сначала старые',
        dateDESK: 'Сначала новые',
        nameAZ: 'Имя - А-Я',
        nameZA: 'Имя - Я-А',
        priceASC: 'Возрастающая цена',
        priceDESC: 'Спадающая цена',
      },
      select: {
        default: 'Выбрать',
        all: 'Выбрать все',
        unread: 'Выбрать новые',
        read: 'Выбрать прочитанные',
        answered: 'Выбрать отвеченные',
        unanswered: 'Выбрать неотвеченные',
        status: 'Статус',
      },
      menu: {
        inbox: 'Входящие',
        sent: 'Отправленные',
        dialogs: 'Диалоги',
      },
      placeholders: {
        to: 'Кому',
        subject: 'Тема',
        message: 'Сообщение',
        greetings: 'Приветствие',
        searchById: 'Искать по ID',
      },
      errors: {
        noUser: 'Пользователь не выбран. Выберите пользователя из списка',
        noMail: 'На данный момент у Вас нет писем в этой категории',
      },
      dispatch: {
        date: 'Дата',
        theme: 'Тема',
        quantity: 'Количество',
        status: 'Статус',
        sent: 'Отправлено',
        declined: 'Отменено',
        edit: 'Редактировать',
        onModerate: 'На рассмотрении',
        free: 'В пути',
        error: 'Вы нарушили правила рассылки',
        errNoFavs:
          'Вы не добавили ни одного пользователя в фавориты. Поэтому вариант "Мои фавориты" недоступен для Вас',
        errNoBookmark:
          'Вы не добавили ни одного пользователя в закладки. Поэтому вариант "Мои закладки" недоступен для Вас',
        cantSelectBirthday:
          'Вы не можете выбрать данный вариант, потому что выбрана опция "Онлайн"',
        cantSelectOnline:
          'Вы не можете выбрать данный вариант, потому что выбрана опция "Дни рожденья"',
        cantDesc: 'Вы не можете варианты выбрать "Онлайн" и "Дни рожденья" вместе',
      },
    },
    shared: {
      common: {
        copyrights: 'Все права защищены',
        age: 'Возраст',
        city: 'Город',
        winks: 'Подмигивания',
        bookmarks: 'Закладки',
        favourites: 'Фавориты',
        photos: 'Фото',
        myPhotos: 'Фотографии',
        allPhotos: 'Все фотографии',
        guests: 'Гости',
        chat: 'Чат',
        letter: 'Письма',
        ordersHistory: 'История заказов',
        projectName: 'RedBini',
        goChat: 'Начать чат!',
        new: 'новый',
        sortBy: 'Сортировка',
        edit: 'Редактировать',
      },
      utils: {
        noResultMatch: 'Результатов по запросу {0} не найдено',
        photoPreview: 'Препросмотр фотографии',
        text: 'Текст',
        video: 'Видео',
        kg: '{0} кг',
        cm: '{0} см',
        lbs: '{0} фунтов',
        hot: 'Гарячее',
      },
      btn: {
        lazyLoad: 'Загрузить еще ({0})',
        chatText: 'Текстовый чат',
        chatVideo: 'Видеочат',
        invitations: 'Приглашения',
      },
      alts: {
        photo: ' фотография',
        ava: ' аватар',
      },
      links: {
        sendWink: 'Подмигнуть',
        addBookmark: 'Добавить в закладки',
        addFavorites: 'Добавить в фавориты',
        startChat: 'Начать чат',
        sendMail: 'Написать письмо',
        sendGift: 'подарок',
        viewProfile: 'Посмотреть профиль',
      },
      headers: {
        myPerfectMan: 'Мой идеальный мужчина',
        aboutMe: 'Обо мне',
        newMembers: 'Новые пользователи',
        wantChat: 'Они хотят початиться',
      },
      titles: {
        sendWink: 'Подмигнуть',
        addBookmark: 'Добавить в закладки',
        addFavorites: 'Добавить в фавориты',
        startChat: 'Начать чат',
        sendMail: 'Написать письмо',
        sendGift: 'Отправить подарок',
        viewProfile: 'Подарить подарок',
      },
      menu: {
        about: 'О нас',
        home: 'Домой',
        terms: 'Соглашение использования',
        cookie: 'Политика Cookie',
        contactUs: 'Обратная связь',
        faq: 'F.A.Q.',
        privacy: 'Политика приватности',
        antiScam: 'Политика анти-мошенничества',
        refund: 'Политика возврата',
        titles: {
          logo: 'RedBini',
          home: 'Домой',
          about: 'О нас',
          faq: 'F.A.Q.',
          contactUs: 'Обратная связь',
        },
        settings: 'Настройки',
        myPage: 'Моя страница',
        logout: 'Выйти',
      },
      placeholders: {
        email: 'E-mail',
        password: 'Пароль',
        name: 'Имя',
        license: 'Лицензионное соглашение',
        nameId: 'Имя или ID',
      },
    },
    settings: {
      title: 'Настройки',
      back: 'Назад',
      general: 'Общие',
      notifications: 'Уведомления',
      email: 'Email',
      password: 'Пароль',
      edit: 'Редактировать',
      newPassword: 'Новый пароль',
      repeatPswd: 'Повторите пароль',
      submit: 'Отправить',
      save: 'Сохранить',
      sys: 'Система',
      notifSys: 'Системные уведомления',
      usrs: 'Пользователи',
      notifWink: 'Подмигивания',
      notifFavs: 'Фавориты',
      notifGuest: 'Гости',
      notifLetter: 'Письма',
      chat: 'Чат',
      chatSounds: 'Чат',
      notifChat: 'Уведомления из чата (приглашения и т.д.)',
      msgActChat: 'Активный чат',
    },
    gallery: {
      setAva: 'Установить как аватар',
      del: 'Удалить фото',
      rotate: 'Вращать',
      delConfirm: 'Вы действительно хотите удалить это фото?',
      startUpdate: 'Отправка фотографии',
      updated: 'Фотография успешно обновлена',
      updateFailed: 'Фотография не обновлена. Пожалуйста, свяжитесь с тех. поддержкой',
      preloader: 'Вход в режим редактирования...',
    },
    feedback: {
      title: 'Обратная связь',
      submitMsg:
        'Ваш запрос отправлен. Примерное время ответа: 24-48 часов. Вы получите ответ на указанный Вами e-mail',
      email: 'Ваш email',
      subject: 'Тема',
      msg: 'Сообщение',
      limitFiles: 'Вы можете прикрепить до {0} файлов', // amount
      attach: 'Прикрепить',
      submit: 'Отправить',
      heading: 'Мы в соц сетях',
      address: 'Адрес',
    },
    giftModal: {
      categories: 'Категории',
      flowers: 'Цветы',
      jewelry: 'Украшения',
      perfume: 'Парфюмы',
      elDevices: 'Электронные устройства',
      toys: 'Игрушки',
      food: 'Еда и напитки',
      tickets: 'Билеты и сертификаты',
      baloons: 'Шарики',
      clothing: 'Одежда',
      other: 'Другое',
      recommended: 'Рекомендуемые',
      all: 'Все',
      giftName: 'Название подарка',
      send: 'Отправить',
      tipHead: 'Цена включает',
      air: 'Воздушную доставку',
      courier: 'Доставку курьером',
      clear: 'Растаможка',
      insurance: 'Страховка',
      to: 'Получатель',
    },
    paySystemModal: {
      heading: 'Выберите платежную систему',
      accept: 'Принять',
    },
    searchGuest: {
      title: 'Поиск девушек',
      online: 'В сети',
      cam: 'С камерой',
      camHD: 'Камера HD',
      nameId: 'Имя или ID',
      submit: 'Искать',
      newUsers: 'Новые девущки',
      age: 'Возраст от {0} до {1}', // num, num
      height: 'Рост от {0} до {1}', // num, num
      resultsTitle: 'Результаты поиска ({0})',
      noData: 'Больше грузить нечего',
    },
    notFound: {
      title: 'Страница не найдена',
      err: '404',
      tip: 'Попробуйте посетить',
      link: 'Главную страницу',
    },
    forgotPswd: {
      title: 'Восстановление пароля',
      tip: 'Введите пароль',
      pswd: 'Пароль',
      repPswd: 'Повторите пароль',
      submit: 'Отправить',
      notEqual: 'Введенные пароли не совпадают',
    },
    emailApprove: {
      title: 'Подтверждение email',
      tip: 'Введите пароль',
      pswd: 'Пароль',
      repPswd: 'Повторите пароль',
      submit: 'Отправить',
      notEqual: 'Введенные пароли не совпадают',
    },
    uploadModal: {
      status: {
        uploading: 'Загружаем фото...',
        settingAva: 'Обновляем аватар...',
      },
    },
    zodiac: {
      aqu: 'Водолей',
      pis: 'Рыбы',
      ari: 'Овен',
      tau: 'Телец',
      gem: 'Близнецы',
      can: 'Рак',
      leo: 'Лев',
      vir: 'Дева',
      lib: 'Весы',
      sco: 'Скорпион',
      sag: 'Стрелец',
      cap: 'Козерог',
    },
  },
};

export default locales;

import Vue from 'vue';
import VueResource from 'vue-resource';
import VueI18n from 'vue-i18n';
import VeeValidate from 'vee-validate';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueSocketIO from 'vue-socket.io';
import { io } from 'socket.io-client';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import Sticky from 'vue-sticky-directive';
import Tabs from 'vue-tabs-component';
import Multiselect from 'vue-multiselect';
import MessageBox from './plugins/messageBox';
import clickAway from './directives/clickAway';
import CONFIG from './config/config';
import validation from './config/validation';
import App from './App.vue';
import locales from './localization/localization';
import store from './store';
import router from './router';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'swiper/css/swiper.css';
import 'font-awesome/css/font-awesome.min.css';
import VueLazyload from 'vue-lazyload';

import './assets/js/emoji/config';
import './assets/js/emoji/util';
import './assets/js/emoji/jquery.emojiarea';
import './assets/js/utils';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min';

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

Vue.use(Sticky);
Vue.use(Tabs);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/static/images/404-text.png',
  attempt: 1,
});

// register globally
Vue.component('multiselect', Multiselect);
Vue.use(VueI18n);
Vue.use(VueResource);
Vue.use(VeeValidate, validation);
Vue.use(VueAwesomeSwiper);
Vue.use(VueScrollTo);

const socket = io(CONFIG.chatSocketURL, { autoConnect: false });

Vue.use(
  new VueSocketIO({
    debug: !CONFIG.production,
    connection: socket,
    vuex: { store, actionPrefix: 'socket_', mutationPrefix: 'socket_' },
  })
);

Vue.http.options.root = CONFIG.apiURL;
Vue.http.options.crossOrigin = true;
Vue.http.options.emulateJSON = true;

if (
  window.location.href.match(/redbini.com/) ||
  window.location.href.match(/redbini.fun/) ||
  window.location.href.match(/192.168.0.130/)
) {
  Vue.use(VueGtag, { bootstrap: false, config: { id: CONFIG.gtagId } }, router);
}

Vue.directive('clickAway', clickAway);

Vue.directive('background', (el, binding) => {
  // eslint-disable-next-line no-undef
  if (navigator.userAgent.indexOf('Edge') > -1 || navigator.userAgent.indexOf('Trident/7.0') > -1) {
    el.style.background = `url(${binding.value.img}) 50% 0 no-repeat`;
    el.style.backgroundSize = 'cover';
    el.className += ` ${binding.value.imgClass}`;
    const children = [].slice.call(el.children);
    const child = children.find((i) => i.classList.contains(binding.value.imgClass));
    if (child) {
      child.style.opacity = '0';
    } else {
      console.warn('v-background directive unable to set opacity to 0. Applying failed.');
    }
  }
});

if (CONFIG.production) {
  Vue.config.devtools = true;
  Vue.config.productionTip = false;
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: locales, // set locale messages
});

new Vue({ i18n, router, store, render: (h) => h(App) }).$mount('#app');

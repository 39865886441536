<template>
  <div class="q-search" tab-index="-1">
    <div class="form-group">
      <input
        ref="qSearch"
        v-model="searchValue"
        type="search"
        class="q-search--input form-control"
        :placeholder="placeholder || $t('shared.placeholders.nameId')"
        @focus="focusSearch(true)"
        @blur="clearResults"
      />
    </div>
    <div v-show="inFocus" class="q-search--result" @mousedown.prevent>
      <p v-show="searchValue.length && error.length" class="text-danger">
        {{ error }}
      </p>
      <p v-if="showPreloader && !error.length" class="loading-wrap">
        <span class="wait-spin relative" />
      </p>
      <simplebar style="height: 300px" data-simplebar-auto-hide="false">
        <ul class="list-unstyled">
          <li
            v-for="user in result"
            :key="user.user_id"
            class="q-search--result__item"
            @mouseup="userSelected(user)"
          >
            <div class="set-flex q-search--user">
              <div class="set-flex">
                <div class="q-search--user__ava" :class="{ 'is-online': user.online }">
                  <img
                    :src="setPhotoSource(user.avatar.img_small)"
                    :alt="user.name + $t('shared.alts.ava')"
                  />
                </div>
                <div class="q-search--user__info">
                  <p class="name">
                    {{ user.name
                    }}<template v-if="user.birthday !== null">
                      , {{ getUserAge(user.birthday) }}
                    </template>
                  </p>
                  <p class="uid">ID: {{ user.user_id }}</p>
                </div>
              </div>
              <div class="q-search--user__status set-flex">
                <span v-if="user.new" class="is-new">{{ $t('shared.common.new') }}</span>
                <span :class="userWebcam('hd')" />
              </div>
            </div>
          </li>
        </ul>
      </simplebar>
    </div>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.css';
import debounce from 'lodash/debounce';
import { PHOTO_SRC, AGE, WEBCAM } from '../../../mixins/utils';
import { NEW_USERS } from '../../../mixins/types/parts';

export default {
  props: {
    placeholder: String,
  },
  data() {
    return {
      inFocus: false,
      searchValue: '',
      result: [],
      error: '',
      limit: 20,
    };
  },
  watch: {
    /**
     * инициализация поиска при открытии дропдауна и заполнение
     * псевдо-данными, если нет результатов
     */
    inFocus(v) {
      if (!this.result.length && v) {
        const self = this;
        this.askData().then(() => {
          self.maskResults();
        });
      }
    },
    /**
     * поиск пользователя по ИД
     *
     * @param val {string}
     */
    searchValue(val) {
      this.error = '';
      this.showPreloader = true;
      if (val.length) {
        this.searchMethod(val);
      } else {
        this.showPreloader = false;
        this.error = '';
        this.maskResults();
      }
    },
    /**
     * прятать прелоадер, когда обновляются результаты поиска
     */
    resultsLength() {
      this.showPreloader = false;
    },
    /* sex() {
				let self = this;
				this.askData().then( r => {
					if (!self.asked) {
						self.maskResults();
					}
				} );
			} */
  },
  methods: {
    /**
     * заполнение дропдауна данными
     *
     * @param bool
     */
    focusSearch(bool) {
      this.inFocus = bool;
      if (this.recentlyJoined.length) {
        bool ? this.maskResults() : '';
      }
    },
    /**
     * поиск по пользователям по ИД или имени
     *
     * @param v {string}
     */
    searchMethod: debounce(function (v) {
      this.$http
        .post('v1/profile/quick-search', {
          access_token: window.localStorage['access-token'],
          name_or_id: v,
        })
        .then((response) => {
          const { result } = response.body;
          if (result.length) {
            this.showPreloader = false;
            this.result = result;
          } else {
            this.error = this.$t('shared.utils.noResultMatch', [v]);
            this.result = [];
          }
        });
    }, 500),
    /**
     * заполнить результат данными поиска
     */
    maskResults() {
      this.result = this.recentlyJoined;
      this.showPreloader = false;
    },
    /**
     * очистить результаты поиска и заполнить их замаскированными результатами
     */
    clearResults() {
      this.searchValue = '';
      this.result = [];
      this.focusSearch(false);
      this.$refs.qSearch.blur();
    },
    /**
     * передача события о выбранном пользователя в родителя и очистка результатов
     *
     * @param user {objtct}
     */
    userSelected(user) {
      // on mouse down
      this.$emit('selected', user);
      this.clearResults();
      // after this fires v-click-away event with *clearResults*
    },
  },
  mounted() {
    const self = this;
    this.askData().then(() => {
      self.maskResults();
    });
  },
  computed: {
    recentlyJoined() {
      return this.$store.getters.recentlyJoinedUsers.slice(0, 10);
    },
    resultsLength() {
      return this.result.length;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  components: {
    simplebar,
  },
  mixins: [PHOTO_SRC, AGE, WEBCAM, NEW_USERS],
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';

.q-search {
  position: relative;

  .res {
    height: 300px;
  }

  &--result {
    position: absolute;
    left: 0;
    top: 100%;
    padding: 7px 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.29);
    z-index: 100;
    ul {
      margin-bottom: 0;
      list-style: none;
      padding: 0;
    }

    .text-danger {
      color: #d9534f;
      text-align: center;
    }

    .loading-wrap {
      color: #3e3f42;
      text-align: center;
    }
  }

  &--result__item {
    padding: 7px 15px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s;
    &:hover {
      background: $light-grey;
    }
  }

  &--user {
    align-items: center;
    justify-content: space-between;
    &__ava {
      border: 1px solid transparent;
      border-right: 1px solid #000000;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      width: 55px;
      height: 55px;
      padding: 3px;
      transform: rotate(45deg);
      img {
        border-radius: 50%;
        width: 100%;
        transform: rotate(-45deg);
      }
    }

    &__info {
      font-size: 14px;
      margin-left: 15px;
      p {
        margin-bottom: 5px;
      }
      .name {
        color: #3e3f42;
        font-weight: bold;
      }

      .uid {
        color: #7f8288;
      }
    }

    &__status {
      span + span {
        margin-left: 5px;
      }
    }

    .is-new {
      border-radius: 8px;
      text-transform: uppercase;
      font-size: 9px;
      font-weight: bold;
      padding: 4px 6px;
      line-height: 1;
      color: $white;
      background: $green;
      &:before {
        display: none;
      }
    }
  }

  &--input {
    width: 335px;
  }
}

@media only screen and (max-width: 480px) {
  .q-search {
    &--result {
      width: 100%;
    }
  }
}
</style>

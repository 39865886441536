export const socialLink = {
  facebook: 'https://www.facebook.com/redbini/',
  twitter: 'https://twitter.com/redbini_com',
  instagram: 'https://www.instagram.com/redbini_com/',
  youtube: 'https://www.youtube.com/channel/UCohNSyNA5iHBxeVDTJFpoaw',
  pinterest: 'https://www.pinterest.co.uk/redbini_com',
};
export default {
  baseURL: 'https://redbini.fun',
  production: true,
  chatURL: 'https://chat.redbini.fun',
  apiURL: 'https://api.redbini.fun',
  chatSocketURL: 'https://chatsocket.redbini.fun',
  gtagId: 'GTM-57MVNSQ',
  watermarkLarge: '/static/images/wm_large.png',
  watermarkSmall: '/static/images/wm_small.png',
  watermarkAlpha: 1,
  sounds: {
    chatInvite: '/static/sounds/invite.mp3',
    toast: '/static/sounds/toast.mp3',
    sys_notification: '/static/sounds/sys_notification.mp3',
  },
  lifeTimes: {
    alert: 5000, // ms
    notification: 8000, // ms
    invite: 30000, // ms
  },
  registration: {
    captchaInterval: 30, // minutes
    triesCount: 3,
  },
  wmnAgreementModal: {
    launchInterval: 30, // days
  },
};

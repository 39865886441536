import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import utils from './modules/utils';
import site from './modules/site';
import auth from './modules/auth';
import socket from './modules/socket-registration';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    utils,
    site,
    socket,
    auth,
  },
});

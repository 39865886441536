/* eslint-disable no-shadow */
/**
 * Created by Alex Shvets on 27.02.2017.
 */
import Vue from 'vue';
import { TOGGLE_MODAL } from '../../mixins/utils';

const state = {
  deviceWidth: 0,
  lastAction: {
    type: '',
    add: 0,
  },
  prevModal: {
    open: false,
    user: {},
    preview: true,
    loader: true,
  },
  giftModal: {
    currentUser: {
      avatar: {},
    },
    preview: true,
    open: false,
    all: [],
    rec: [],
  },
  virtualGiftModal: {
    currentUser: {
      avatar: {},
    },
    preview: true,
    open: false,
    all: [],
    rec: [],
  },
  giftLetterModal: {
    open: false,
    user: {
      avatar: {},
    },
    text: '',
  },
  registerModal: {
    open: false,
    user: {},
  },
  verificationModal: {
    open: false,
    user: {},
  },
  questionnaireModal: {
    open: false,
    user: {},
  },
  recaptchaModal: {
    open: false,
    callback: {
      success: '',
      fail: '',
    },
  },
  restoreProfileModal: {
    open: false,
    token: '',
    callback: {
      success: '',
      fail: '',
    },
  },
  wmnAgreementModal: {
    open: false,
  },
  randomUsers: [],
  recentlyJoinedUsers: [],
  mailModal: {
    to: {},
    isAnswer: false, // true comes if sex.isWoman
    womanCanAnswer: false, // dialogs for women feature (responsive anwser)
    data: {}, // custom data for answer
    open: false,
  },
  updateMailState: false,
  dispatchModal: {
    open: false,
    data: {},
  },
  uploadPhotosModal: {
    open: false,
  },
  lowCreditsModal: {
    open: false,
  },
  refundModal: {
    open: false,
  },
  paySystemModal: {
    open: false,
  },
  toasts: [],
  alerts: [],
  invites: [],
  sysNotifications: [
    { type: 'warning', text: 'warn-1' },
    { type: 'warning', text: 'warn-2' },
    { type: 'info', text: 'info-1' },
    { type: 'info', text: 'info-2' },
  ],
  gallery: {
    index: 0,
    open: false,
    photos: [],
    update: false,
  },
  sidebarStatus: false,
};

const getters = {
  verificationModalStatus(state) {
    return state.verificationModal.open;
  },
  sidebarStatus(state) {
    return state.sidebarStatus;
  },
  /**
   * получение последнего действия
   *
   * @param state
   * @return {state.lastAction|{type, add}|*}
   */
  lastAction(state) {
    return state.lastAction;
  },
  /**
   * получение обновление состояния писем (для обновления страницы, находясь
   * на компоненте)
   *
   * @param state
   * @return {boolean|*}
   */
  updateMailState(state) {
    return state.updateMailState;
  },
  /**
   * Получение ширины вьюпорта устройства
   *
   * @param state
   * @return {number}
   */
  deviceWidth(state) {
    return state.deviceWidth;
  },
  /**
   * Получение состояния модалки с предпросмотром профиля
   *
   * @param state
   * @return {state.prevModal|{open, user, preview, loader}}
   */
  prevModal(state) {
    return state.prevModal;
  },
  /**
   *  Получение состояния модалки регистраци
   *
   * @param state
   * @return {state.registerModal|{open, user}}
   */
  registerModal(state) {
    return state.registerModal;
  },
  verificationModal(state) {
    return state.verificationModal;
  },
  questionnaireModal(state) {
    return state.questionnaireModal;
  },
  /**
   * Получение состояния модалки с проверкой капчи
   *
   * @param state
   * @return {state.recaptchaModal|{open, callback}}
   */
  recaptchaModal(state) {
    return state.recaptchaModal;
  },
  /**
   * Получение состояния модалки с восстановлением профиля пользователя
   *
   * @param state
   * @return {state.restoreProfileModal|{open, callback}}
   */
  restoreProfileModal(state) {
    return state.restoreProfileModal;
  },
  /**
   *  Получение состояния модалки загрузки фотографий (аватара)
   *
   * @param state
   * @return {state.uploadPhotosModal|{open}}
   */
  uploadPhotosModal(state) {
    return state.uploadPhotosModal;
  },
  /**
   *  Получение состояния модалки с лицензионным соглашением (клиентка только)
   *
   * @param state
   * @return {state.wmnAgreementModal|{open}}
   */
  wmnAgreementModal(state) {
    return state.wmnAgreementModal;
  },
  /**
   *  Получение состояния модалки с подарками
   *
   * @param state
   * @return {state.giftModal|{currentUser, preview, open, all, rec}}
   */
  giftModal(state) {
    return state.giftModal;
  },
  virtualGiftModal(state) {
    return state.virtualGiftModal;
  },
  /**
   *  Получение состояния модалки сопроводительным письмом из подарка
   *
   * @param state
   * @return {state.giftLetterModal|{open, user, text}}
   */
  giftLetterModal(state) {
    return state.giftLetterModal;
  },
  /**
   *  Получение состояния модалки с "у вас недостаточно кредитов"
   *
   * @param state
   * @return {state.lowCreditsModal|{open}}
   */
  lowCreditsModal(state) {
    return state.lowCreditsModal;
  },

  /**
   *  Получение состояния модалки возврата средств"
   *
   * @param state
   * @return {state.lowCreditsModal|{open}}
   */
  refundModal(state) {
    return state.refundModal;
  },

  /**
   *  Получение состояния модалки выбор платежной системы
   *
   * @param state
   * @return {state.paySystemModal|{open}}
   */
  paySystemModal(state) {
    return state.paySystemModal;
  },

  /**
   * получение случайных пользователей
   *
   * @param state
   * @return {Array}
   */
  randomUsers(state) {
    return state.randomUsers;
  },
  /**
   * получение последних зарегистрированных пользователей
   *
   * @param state
   * @return {Array}
   */
  recentlyJoinedUsers(state) {
    return state.recentlyJoinedUsers;
  },
  /**
   *  Получение состояния модалки модалки с намсианием письма
   *
   * @param state
   * @return {state.mailModal|{to, isAnswer, womanCanAnswer, data, open}}
   */
  mailModal(state) {
    return state.mailModal;
  },
  /**
   *  Получение состояния модалки с рассылкой письма
   *
   * @param state
   * @return {state.dispatchModal|{open, data}}
   */
  dispatchModal(state) {
    return state.dispatchModal;
  },
  /**
   * Получение состояния всплывающих уведомлений (винки, фавориты и пр)
   *
   * @param state
   * @return {Array}
   */
  toasts(state) {
    return state.toasts;
  },
  /**
   * Получение состояния с сообщениями от системы (ошибки, успех)
   *
   * @param state
   * @return {Array}
   */
  alerts(state) {
    return state.alerts;
  },
  /**
   * Получение списка инвайтов в чат
   *
   * @param state
   * @return {Array}
   */
  invites(state) {
    return state.invites;
  },
  /**
   * Получение списка системных уведомлений
   *
   * @param state
   * @return {Array}
   */
  sysNotifications(state) {
    return state.sysNotifications;
  },
  /**
   * Получение состояния галереи
   *
   * @param state
   * @return {state.gallery|{index, open, photos, update}}
   */
  gallery(state) {
    return state.gallery;
  },
  /**
   * Получение флага об обновлении состояния галереи
   *
   * @param state
   * @return {boolean}
   */
  updatedGallery(state) {
    return state.gallery.update;
  },
};

const mutations = {
  /**
   * Удалить системное уведомление
   *
   * @param state
   * @param payload
   */
  deleteNotification(state) {
    Object.assign(state.sysNotifications);
  },
  /**
   * Загрузить системное уведомление
   *
   * @param state
   * @param payload
   */
  loadSysNotifications(state, payload) {
    Object.assign(state.sysNotifications, payload);
  },
  /**
   * Установить последнием действием
   *
   * @param state
   * @param payload
   */
  setAction(state, payload) {
    state.lastAction = payload;
  },
  /**
   * Установить ширину вьюпорта
   *
   * @param state
   * @param payload
   */
  setDeviceWidth(state, payload) {
    state.deviceWidth = payload;
  },
  /**
   * Обновить состояние модалки с предпросмотром профиля
   *
   * @param state
   * @param payload
   */
  editPrevModal(state, payload) {
    Object.assign(state.prevModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Установить список случайных пользователей
   *
   * @param state
   * @param payload
   */
  setRandomUsers(state, payload) {
    state.randomUsers = payload;
  },
  /**
   * Установить список последних зареганных пользователей
   *
   * @param state
   * @param payload
   */
  setRecentlyJoinedUsers(state, payload) {
    state.recentlyJoinedUsers = payload;
  },
  /**
   * Обновить состояние модалки регистрации
   *
   * @param state
   * @param payload
   */
  updateRegisterModal(state, payload) {
    Object.assign(state.registerModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  updateVerificationModal(state, payload) {
    Object.assign(state.verificationModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  updateQuestionnaireModal(state, payload) {
    Object.assign(state.questionnaireModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние модалки восстановления профиля пользователя
   *
   * @param state
   * @param payload
   */
  updateRestoreProfileModal(state, payload) {
    Object.assign(state.restoreProfileModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние модалки регистрации
   *
   * @param state
   * @param payload
   */
  updateRecaptchaModal(state, payload) {
    Object.assign(state.recaptchaModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние модалки с подарками
   *
   * @param state
   * @param payload
   */
  updateGiftModal(state, payload) {
    Object.assign(state.giftModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  updateVirtualGiftModal(state, payload) {
    Object.assign(state.virtualGiftModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние модалки с загрузкой аватара
   *
   * @param state
   * @param payload
   */
  updateUploadPhotosModal(state, payload) {
    Object.assign(state.uploadPhotosModal, payload);
    TOGGLE_MODAL(payload.open, payload.skipToggle);
  },
  /**
   * Обновить состояние модалки с сопроводительным письмом к подарку
   *
   * @param state
   * @param payload
   */
  updateGiftLetterModal(state, payload) {
    Object.assign(state.giftLetterModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние модалки "у вас недостаточно кредитов"
   *
   * @param state
   * @param payload
   */
  updateLowCreditModal(state, payload) {
    Object.assign(state.lowCreditsModal, payload);
    TOGGLE_MODAL(payload.open);
  },

  /**
   * Обновить состояние модалки выбор платежной системы
   *
   * @param state
   * @param payload
   */
  updatePaySystemModal(state, payload) {
    Object.assign(state.paySystemModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние модалки возврата средтсв
   *
   * @param state
   * @param payload
   */
  updateRefundModal(state, payload) {
    Object.assign(state.refundModal, payload);
  },
  /**
   * Обновить состояние модалки лиценз. соглашением у клиентки
   *
   * @param state
   * @param payload
   */
  updateWmnAgreementModal(state, payload) {
    Object.assign(state.wmnAgreementModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние модалки написания письма
   *
   * @param state
   * @param payload
   */
  updateMailModal(state, payload) {
    Object.assign(state.mailModal, payload);
    if (!payload.open) {
      state.mailModal.data = {};
      state.mailModal.isAnswer = false;
    }
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние в Mail компоненте
   *
   * @param state
   */
  updateMailState(state) {
    state.updateMailState = !state.updateMailState;
  },
  /**
   * Обновить состояние модалки с рассылкой письма
   *
   * @param state
   * @param payload
   */
  updateDispatchModal(state, payload) {
    Object.assign(state.dispatchModal, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние галери
   *
   * @param state
   * @param payload
   */
  updateGallery(state, payload) {
    Object.assign(state.gallery, payload);
    TOGGLE_MODAL(payload.open);
  },
  /**
   * Обновить состояние галереи - флаг
   *
   * @param state
   */
  updateGalleryState(state) {
    state.gallery.update = !state.gallery.update;
  },
  /**
   * Добавить уведомление (алерт)
   *
   * @param state
   * @param payload
   */
  addAlert(state, payload) {
    const obj = {
      id: +new Date(),
    };
    state.alerts.push(Object.assign(payload, obj));
  },
  /**
   * Добавить увеломление (тоаст) для винка, фаворита и пр
   *
   * @param state
   * @param payload
   */
  setToasts(state, payload) {
    state.toasts.push(payload);
  },
  /**
   * Добавить инвайт
   *
   * @param state
   * @param payload
   */
  setInvites(state, payload) {
    /* if (foundInvites.length === 3) {
      let indexOfFirstInvite = null
      const foundFirstInviteEl = state.invites.find((invite, index) => {
        if (invite.params.senderId === payload.params.senderId) {
          indexOfFirstInvite = index
          return invite
        }
      })
      state.invites.splice(foundFirstInviteEl, 1)
      state.invites.push(payload)
   */

    const foundIndex = state.invites.findIndex(
      (invite) => invite.params.senderId === payload.params.senderId
    );
    if (foundIndex !== -1) {
      state.invites.splice(foundIndex, 1, payload);
      /* state.invites.push(payload) */
    } else {
      state.invites.push(payload);
    }
  },

  /**
   * Удалить инвайты
   *
   * @param state
   * @param payload
   */
  closeInvites(state, payLoad) {
    payLoad.notifications.map((notification) => {
      for (let i = 0; i < state.invites.length; i++) {
        if (state.invites[i].params.notificationId === notification.id)
          state.invites.splice(state.invites[i], 1);
      }
    });
  },
  /**
   * Удалить инватй
   *
   * @param state
   * @param payload
   */
  removeInvite(state, payload) {
    const index = state.invites.findIndex(
      (i) => i.params.notificationId === payload.notificationId
    );
    state.invites.splice(index, 1);
  },
  /**
   * Удалить уведомление (тоаст)
   *
   * @param state
   * @param payload
   */
  removeToast(state, payload) {
    const index = state.toasts.findIndex((i) => i.notificationId === payload);
    state.toasts.splice(index, 1);
  },
  /**
   * Удалить уведомление (алерт)
   *
   * @param state
   * @param payload
   */
  removeAlert(state, payload) {
    const index = state.alerts.findIndex((i) => i.id === payload);
    state.alerts.splice(index, 1);
  },
  setSidebarStatus(state, payload) {
    if (payload) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    state.sidebarStatus = payload;
  },
};

const actions = {
  sidebarHandler(context, payload) {
    context.commit('setSidebarStatus', payload);
  },

  /**
   * сокет-слушатель на уведомления (тоасты и инвайты)
   *
   * @param context
   * @param payload
   */
  socket_notification(context, payload) {
    console.log(payload);

    if (payload.type === 'invite') {
      payload.params.user = {};
      payload.params.user.avatar = {};

      context.commit('setInvites', payload);
    } else {
      context.dispatch('getCounters', {});
      context.commit('setToasts', payload);
    }
  },
  /**
   * сокет-слушатель на закрытие уведомлений (тоасты и инвайты)
   *
   * @param context
   * @param payload
   */
  socket_invitesClose(context, payload) {
    console.log('invites to close: ', payload);
    context.commit('closeInvites', payload);
  },

  /**
   * создать сообщение об ошибке или успешном действии
   *
   * @param context
   * @param payload
   */
  addAlert(context, payload) {
    context.commit('addAlert', payload);
  },
  /**
   * загрузить инфу для предпросмотра профиля
   *
   * @param context
   * @param payload
   */
  loadPreviewInfo(context, payload) {
    if (window.localStorage['access-token']) {
      Vue.http
        .post('v1/profile/profile', {
          access_token: window.localStorage['access-token'],
          target_user_id: payload.id,
        })
        .then(
          (response) => {

            if (response.body.status) {

              const next = function () {
                const obj = {
                  userDesc: response.body.result.profile_additional_info.about_me,
                  new: response.body.result.new,
                  avatar: {
                    img_large: response.body.result.avatar.img_large,
                    img_preview: response.body.result.avatar.img_medium,
                  },
                  images,
                  online: response.body.result.online,
                  mapped: true,
                  userAge: response.body.result.birthday,
                  userId: response.body.result.user_id,
                  user_id: response.body.result.user_id, // for privileges check
                  userName: response.body.result.name,
                  userPrefer: response.body.result.profile_additional_info.about_man,
                  userStatus: response.body.result.status,
                };
                context.commit('editPrevModal', {
                  user: obj,
                  preview: payload.preview,
                  loader: false,
                });
              };

              let images = response.body.result.photos.slice(0, 8);

              if (images.length) {
                let counter = 0;
                const prepare = function () {
                  counter += 1;
                  if (counter >= images.length) {
                    next();
                  }
                };
                const cache = [];
                images.forEach((img) => {
                  const tmp = new Image();
                  tmp.onload = function () {
                    prepare();
                  };
                  tmp.src = img.img_preview;
                  cache.push(tmp);
                });
              } else {
                next();
              }
            } else {
              context.commit('editPrevModal', {
                open: false,
                loader: false,
              });
              alert('Something goes wrong');
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      Vue.http.post('v1/user-guest/woman-info', { id: payload.id }).then(
        (response) => {
          if (response.body.status) {
            const next = function () {
              context.commit('editPrevModal', {
                user: response.body,
                preview: payload.preview,
                loader: false,
              });
            };
            const { images } = response.body;
            if (images.length) {
              let counter = 0;
              const prepare = function () {
                counter += 1;

                if (counter >= images.length) {
                  next();
                }
              };
              const cache = [];
              images.forEach((img) => {
                const tmp = new Image();

                tmp.onload = function () {
                  prepare();
                };
                tmp.onerror = function () {
                  prepare();
                };
                tmp.src = img.img_preview;
                cache.push(tmp);
              });
            } else {
              next();
            }
          } else {
            alert('Something goes wrong');
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  },
  /**
   * загрузить информацию для модалки с подарками
   *
   * @param context
   * @param payload
   */
  updateGiftModal({ commit }, payload) {
    const sort = function (a, b) {
      return a.name > b.name ? -1 : 1;
    };
    Vue.http
      .post('v1/gift/load', {
        access_token: window.localStorage['access-token'],
      })
      .then(
        (r) => {
          if (r.body.status) {
            //	if ( typeOf( r.body.result ) === 'object' ) {
            // eslint-disable-next-line no-undef
            if (r.body.result && Array.isArray(r.body.result)) {
              let tmp = [];
              for (let i = 0; i < r.body.result.length; i++) {
                r.body.result[i].sumPrice =
                  r.body.result[i].price + r.body.result[i].delivery_price;
                r.body.result[i].sumPriceEur =
                  r.body.result[i].price_eur + r.body.result[i].delivery_price_eur;

                tmp = tmp.concat(r.body.result[i]);
              }

              // let tmp = [];
              // for ( let k in r.body.result ) {
              // 	let item = r.body.result[ k ];
              // 	item.forEach( i => {
              // 		i.sumPrice = i.price + i.delivery_price
              // 	} )
              // 	tmp = tmp.concat( item )
              // }
              commit('updateGiftModal', {
                all: tmp.sort(sort),
                user_id: payload.user_id,
                retryGift: payload.retryGift,
                open: payload.open,
              });
            }
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'load rec gifts',
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.someErr'),
            info: 'load rec gifts',
          });
        }
      );

    Vue.http
      .post('v1/gift/load/recommend', {
        access_token: window.localStorage['access-token'],
        target: payload.user_id,
      })
      .then(
        (r) => {
          if (r.body.status) {
            // eslint-disable-next-line no-undef
            if (r.body.result && Array.isArray(r.body.result)) {
              let tmp = [];
              for (let i = 0; i < r.body.result.length; i++) {
                r.body.result[i].sumPrice =
                  r.body.result[i].price + r.body.result[i].delivery_price;
                r.body.result[i].sumPriceEur =
                  r.body.result[i].price_eur + r.body.result[i].delivery_price_eur;
                tmp = tmp.concat(r.body.result[i]);
              }
              // for ( let k in r.body.result ) {
              // 	let item = r.body.result[ k ];
              // 	item.forEach( i => {
              // 		i.sumPrice = i.price + i.delivery_price
              // 	} )
              // 	tmp = tmp.concat( item )
              // }

              commit('updateGiftModal', {
                rec: tmp.sort(sort),
                user_id: payload.user_id,
              });
            }
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'load rec gifts',
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.someErr'),
            info: 'load rec gifts',
          });
        }
      );

    Vue.http
      .post('v1/profile/quick-search', {
        access_token: window.localStorage['access-token'],
        name_or_id: payload.user_id,
      })
      .then((response) => {
        commit('updateGiftModal', {
          currentUser: response.body.result[0],
          name_or_id: payload.user_id,
          open: payload.open,
        });
      });
  },

  updateVirtualGiftModal({ commit }, payload) {
    const sort = function (a, b) {
      return a.name > b.name ? -1 : 1;
    };
    Vue.http
      .post('v1/profile/quick-search', {
        access_token: window.localStorage['access-token'],
        name_or_id: payload.user_id,
      })
      .then((response) => {
        Vue.http
          .post('v1/virtual-gift/list', {
            access_token: window.localStorage['access-token'],
          })
          .then(
            (r) => {
              if (r.body.status) {
                //	if ( typeOf( r.body.result ) === 'object' ) {
                // eslint-disable-next-line no-undef
                if (r.body.result && Array.isArray(r.body.result)) {
                  let tmp = [];
                  for (let i = 0; i < r.body.result.length; i++) {
                    tmp = tmp.concat(r.body.result[i]);
                  }

                  // let tmp = [];
                  // for ( let k in r.body.result ) {
                  // let item = r.body.result[ k ];
                  // item.forEach( i => {
                  // i.sumPrice = i.price + i.delivery_price
                  // } )
                  //  tmp = tmp.concat( item )
                  // }
                  if (response.body.result.length > 0) {
                    commit('updateVirtualGiftModal', {
                      all: tmp.sort(sort),
                      user_id: payload.user_id,
                      open: payload.open,
                    });
                  }
                }
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                  info: 'load rec gifts',
                });
              }
            },
            () => {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'load rec gifts',
              });
            }
          );
        if (response.body.result.length > 0) {
          commit('updateVirtualGiftModal', {
            currentUser: response.body.result[0],
            name_or_id: payload.user_id,
            open: payload.open,
          });
        }
      });
  },

  /**
   * загрузить список случайных пользователей
   *
   * @param context
   * @param payload
   */
  loadRandomUsers(context, payload) {
    if (context.getters.sex.isMan || !window.localStorage['access-token']) {
      Vue.http.post('v1/user-guest/online-woman', { limit: payload.limit }).then((response) => {
        response.json().then((response) => {
          if (response.status) {
            context.commit('setRandomUsers', response.result.list);
          }
        });
      });
    } else if (context.getters.sex.isWoman) {
      Vue.http
        .post('v1/news/random-man', {
          access_token: window.localStorage['access-token'],
          limit: payload.limit,
        })
        .then(
          (response) => {
            response.json().then(
              (response) => {
                context.commit('setRandomUsers', response.result);
              },
              (error) => {
                console.error(error);
              }
            );
          },
          (error) => {
            console.log(error);
          }
        );
    }
  },
  /**
   * загрузить пользоватея для модалки написания письма
   *
   * @param context
   * @param payload
   */
  loadMailUser(context, payload) {
    if (payload.to) {
      Vue.http
        .post('v1/profile/quick-search', {
          access_token: window.localStorage['access-token'],
          name_or_id: payload.to,
        })
        .then((response) => {
          context.commit('updateMailModal', {
            open: payload.open,
            to: response.body.result[0],
            data: payload.data || {},
            isAnswer: payload.isAnswer || false,
            womanCanAnswer: payload.womanCanAnswer || false,
          });
        });
    } else {
      context.commit('updateMailModal', {
        open: payload.open,
        to: '',
        data: {},
        isAnswer: false,
      });
    }
  },
  /**
   * загрузить список системных уведомлений
   *
   * @param context
   */
  loadSysNotifications(context) {
    Vue.http
      .post('v1/system-notification/load-one', {
        access_token: window.localStorage['access-token'],
      })
      .then((response) => {
        context.commit('loadSysNotifications', {
          notifications: response.body.result,
        });
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

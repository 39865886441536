<template>
  <transition name="modal">
    <div v-if="modal.open" class="vue-modal modal-mask rcpt-modal">
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container rcpt-modal--container">
          <div class="modal-body rcpt-modal--body">
            <vue-recaptcha
              v-if="siteSettings.google_recaptcha_public_key"
              ref="recaptcha"
              class="g-recaptcha"
              name="captcha"
              :load-recaptcha-script="true"
              :sitekey="siteSettings.google_recaptcha_public_key"
              @verify="onVerify"
              @expired="onExpired"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { RECAPTCHA_MODAL } from '../../../mixins/formsActions';
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: { VueRecaptcha },
  mixins: [RECAPTCHA_MODAL],
  data() {
    return {
      captcha: '',
    };
  },
  computed: {
    modal() {
      return this.$store.getters.recaptchaModal;
    },
    open() {
      return this.modal.open;
    },
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
  },
  watch: {
    open(v) {
      if (!v) this.resetRecaptcha();
    },
  },
  methods: {
    /**
     * Верификация капчи
     */
    onVerify(response) {
      this.captcha = response;
      this.checkCaptcha();
    },
    /**
     * Очистка при ошибке
     */
    onExpired() {
      this.captcha = '';
    },
    /**
     * Обнулить состояние капчи
     */
    resetRecaptcha() {
      this.captcha = '';
      this.$refs.recaptcha.reset();
    },
    checkCaptcha() {
      this.$http
        .post('v1/user-guest/check-google-captcha', {
          captcha: this.captcha,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.modal.callback.success();
            } else if (this.modal.callback.fail) this.modal.callback.fail();
            this.closeRecaptchaModal();
          },
          () => {
            if (this.modal.callback.fail) this.modal.callback.fail();
            this.closeRecaptchaModal();
          }
        );
    },
  },
};
</script>

<style lang="scss">
.rcpt-modal {
  &--container {
    width: auto;
    padding: 0;
    background: none;
    border-radius: 0;
  }
  &--body {
    padding: 0;
    display: flex;
    justify-content: center;
  }
}
</style>

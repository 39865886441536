<template>
  <div id="app">
    <rb-header v-show="loaded" :is-auth="isAuthenticated" />
    <transition name="fade">
      <router-view v-show="loaded" />
    </transition>
    <rb-footer v-show="loaded" />
    <locale />
    <captcha />
    <cookie v-if="isAuthenticated && !sex.isWoman" />
  </div>
</template>

<script>
import { MAIN_RESIZE_DETECTOR } from './mixins/utils';
import { LOGIN } from './mixins/formsActions';
import { LOAD_PROFILE } from './mixins/types/static';
import captcha from './components/Shared/modals/ReCaptcha.vue';
import RbHeader from './components/HeaderComponent.vue';
import RbFooter from './components/Shared/Footer.vue';
import Cookie from './components/Shared/parts/Cookie.vue';
import locale from './components/Shared/parts/localization.vue';
import { bootstrap } from 'vue-gtag';
import config from './config/config';

let reconnectInterval = null;

export default {
  components: {
    locale,
    captcha,
    RbHeader,
    RbFooter,
    Cookie,
  },
  mixins: [LOGIN, MAIN_RESIZE_DETECTOR, LOAD_PROFILE],
  data: () => {
    return {
      emojiPickerInitialized: false,
      loaded: false,
      version: '1.3.1',
    };
  },
  computed: {
    frontendVersion() {
      return this.$store.getters.frontendVersion;
    },
    /*fbAppID() {
      return this.$store.getters.siteSettings.facebook_app_id;
    },*/
    user() {
      return this.$store.getters.user;
    },
    sex() {
      return this.$store.getters.sex;
    },
    config() {
      return this.$store.getters.config;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    settings() {
      return this.$store.getters.settings;
    },
  },
  watch: {
    isAuthenticated(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.$socket.connect();
      }
      if (oldVal === true && newVal === false) {
        this.$socket.disconnect();
      }
    },
    /**
     * При получении ключа приложения инициализировать плагин логина в фейсбук
     */
    /*fbAppID(v) {
      if (v) this.initFBlogin();
    },*/
    sex(v) {
      if (v.isWoman) {
        document.documentElement.lang = 'ru';
        this.$root.$i18n.locale = 'ru';
      }
    },
  },
  sockets: {
    connect() {
      clearInterval(reconnectInterval);
      this.$store.commit('setRegisteredOnChatStatus', { status: true });
      if (this.isAuthenticated) this.registerSession();
    },

    disconnect() {
      if (this.isAuthenticated) {
        reconnectInterval = setInterval(() => {
          this.$socket.connect();
        }, 10000);
      }

      this.$store.commit('setRegisteredOnChatStatus', { status: false });
    },
  },

  mounted() {
    this.getSiteType();
    if (this.isAuthenticated) {
      this.$socket.connect();
    }
    window.socketIo = this.$socket;

    this.startExam()

    window.scrollTo(0, 0);

    this.toggleState();

    if (this.sex.isWoman) {
      document.documentElement.lang = 'ru';
    }

    setTimeout(() => {
      this.$store.dispatch('getSiteSettings');
      this.writeReferalRecord();
      if (
        window.location.href.match(/redbini.com/) ||
        window.location.href.match(/redbini.fun/) ||
        window.location.href.match(/192.168.0.130/)
      ) {
        bootstrap().then(() => {
          console.log('all done!');
        });
      }
    }, 500);
  },
  methods: {
    startExam() {
      fetch('/version.txt')
        .then(response => response.text())
        .then(text => {
          console.log(text)
          if ( text != this.version) {
            location.reload(true)
          }
        })
      this.periodExam()
    },
    getSiteType() {
      fetch(config.apiURL+'/v1/site-settings/get-is-prod')
        .then(response => response.json())
        .then(({status,isProd}) => {
          console.log(status,isProd)
          if (!isProd && !status)
            window.location = 'https://redbini.com/';
        })
      this.periodExam()
    },
    periodExam() {
      setTimeout(() => {
        this.startExam()
      }, 600000)
    },
    isEmptyObj(obj) {
      for (const key in obj) {
        return false;
      }
      return true;
    },

    writeReferalRecord() {
      const write = () => {
        if (this.$route.params.id && !this.isEmptyObj(this.$route.query)) {
          window.localStorage.setItem(
            'ref',
            JSON.stringify({ id: this.$route.params.id, ...this.$route.query })
          );
        } else if (!this.isEmptyObj(this.$route.query)) {
          window.localStorage.setItem('ref', JSON.stringify(this.$route.query));
        } else {
          window.localStorage.removeItem('ref');
        }
      };

      if (!this.isEmptyObj(this.$route.query)) {
        if (
          window.localStorage.ref &&
          JSON.parse(window.localStorage.ref).ref !== this.$route.query
        ) {
          write();
        }
        if (!window.localStorage.ref) {
          write();
        }
      } else {
        window.localStorage.removeItem('ref');
      }
    },

    toggleState() {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        hideLoader();
        this.loaded = true;
      }, 400);
    },
    registerSession() {
      this.$store.dispatch('registerUserSession', {
        token: localStorage['access-token'],
        frontendVersion: this.frontendVersion,
      });
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/common.scss';

.preload_wrap {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

/**
 * Created by Work_2 on 19.02.2017.
 */
import Vue from 'vue';

import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import zipObject from 'lodash/zipObject';

/**
 * Проверка необходимости запуска модалки с лицензионным соглашением клиентки
 *
 * @param context - контекст выполнения action
 */
const checkAgreement = function () {
  /* if (!window.localStorage['agreement']) {
    context.commit('updateWmnAgreementModal', {
      open: true
    })
  } else {
    try {
      const arr = JSON.parse(window.localStorage['agreement']);
      let match = arr.find(i => i.id === context.getters.user.user_id);
      if (!match) {
        context.commit('updateWmnAgreementModal', {
          open: true
        })
      }
    } catch (e) {
    }
  } */
  return;
};

const state = {
  userDataGtagSent: false,
  frontendVersion: '1.2.6-natalia-site',
  user: {
    profile_additional_info: {},
    update: false,
  },
  companionId: 0,
  message: [],
  winks: {
    myWinks: [],
    iSent: [],
  },
  bookmarks: [],
  favorites: {
    accepted: [],
    followers: [],
  },
  dispatch: [],
  counters: {},
  photos: [],
  groups: { paySystems: null },
  paySystemsStatic: [
    { id: 1, name: 'Multicard' },
    { id: 2, name: 'SegPay' },
    { id: 3, name: 'Epayments' },
    { id: 4, name: 'CCBill' },
    { id: 5, name: 'Payments Trust' },
    { id: 6, name: 'Bill Line UA Hryvnas' },
    { id: 7, name: 'Bill Line US Dollars' },
    { id: 8, name: 'Stripe' },
  ],
  settings: {},
  siteSettings: {},
  guests: [],
  balance: 0,
  childWindow: {},
  questionnaireStatus: false,
  questionsForQuestionnaire: [],
  questionnaireCreditsReward: 0,
  questionnaireStep: null,
};

const getters = {
  userDataGtagSent(state) {
    return state.userDataGtagSent;
  },
  paySystemsStatic(state) {
    return state.paySystemsStatic;
  },
  userPaymentsGroup(state) {
    return state.groups.paySystems;
  },
  frontendVersion(state) {
    return state.frontendVersion;
  },
  questionnaireStatus(state) {
    return state.questionnaireStatus;
  },
  questionsForQuestionnaire(state) {
    return state.questionsForQuestionnaire;
  },

  questionnaireCreditsReward(state) {
    return state.questionnaireCreditsReward;
  },
  questionnaireStep(state) {
    return state.questionnaireStep;
  },
  /**
   * получить дочернее окно (неактино_
   *
   * @param state
   * @return {state.childWindow|{}|*}
   */
  childWindow(state) {
    return state.childWindow;
  },
  /**
   * получить текущего пользователя
   *
   * @param state
   * @return {state.user|{profile_additional_info, update}|*}
   */
  user(state) {
    return state.user;
  },
  companionId(state) {
    return state.companionId;
  },

  message(state) {
    return state.message;
  },

  /**
   * получить пол пользователя
   *
   * @param state
   * @return {{isMan: boolean, isWoman: boolean}}
   */
  sex(state) {
    // two variables because of more exact calculations
    const male = state.user.user_type === 'man';
    const female = state.user.user_type === 'woman';
    return {
      isMan: male,
      isWoman: female,
    };
  },
  /**
   * получить флаг об обновлении профиля
   *
   * @param state
   * @return {boolean}
   */
  profileUpdated(state) {
    return state.user.update;
  },
  /**
   * получить счётчики
   *
   * @param state
   * @return {state.counters|{}|*}
   */
  counters(state) {
    return state.counters;
  },
  /**
   * получить список пользователей, которые подмигнули пользователю или пользователь
   * подмигнул им
   *
   * @param state
   * @return {state.winks|{myWinks, iSent}}
   */
  winks(state) {
    return state.winks;
  },
  /**
   * получить список пользователей, которые заходили на страницу пользователя
   *
   * @param state
   * @return {Array|*}
   */
  guests(state) {
    return state.guests;
  },
  /**
   * получить список пользователей, которых добавил пользователь
   *
   * @param state
   * @return {Array|*}
   */
  bookmarks(state) {
    return state.bookmarks;
  },
  /**
   * получить список пользователей, которых добавил пользователь или которые добавили его
   *
   * @param state
   * @return {state.favorites|{accepted, followers}}
   */
  favorites(state) {
    return state.favorites;
  },
  /**
   * получить список фотографий пользователя
   *
   * @param state
   * @return {Array|*}
   */
  photos(state) {
    return state.photos;
  },
  /**
   * получить настройки сайта
   *
   * @param state
   * @return {state.siteSettings|{}|*}
   */
  siteSettings(state) {
    console.log(state.siteSettings)
    return state.siteSettings;
  },
  /**
   * получить настройки пользователя
   *
   * @param state
   * @return {state.settings|{}|*}
   */
  settings(state) {
    return state.settings;
  },
  /**
   * получить рассылки пользователя
   *
   * @param state
   * @return {Array|*}
   */
  dispatch(state) {
    return state.dispatch;
  },
  /**
   * получить баланс пользоватедя
   *
   * @param state
   * @param getters
   * @return {*}
   */
  balance(state, getters) {
    return getters.sex.isMan ? state.balance : false;
  },
};

const mutations = {
  setUserDataGtagSent(state, payload) {
    state.userDataGtagSent = payload;
  },
  setRegisteredOnChatStatus(state, payload) {
    Object.assign(state.user, { chat: payload.status });
  },

  setCompanion(state, payload) {
    state.companionId = payload;
  },

  updateMessage(state, payload) {
    state.message = payload;
  },
  /**
   * установить дочернее окно (неактивно)
   *
   * @param state
   * @param payload
   */
  setChildWindow(state, payload) {
    state.childWindow = payload;
  },
  /**
   * обновить пользователя системы
   *
   * @param state
   * @param payload
   */
  updateUser(state, payload) {
    payload.update = !state.user.update;
    state.user = { ...state.user, ...payload };
    if (state.user.user_type === 'woman') {
      window.localStorage.setItem('sex', 'woman'); // use only in non-important situations
    }
  },
  /**
   * записать пользователя системы
   *
   * @param state
   * @param payload
   */
  updateUserProfile(state, payload) {
    Object.assign(state.user, payload);
  },
  /**
   * записать чат токен пользователя
   *
   * @param state
   * @param payload
   */
  addChatToken(state, payload) {
    Object.assign(state.user, {
      chat: payload,
    });
  },
  /**
   * обновить список винков пользователя
   *
   * @param state
   * @param payload
   */
  updateWinks(state, payload) {
    if (payload.offset) {
      payload.data.forEach(() => state.winks[payload.type].push(payload.data));
    } else {
      state.winks[payload.type] = payload.data;
    }
  },
  /**
   * обновить список гостей пользователя
   *
   * @param state
   * @param payload
   */
  updateGuests(state, payload) {
    if (payload.offset) {
      payload.data.forEach((item) => state.guests.push(item));
    } else {
      state.guests = payload.data;
    }
  },
  /**
   * обновить список закладок пользователя
   *
   * @param state
   * @param payload
   */
  updateBookmarks(state, payload) {
    if (payload.offset) {
      payload.data.forEach((item) => state.bookmarks.push(item));
    } else {
      state.bookmarks = payload.data;
    }
  },
  /**
   * обновить список фаворитов пользователя
   *
   * @param state
   * @param payload
   */
  updateFavorites(state, payload) {
    if (payload.offset) {
      payload.data.forEach(() => state.favorites[payload.type].push(payload.data));
    } else {
      state.favorites[payload.type] = payload.data;
    }
  },
  /**
   * обновить список фотографий пользователя
   *
   * @param state
   * @param payload
   */
  updatePhotos(state, payload) {
    state.photos = payload.data;
  },
  /**
   * записать список рассылок пользователя
   *
   * @param state
   * @param payload
   */
  setDispatch(state, payload) {
    state.dispatch = payload;
  },
  /**
   * записать настройки пользователя
   *
   * @param state
   * @param payload
   */
  setSettings(state, payload) {
    state.settings = payload;
  },
  /**
   * записать настройки пользователя
   *
   * @param state
   * @param payload
   */
  setUserGroups(state, payload) {
    state.groups.paySystems = payload;
  },
  /**
   * отключено
   *
   * @param state
   * @param payload
   */
  updateSettings(state, payload) {
    state[payload.type] = payload.data;
  },
  /**
   * записать баланс пользователя
   *
   * @param state
   * @param payload
   */
  setBalance(state, payload) {
    state.balance = payload;
  },
  /**
   * записать настройки сайта
   *
   * @param state
   * @param payload
   */
  getSiteSettings(state, payload) {
    console.log(payload)
    state.siteSettings = payload;
  },
  /**
   * записать счётчики
   *
   * @param state
   * @param payload
   */
  setCounters(state, payload) {
    state.counters = payload;
  },
  /**
   * сокет ответа на регистрацию в чате
   *
   * @param state
   * @param payload
   * @constructor
   */
  SOCKET_REGISTRATION_RESPONSE(state, payload) {
    Object.assign(state.user, { chat: payload.status });
  },
  QUESTIONNAIRE_STATUS(state, payload) {
    state.questionnaireStatus = payload;
  },
  QUESTIONS_FOR_QUESTIONNAIRE(state, payload) {
    state.questionsForQuestionnaire = payload;
  },

  QUESTIONNAIRE_REWARD(state, payload) {
    state.questionnaireCreditsReward = payload;
  },
  QUESTIONNAIRE_STEP(state, payload) {
    state.questionnaireStep = payload;
  },
};

const actions = {
  refreshMessage(context) {
    Vue.http
      .post('v1/message/load/dialog/one', {
        access_token: window.localStorage['access-token'],
        target_user_id: state.companionId,
        offset: 0,
        limit: 20,
      })
      .then((response) => {
        this.count = +response.body.count;
        const { result } = response.body;

        context.commit('updateMessage', response.body.result);

        if (!context) {
          result.forEach((i) => {
            this.selfUser.user_id !== i.companion.user_id ? (i.user_id = i.companion.user_id) : '';
          }, this);
        }

        const tmp = this.dialogs.concat(response.body.result);
        this.showListLoader = false;
        this.dialogs = orderBy(tmp, (e) => e.message.time_create, ['asc']);
        const self = this;
        setTimeout(() => {
          if (self.width <= 1200 && !self.offset) {
            const el = self.$refs.dialogs.lastElementChild;
            self.$scrollTo(el);
          }
        }, 500);
      });
  },

  /**
   * сокет ответа на обновление настроек звука
   *
   * @param dispatch
   * @param payload
   */
  socket_settingsUpdated({ dispatch }, payload) {
    if (payload.type !== 'site' && payload.updated) dispatch('getUserSettings', {});
  },
  /**
   * разлогинивание пользователя из фейсбука и сайта
   *
   * @param context
   */
  logout(context) {
    // eslint-disable-next-line no-undef
    /*FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        // eslint-disable-next-line no-undef
        FB.logout();
      } else {
        context.dispatch('logoutItself');
      }
    });*/
    context.dispatch('logoutItself');
  },
  /**
   * разлогинивание пользователя
   *
   * @param commit
   */
  logoutItself({ commit }) {
    commit('updateUser', {
      profile_additional_info: {},
      user_type: '',
    });
    window.localStorage.removeItem('access-token');
    window.localStorage.removeItem('chat-token');
    window.localStorage.removeItem('sex');
    window.localStorage.removeItem('locale');
    // window.sessionStorage.removeItem('questAuto');
    Vue.config.lang = 'en';
    setTimeout(() => {
      window.location.href = '/';
    }, 0);
  },
  /**
   * получить баланс пользователя
   *
   * @param context
   */
  getUserBalance(context) {
    // get balance
    if (context.getters.sex.isMan) {
      Vue.http
        .post('v1/credit/balance', {
          access_token: window.localStorage['access-token'],
        })
        .then(
          (response) => {
            if (response.body.status) {
              context.commit('setBalance', response.body.credits);
            } else {
              context.dispatch('addAlert', {
                type: 'error',
                text: Vue.t('alerts.someErr'),
                info: 'balance check',
              });
            }
          },
          () => {
            context.dispatch('addAlert', {
              type: 'error',
              text: Vue.t('alerts.someErr'),
              info: 'balance check',
            });
          }
        );
    }
  },

  getQuestionnaire(context) {
    // get balance
    if (context.getters.sex.isMan) {
      Vue.http
        .post('v1/questionnaire/load', {
          access_token: window.localStorage['access-token'],
        })
        .then(
          (response) => {
            // console.log('resp q', response);
            // if ( response.body.length == 0 ) {
            //   console.log('return', response.body.length);
            //   context.commit('updateQuestionnaireModal', {
            //     open: false,
            //   });
            // }
            const { credits_reward, step } = response.body;
            delete response.body.credits_reward;
            delete response.body.step;

            const questions = toPairs(response.body)
              .map((i) => zipObject(['name', 'value'], i))
              .filter((i) => i.value);

            context.commit('QUESTIONNAIRE_STATUS', !!questions.length);
            context.commit('QUESTIONS_FOR_QUESTIONNAIRE', questions);
            context.commit('QUESTIONNAIRE_REWARD', credits_reward);
            context.commit('QUESTIONNAIRE_STEP', step);

            /* clearTimeout(timer);

            if (!!questions.length) {
              if (sessionStorage.getItem('questAuto') === null) {
                sessionStorage.setItem('questAuto', JSON.stringify(true));
                timer = setTimeout(() => {
                  context.commit('updateQuestionnaireModal', {
                    open: true,
                  });
                }, 1000);
              } else if (JSON.parse(sessionStorage.getItem('questAuto'))) {
                timer = setTimeout(() => {
                  context.commit('updateQuestionnaireModal', {
                    open: true,
                  });
                }, 1000);
              }
            } */
          },
          () => {
            context.dispatch('addAlert', {
              type: 'error',
              text: Vue.t('alerts.someErr'),
              info: 'balance check',
            });
          }
        );
    }
  },
  /**
   * получить основные данные о пользователе и установить активность
   *
   * @param context
   * @param payload
   */
  getUserData(context, payload) {
    context.dispatch('getUserProfile', payload);
    context.dispatch('getSiteSettings');
    context.dispatch('getUserSettings', {});
    context.dispatch('getCounters', {});
    context.dispatch('getUserGroups', {});
    Vue.http.post('v1/active/set', {
      access_token: window.localStorage['access-token'],
    });
  },
  /**
   * загрузить фотографии пользователя
   *
   */
  loadPhotos(context) {
    Vue.http
      .post('v1/photo/my-photo', {
        access_token: window.localStorage['access-token'],
        limit: 0,
      })
      .then(
        (response) => {
          context.commit('updatePhotos', {
            data: response.body.result,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  },
  /**
   * получить настройки сайта
   *
   * @param context
   * @param payload
   */
  getSiteSettings(context) {
    const accessToken = window.localStorage['access-token'];

    const requestBody = {};

    if (accessToken) requestBody.access_token = accessToken;

    Vue.http.post('v1/site-settings', requestBody).then(
      (response) => {
        if (response.body.status) {
          context.commit('getSiteSettings', response.body.result);
        } else {
          context.dispatch('addAlert', {
            type: 'error',
            text: Vue.t('alerts.someErr'),
            info: 'site settings check',
          });
        }
      },
      () => {
        context.dispatch('addAlert', {
          type: 'error',
          text: Vue.t('alerts.someErr'),
          info: 'site settings check',
        });
      }
    );
  },
  /**
   * проверка на давность входа на сайт. Если не заходили дольше месяца - проверяем, нужно ли
   * показать модалку лицензионного соглашения клиентки
   *
   * @param context
   */
  launchAgreementModal(context) {
    if (context.getters.sex.isWoman) {
      if (window.localStorage.lastActive) {
        try {
          const date = +window.localStorage.lastActive;
          const currentDate = Date.now();
          if (
            currentDate - date >
            context.rootGetters.config.wmnAgreementModal.launchInterval * 24 * 60 * 60 * 1000
          ) {
            // n * hours * min * sec * ms
            checkAgreement(context);
          } else {
            checkAgreement(context);
          }
          window.localStorage.setItem('lastActive', Date.now());
        } catch (e) {
          checkAgreement(context);
        }
      } else {
        checkAgreement(context);
        window.localStorage.setItem('lastActive', Date.now());
      }
    }
  },
  /**
   * получить профиль пользователя
   *
   * @param context
   * @param payload
   */
  getUserProfile(context, payload) {
    Vue.http
      .post('v1/profile/profile', {
        access_token: window.localStorage['access-token'],
      })
      .then(
        (response) => {
          if (response.body.status === true) {
            const tmp = {};
            for (const key in response.body.result) {
              tmp[key] = response.body.result[key];
            }
            tmp.accessToken = window.localStorage['access-token'];
            context.commit('updateUser', tmp);
            context.dispatch('getUserBalance');
            context.dispatch('getQuestionnaire');
            context.dispatch('launchAgreementModal');
            if (payload) context.dispatch('makeGuest', parseInt(payload.targetId));
          } else {
            context.dispatch('addAlert', {
              type: 'error',
              text: Vue.t('alerts.someErr'),
              info: 'get profile',
            });
          }
        },
        () => {
          context.dispatch('logout');
        }
      );
  },
  /**
   * получить группы пользователя
   *
   * @param context
   */
  getUserGroups(context) {
    Vue.http
      .post('v1/user-settings/user-groups', {
        access_token: window.localStorage['access-token'],
      })
      .then((res) => {
        if (res.body.status) {
          context.commit('setUserGroups', res.body.result.group_pay);
        } else {
          context.dispatch('addAlert', {
            type: 'error',
            text: Vue.t('alerts.someErr'),
            info: 'get groups',
          });
        }
      })
      .catch(() => {
        context.dispatch('addAlert', {
          type: 'error',
          text: Vue.t('alerts.someErr'),
          info: 'get groups',
        });
      });
  },
  /**
   * получить настройки пользователя
   *
   * @param context
   */
  getUserSettings(context) {
    Vue.http
      .post('v1/user-settings/get', {
        access_token: window.localStorage['access-token'],
      })
      .then(
        (response) => {
          if (response.body.status) {
            context.commit('setSettings', response.body.result);
          } else {
            context.dispatch('addAlert', {
              type: 'error',
              text: Vue.t('alerts.someErr'),
              info: 'get settings',
            });
          }
        },
        () => {
          context.dispatch('addAlert', {
            type: 'error',
            text: Vue.t('alerts.someErr'),
            info: 'get settings',
          });
        }
      );
  },
  /**
   * получить список рассылок пользователя
   *
   * @param context
   */
  getDispatch(context) {
    Vue.http
      .post('v1/message/distribution/load', {
        access_token: window.localStorage['access-token'],
      })
      .then((r) => {
        if (r.body.status) {
          context.commit('setDispatch', r.body.result);
        }
      });
  },
  /**
   * записать настройки пользователя
   *
   * @param context
   * @param payload
   */
  setSettings(context, payload) {
    const data = {
      access_token: window.localStorage['access-token'],
    };
    for (const key in payload) {
      data[key] = payload[key];
    }
    Vue.http.post('v1/user-settings/set', data).then(() => {
      context.dispatch('getUserSettings');
    });
  },
  /**
   * получить данные счётчиков
   *
   * @param context
   */
  getCounters(context) {
    Vue.http
      .post('v1/profile/count', {
        access_token: window.localStorage['access-token'],
      })
      .then((response) =>
        response.body.status ? context.commit('setCounters', response.body.result) : ''
      );
  },
  /**
   * сделать запрос о визите на страницу другого пользователя "гость"
   *
   * @param context
   * @param payload
   */
  makeGuest(context, payload) {
    if (context.getters.user.user_id !== payload && payload) {
      Vue.http.post('v1/guest/make', {
        access_token: window.localStorage['access-token'],
        target_user_id: payload,
      });
    }
  },

  /**
   * загрузить список фаворитов
   *
   * @param context
   * @param payload
   */
  loadFavorites(context, payload) {
    if (payload.accept) {
      Vue.http
        .post('v1/favorite/load-accept', {
          access_token: window.localStorage['access-token'],
          limit: payload.limit || '',
          offset: payload.offset || '',
        })
        .then(
          (response) => {
            context.commit('updateFavorites', {
              type: 'accepted',
              data: response.body.result,
            });
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      Vue.http
        .post('v1/favorite/load-follower', {
          access_token: window.localStorage['access-token'],
          limit: payload.limit || 0,
          offset: payload.offset || 0,
        })
        .then(
          (response) => {
            context.commit('updateFavorites', {
              type: 'followers',
              data: response.body.result,
            });
          },
          (error) => {
            console.log(error);
          }
        );
    }
  },
  /**
   * загрузить список винков
   *
   * @param context
   * @param payload
   */
  loadWinks(context, payload) {
    Vue.http
      .post('v1/wink/load', {
        access_token: window.localStorage['access-token'],
        i_winked: payload.my || '',
        limit: payload.limit || 0,
        offset: payload.offset || 0,
      })
      .then(
        (response) => {
          if (payload.my) {
            context.commit('updateWinks', {
              offset: payload.offset,
              type: 'iSent',
              data: response.body.result,
            });
          } else {
            context.commit('updateWinks', {
              offset: payload.offset,
              type: 'myWinks',
              data: response.body.result,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  },
  /**
   * загрузить список закладок
   *
   * @param context
   * @param payload
   */
  loadAllBookmarks(context, payload) {
    Vue.http
      .post('v1/bookmark/load', {
        access_token: window.localStorage['access-token'],
        offset: payload.offset || 0,
        limit: payload.limit || 0,
      })
      .then(
        (response) => {
          context.commit('updateBookmarks', {
            offset: payload.offset,
            data: response.body.result,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

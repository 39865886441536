/* eslint-disable no-shadow */
const state = {
  token: false,
  status: '',
  refreshStatus: false,
  isAuthenticated: localStorage['access-token'],
  user: {},
  error: '',
  login_validate: {
    v_loading: false,
    v_error: false,
    v_success: false,
  },
  isPreloader: true,
};
const mutations = {
  AUTH_REQUEST: (state) => {
    state.status = 'loading';
  },
  AUTH_SUCCESS: (state, user) => {
    state.status = 'success';
    state.token = user.token;
    state.user = user;
  },
  TOKEN: (state, status) => {
    state.refreshStatus = status;
  },
  AUTH_ERROR: (state, err) => {
    state.status = 'error';
    state.error = err.response ? err.response.data.errors : '';
  },
  SET_TOKEN: (state, token) => {
    state.isAuthenticated = token;
  },
  AUTH_LOGOUT: (state) => {
    state.user = {};
    state.status = '';
  },
  VALIDATE_STATUS: (state, status) => {
    state.login_validate.v_loading = status.v_loading;
    state.login_validate.v_error = status.v_error;
    state.login_validate.v_success = status.v_success;
  },
  INDEX_PRELOADER: (state, status) => {
    state.isPreloader = status;
  },
};
const actions = {
  /**
   * Получение данных пользователя
   *
   * @return {Promise}
   *
   */
  /**
   * Авторизация в системе и обработка запроса
   *
   * @param user {object}
   * @return {Promise}
   *
   */
  AUTH_REQUEST: (user) => {
    console.log(user);
    /* commit('INDEX_PRELOADER', true);
    commit('TOKEN', false);
    return new Promise((resolve, reject) => {
      axios
        .post('api/auth/login', user)
        .then((response) => {
          const user_data = {};
          user_data.token = response.headers.authorization;
          user.token = response.headers.authorization;
          localStorage.setItem('access-token', response.headers.authorization);
          axios.defaults.headers.common.Authorization = user.token;
          commit('AUTH_SUCCESS', user_data);
          dispatch('USER_PROFILE');
          commit('AUTH_ERROR', '');
          commit('TOKEN', true);
          commit('SET_TOKEN', user.token);
          setTimeout(() => {
            commit('INDEX_PRELOADER', false);
          }, 300);
          resolve(response);
        })
        .catch((err) => {
          commit('INDEX_PRELOADER', false);
          commit('AUTH_ERROR', err);
          // localStorage.removeItem ( 'access-token' );
          commit('AUTH_ERROR', err);
          reject(err);
        });
    }); */
  },
};
const getters = {
  isAuthenticated: (state) => !!state.isAuthenticated,
  isPreloader: (state) => state.isPreloader,
};

export default {
  state,
  getters,
  mutations,
  actions,
};

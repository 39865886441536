<template>
  <div v-if="showCookie" id="cookies">
    <div id="cookiesInfo">
      OUR SITE USES COOKIES TO DISTINGUISH YOU FROM OTHER USERS OF OUR SITE. THIS ALLOWS YOU TO
      CONVENIENTLY VIEW OUR SITE, AND ALSO MAKES IT POSSIBLE TO IMPROVE IT. IN ORDER TO CONTINUE
      WORKING WITH THE SITE, YOU NEED TO ACCEPT THE USE OF COOKIES. YOU CAN CONFIGURE THE STORAGE
      CONDITIONS FOR YOUR DATA IN THE BROWSER OR IN OUR
      <router-link :to="{ name: 'cookie' }"> COOKIES POLICY </router-link> COOKIES POLICY , WHERE
      YOU CAN ALSO FIND GENERAL INFORMATION ABOUT THE PROCESSING OF YOUR DATA.
    </div>
    <a id="cookiesClose" href="#" @click.prevent="closeCookie"
      ><span class="rbi rbi-close" style="color: whitesmoke"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'Cookie',
  data() {
    return {
      showCookie: false,
    };
  },
  computed: {
    localCookieRecord() {
      // eslint-disable-next-line no-prototype-builtins
      return window.localStorage.hasOwnProperty('cookie-allow');
    },
  },
  watch: {
    localCookieRecord(val) {
      if (!val) this.showCookie = true;
    },
  },
  methods: {
    closeCookie() {
      this.showCookie = false;
      window.localStorage.setItem('cookie-allow', 'false');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/vars';
#cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $black;
  z-index: 10099;
  -webkit-box-shadow: 0 -3px 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -3px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -3px 7px rgba(0, 0, 0, 0.1);
  #cookiesInfo {
    padding: 20px 80px 20px 20px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    color: #b2b2b2;
    a {
      font-family: inherit;
      color: $red;
      text-decoration: none;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      -o-transform: translate(0);
    }
  }
  #cookiesClose {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 16px;
    height: 16px;
    background-size: 16px auto;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -o-transform: translate(0);
    color: $red;
  }
}
</style>

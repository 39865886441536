<template>
  <footer id="footer" class="footer rb-wrapper">
    <template v-if="showContent">
      <div class="footer-top">
        <div class="rb-container container">
          <div class="footer_header">
            <div class="pull-left">
              <div class="footer-logo">
                <router-link class="logo" :to="link" :title="$t('shared.menu.titles.home')" />
              </div>
              <nav class="footer_nav">
                <ul class="nav">
                  <li>
                    <router-link :to="{ name: 'about-us' }" :title="$t('shared.menu.titles.about')">
                      {{ $t('shared.menu.about') }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'faq' }" :title="$t('shared.menu.titles.faq')">
                      {{ $t('shared.menu.faq') }}
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name: 'feedback' }"
                      :title="$t('shared.menu.titles.contactUs')"
                    >
                      {{ $t('shared.menu.contactUs') }}
                    </router-link>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="pull-right">
              <ul class="social social-links">
                <li class="social-link">
                  <a :href="socialLink.facebook" target="_blank" class="social-icon rbi-facebook" />
                </li>
                <li class="social-link">
                  <a :href="socialLink.twitter" target="_blank" class="social-icon rbi-twitter" />
                </li>
                <li class="social-link">
                  <a :href="socialLink.youtube" target="_blank" class="social-icon rbi-youtube" />
                </li>
                <li class="social-link">
                  <a
                    :href="socialLink.instagram"
                    target="_blank"
                    class="social-icon rbi-instagram-new"
                  />
                </li>
                <li class="social-link">
                  <a
                    :href="socialLink.pinterest"
                    target="_blank"
                    class="social-icon rbi-pinterest"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-middle">
        <div class="rb-container container">
          <div class="services footer_services">
            <ul class="services-lists">
              <li class="services-item" style="justify-content: center">
                <img
                  v-lazy="'/static/images/services/visa-logo-m.webp'"
                  height="50"
                  width="149"
                  class="small-pay-image"
                  alt="Visa"
                />
              </li>
              <li class="services-item">
                <img
                  v-lazy="'/static/images/services/visa-secure-m.webp'"
                  height="50"
                  width="60"
                  alt="Verified by visa"
                />
              </li>
              <li class="services-item">
                <img
                  v-lazy="'/static/images/services/Ms2-m.webp'"
                  height="50"
                  width="61"
                  alt="Mastercard"
                />
              </li>
              <li class="services-item">
                <img
                  v-lazy="'/static/images/services/mastercard-secure-m.webp'"
                  class="image-reflection"
                  height="50"
                  width="93"
                  alt="Mastercard Secure Code"
                />
              </li>
              <!--            <li class="services-item">
                <a href="https://www.segpay.com/" target="_blank" title="SegPay">
                  <img src="/static/images/services/seqpay.png" alt="" />
                </a>
              </li>
              <li class="services-item">
                <a
                  href="https://www.mastercard.com/"
                  target="_blank"
                  title="MasterCard"
                >
                  <img src="/static/images/services/mastercard-h56.png" alt="" />
                </a>
              </li>
              <li class="services-item">
                <a href="https://www.visa.com/" target="_blank" title="Visa">
                  <img src="/static/images/services/visa.png" alt="" />
                </a>
              </li>
              <li class="services-item">
                <a
                  href="https://www.multicards.com"
                  target="_blank"
                  title="Multicards"
                >
                  <img src="/static/images/services/multicartds.png" alt="" />
                </a>
              </li>
              <li class="services-item">
                <a href="http://www.lpb.lv/en/" target="_blank" title="Latvijas pasta banka">
                  <img src="/static/images/services/lpb-h56.png" alt="">
                </a>
              </li>
              <li class="services-item">
                <a
                  href="https://www.epayments.com/"
                  target="_blank"
                  title="ePayments"
                >
                  <img src="/static/images/services/epayments.png" alt="" />
                </a>
              </li>
              <li class="services-item">
                <a href="https://ccbill.com/" target="_blank" title="CCBill">
                  <img src="/static/images/services/ccbill-logo.png" alt="" />
                </a>
              </li>
              <li class="services-item">
                <a
                  href="https://paymentstrust.com/"
                  target="_blank"
                  title="Payments Trust"
                >
                  <img
                    class="opacity_hover"
                    src="/static/images/services/paymentstrust-logo.png"
                    alt=""
                  />
                </a>
              </li>-->
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="rb-container container">
          <div class="bottom-flex" style="color: black">
            <p>2018 - {{ year }} © {{ siteName }}. All rights reserved</p>
            <ul>
              <li>
                <router-link :to="privacyLink" :title="$t('shared.menu.privacy')">
                  {{ $t('shared.menu.privacy') }}
                </router-link>
              </li>
              <li>
                <router-link :to="antiScamLink" :title="$t('shared.menu.antiScam')">
                  {{ $t('shared.menu.antiScam') }}
                </router-link>
              </li>
              <li v-if="!sex.isWoman">
                <router-link :to="{ name: 'refund' }" :title="$t('shared.menu.refund')">
                  {{ $t('shared.menu.refund') }}
                </router-link>
              </li>
              <li>
                <router-link :to="termsLink" :title="$t('shared.menu.terms')">
                  {{ $t('shared.menu.terms') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'cookie' }" :title="$t('shared.menu.cookie')">
                  {{ $t('shared.menu.cookie') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </footer>
</template>

<script>
import { socialLink } from '../../config/config';

export default {
  components: {},
  data() {
    return {
      socialLink,
      showContent: false,
    };
  },
  computed: {
    /**
     * получить ссылку для кнопки "Домой"
     *
     * @return {object}
     */
    link() {
      if (!this.user) {
        return {
          name: 'index',
        };
      }
      return {
        name: 'profile',
        params: {
          id: this.user,
        },
      };
    },
    /**
     * получить ссылку для кнопки "Антискам политика"
     *
     * @return {object}
     */
    antiScamLink() {
      return { name: 'antiscam' };
    },
    /**
     * получить ссылку для кнопки "Пользовательское соглашение"
     *
     * @return {object}
     */
    termsLink() {
      return this.sex.isWoman ? { name: 'terms-wu' } : { name: 'terms' };
    },
    /**
     * получить ссылку для кнопки "Политика приватности"
     *
     * @return {object}
     */
    privacyLink() {
      return this.sex.isWoman ? { name: 'privacy-wu' } : { name: 'privacy' };
    },
    sex() {
      return this.$store.getters.sex;
    },
    year() {
      return new Date().getFullYear();
    },
    isAuthorized() {
      return !!window.localStorage['access-token'];
    },
    user() {
      return this.$store.getters.user.user_id;
    },
    mcAfee() {
      return `https://www.mcafeesecure.com/verify?host=${window.location.hostname}`;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
  },
  mounted() {
    window.addEventListener('scroll', this.showContentAfterScroll);
  },
  methods: {
    showContentAfterScroll() {
      console.log('Scroll!');
      this.showContent = true;
      window.removeEventListener('scroll', this.showContentAfterScroll);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/vars';

#footer {
  background: $black;
  z-index: 0;
  padding-top: 0;
  color: $white;
  margin-top: 70px;
  .pull-right,
  .pull-left {
    display: flex;
    align-items: center;
    float: none !important;
    flex: 1 1 auto;
  }

  .footer-top {
    border-bottom: 1px solid #2a2a2a;
    .footer_header {
      width: 100%;
      background: $black;
      position: relative;

      padding: 30px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .footer-logo {
        .logo {
          display: inline-block;
          width: 128px;
          height: 30px;
          background: url('/static/images/svg/logo.svg') no-repeat center center;
          background-size: contain;
        }
      }

      .footer_nav {
        margin-left: 87px;

        .nav {
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            margin-left: 47px;
            font-size: $basic-font-size;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 1.6px;

            a {
              color: $white;
              position: relative;
              text-decoration: none;
              padding: 13px 0;

              &:before {
                content: '';
                display: block;
                height: 1px;
                background: $white;
                position: absolute;
                width: 50px;
                transform: translateX(-50%) scale(0);
                bottom: 0;
                left: 50%;
                transition: 0.3s;
              }

              &:hover {
                &:before {
                  transform: translateX(-50%) scale(1);
                }
              }
            }

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      &-bottom {
        display: flex;
        align-items: center;
        padding: 100px 0;

        .footer-service {
          .service {
            list-style: none;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;

            li {
              margin-right: 90px;
            }
          }
        }
      }

      &:after {
        content: '';
        display: block;
        width: 5%;
        height: 5px;
        position: absolute;
        left: 0;
        bottom: -3px;
        background: $red;
      }
    }
  }

  .services {
    padding: 30px 0;

    &-lists {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
    }
    &-item {
      margin-right: 50px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      .image-reflection {
        margin-top: 7px;
      }

      img {
        max-height: 65px;
        transition: 0.3s;
        // filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
        // filter: gray; /* IE6-9 */
        // -webkit-filter: grayscale(
        //   100%
        // ); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
        &.opacity_hover {
          opacity: 0.4;
        }
      }

      .small-pay-image {
        max-height: 50px !important;
      }

      &:hover {
        img {
          // filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
          // -webkit-filter: grayscale(0%);
          &.opacity_hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid #2a2a2a;

    .bottom-flex {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
        color: $white;
        font-size: $basic-font-size;
        font-weight: 300;
        line-height: 26px;
        letter-spacing: 1.6px;
      }
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
          color: $white;
          font-size: $basic-font-size;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: 1.6px;

          a {
            color: $white;
            position: relative;
            text-decoration: none;
            padding: 13px 0;

            &:before {
              content: '';
              display: block;
              height: 1px;
              background: $white;
              position: absolute;
              width: 50px;
              transform: translateX(-50%) scale(0);
              bottom: 0;
              left: 50%;
              transition: 0.3s;
            }

            &:hover {
              &:before {
                transform: translateX(-50%) scale(1);
              }
            }
          }

          &:after {
            content: '-';
            display: inline-block;
            margin: 0 30px;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .social {
    &-links {
      display: flex;
      color: #ffffff;
      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;
      justify-content: flex-end;
    }

    &-link {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 50px !important;

      &:last-child {
        margin-left: 0;
      }
    }

    &-icon {
      font-size: 24px;
      transition: 0.3s;
      color: $white;
      text-decoration: none;

      &:hover {
        color: $red;
      }

      &.rbi {
        &-youtube {
          font-size: 30px;
        }
        &-google-plus {
          font-size: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1720px) {
  $basic-font-size: 15px;
  #footer {
    .footer-top {
      .footer_header {
        .footer-logo {
          .logo {
            width: 112px;
            height: 32px;
            display: inline-block;
            -webkit-background-size: contain;
            background-size: contain;
          }
        }

        .footer_nav {
          margin-left: 40px;
          .nav {
            li {
              margin-left: 20px;
              font-size: $basic-font-size;
            }
          }
        }
      }
    }

    .services {
      padding: 60px 0;
      &-lists {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
      }
      &-item {
        margin-right: 45px;

        img {
          max-height: 40px;
          transition: 0.3s;
          // filter: grayscale(100%);
        }

        .small-pay-image {
          max-height: 30px !important;
        }

        a,
        span {
          &:hover {
            img {
              // filter: grayscale(0);
            }
          }
        }
      }
    }

    .footer-bottom {
      border-top: 1px solid #2a2a2a;
      .bottom-flex {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
          color: $white;
          font-size: $basic-font-size;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: 1.6px;
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0;

          li {
            color: $white;
            font-size: $basic-font-size;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 1.6px;

            a {
              color: $white;
              position: relative;
              text-decoration: none;
              padding: 13px 0;

              &:before {
                content: '';
                display: block;
                height: 1px;
                background: $white;
                position: absolute;
                width: 50px;
                transform: translateX(-50%) scale(0);
                bottom: 0;
                left: 50%;
                transition: 0.3s;
              }

              &:hover {
                &:before {
                  transform: translateX(-50%) scale(1);
                }
              }
            }

            &:after {
              content: '-';
              display: inline-block;
              margin: 0 10px;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .social {
      &-links {
        display: flex;
        color: #ffffff;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;
      }

      &-link {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px !important;

        &:last-child {
          margin-left: 0;
        }
      }

      &-icon {
        font-size: 22px;
        transition: 0.3s;
        color: $white;
        text-decoration: none;

        &:hover {
          color: $red;
        }

        &.rbi {
          &-youtube {
            font-size: 28px;
          }
          &-google-plus {
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1470px) {
  $basic-font-size: 14px;
  #footer {
    .footer-top {
      .footer_header {
        .footer-logo {
          .logo {
            width: 100px;
            height: 23px;
            display: inline-block;
            -webkit-background-size: contain;
            background-size: contain;
          }
        }

        .footer_nav {
          margin-left: 30px;
          .nav {
            li {
              margin-left: 20px;
              a {
                font-size: $basic-font-size;
                padding: 10px 0;
              }
            }
          }
        }
      }
    }

    .services {
      padding: 40px 0;
      &-lists {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
      }
      &-item {
        margin-right: 45px;
        img {
          max-height: 40px;
          transition: 0.3s;
          // filter: grayscale(100%);
        }

        .small-pay-image {
          max-height: 30px !important;
        }

        a,
        span {
          &:hover {
            img {
              // filter: grayscale(0);
            }
          }
        }
      }
    }

    .footer-bottom {
      border-top: 1px solid #2a2a2a;
      .bottom-flex {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
          color: $white;
          font-size: $basic-font-size;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: 1.6px;
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0;
          li {
            color: $white;
            font-size: $basic-font-size;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 1.6px;

            a {
              color: $white;
              position: relative;
              text-decoration: none;
              padding: 13px 0;

              &:before {
                content: '';
                display: block;
                height: 1px;
                background: $white;
                position: absolute;
                width: 50px;
                transform: translateX(-50%) scale(0);
                bottom: 0;
                left: 50%;
                transition: 0.3s;
              }

              &:hover {
                &:before {
                  transform: translateX(-50%) scale(1);
                }
              }
            }

            &:after {
              content: '-';
              display: inline-block;
              margin: 0 15px;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .social {
      &-links {
        display: flex;
        color: #ffffff;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;
      }

      &-link {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px !important;

        &:last-child {
          margin-left: 0;
        }
      }

      &-icon {
        font-size: 22px;
        transition: 0.3s;
        color: $white;
        text-decoration: none;

        &:hover {
          color: $red;
        }

        &.rbi {
          &-youtube {
            font-size: 28px;
          }
          &-google-plus {
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  $basic-font-size: 14px;

  #footer {
    margin-top: 40px;
    .footer-top {
      .footer_header {
        .footer-logo {
          .logo {
            width: 100px;
            height: 23px;
          }
        }

        .footer_nav {
          margin-left: 30px;
          .nav {
            li {
              margin-left: 15px;
              a {
                font-size: $basic-font-size;
                padding: 10px;
              }
            }
          }
        }
      }
    }

    .services {
      &-lists {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
      }
      &-item {
        margin-right: 45px;
        img {
          max-height: 40px;
          transition: 0.3s;
          // filter: grayscale(100%);
        }

        .small-pay-image {
          max-height: 30px !important;
        }

        a,
        span {
          &:hover {
            img {
              // filter: grayscale(0);
            }
          }
        }
      }
    }

    .footer-bottom {
      border-top: 1px solid #2a2a2a;
      .bottom-flex {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
          color: $white;
          font-size: $basic-font-size;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: 1.6px;
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0;
          li {
            color: $white;
            font-size: $basic-font-size;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 1.6px;

            a {
              color: $white;
              position: relative;
              text-decoration: none;
              padding: 13px 0;

              &:before {
                content: '';
                display: block;
                height: 1px;
                background: $white;
                position: absolute;
                width: 50px;
                transform: translateX(-50%) scale(0);
                bottom: 0;
                left: 50%;
                transition: 0.3s;
              }

              &:hover {
                &:before {
                  transform: translateX(-50%) scale(1);
                }
              }
            }

            &:after {
              content: '-';
              display: inline-block;
              margin: 0 15px;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .social {
      &-links {
        display: flex;
        color: #ffffff;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;
      }

      &-link {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px !important;

        &:last-child {
          margin-left: 0;
        }
      }

      &-icon {
        font-size: 22px;
        transition: 0.3s;
        color: $white;
        text-decoration: none;

        &:hover {
          color: $red;
        }

        &.rbi {
          &-youtube {
            font-size: 28px;
          }
          &-google-plus {
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  $basic-font-size: 14px;

  #footer {
    .footer-top {
      .footer_header {
        .footer-logo {
          .logo {
            width: 100px;
            height: 23px;
          }
        }

        .footer_nav {
          margin-left: 30px;
          .nav {
            li {
              margin-left: 15px;
              a {
                font-size: $basic-font-size;
                padding: 10px;
              }
            }
          }
        }
      }
    }

    .services {
      padding: 40px 0;
      &-lists {
        list-style: none;

        margin: 0;
        padding: 0;
      }
      &-item {
        margin-right: 30px;
        margin: 30px 30px 30px 0;
        img {
          max-height: 45px;
          transition: 0.3s;
          // filter: grayscale(100%);
        }

        .small-pay-image {
          max-height: 30px !important;
        }

        a,
        span {
          &:hover {
            img {
              // filter: grayscale(0);
            }
          }
        }
      }
    }

    .footer-bottom {
      border-top: 1px solid #2a2a2a;
      .bottom-flex {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
          color: $white;
          font-size: $basic-font-size;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: 1.6px;
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0;
          li {
            color: $white;
            font-size: $basic-font-size;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 1.6px;

            a {
              color: $white;
              position: relative;
              text-decoration: none;
              padding: 13px 0;

              &:before {
                content: '';
                display: block;
                height: 1px;
                background: $white;
                position: absolute;
                width: 50px;
                transform: translateX(-50%) scale(0);
                bottom: 0;
                left: 50%;
                transition: 0.3s;
              }

              &:hover {
                &:before {
                  transform: translateX(-50%) scale(1);
                }
              }
            }

            &:after {
              content: '-';
              display: inline-block;
              margin: 0 15px;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .social {
      &-links {
        display: flex;
        color: #ffffff;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;
      }

      &-link {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px !important;

        &:last-child {
          margin-left: 0;
        }
      }

      &-icon {
        font-size: 18px;
        transition: 0.3s;
        color: $white;
        text-decoration: none;

        &:hover {
          color: $red;
        }

        &.rbi {
          &-youtube {
            font-size: 24px;
          }
          &-google-plus {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1260px) {
  #footer .footer-bottom .bottom-flex {
    flex-direction: column-reverse;
    ul {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 991px) {
  #footer {
    margin-top: 30px;
    overflow: hidden;
    padding-bottom: 0;
    .pull-right,
    .pull-left {
      display: flex;
      align-items: center;
      float: none !important;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
    }

    .pull-left {
      justify-content: space-between;
      position: relative;
      padding-bottom: 30px;
      &:after {
        content: '';
        display: block;
        width: 300%;
        height: 1px;
        position: absolute;
        left: -100%;
        bottom: 0;
        background: #2a2a2a;
        z-index: 0;
      }
      &:before {
        content: '';
        display: block;
        width: 5%;
        height: 5px;
        position: absolute;
        left: 0;
        bottom: -2px;
        background: $red;
        z-index: 1;
      }
    }

    .footer-top {
      .footer_header {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        .footer-logo {
          .logo {
            width: 132px;
            height: 32px;
          }
        }

        &:after {
          display: none;
        }

        .footer_nav {
          .nav {
            li {
              margin-left: 15px;
              a {
                font-size: 20px;
                padding: 10px;
              }
            }
          }
        }
      }
    }

    .services {
      padding: 40px 0;
      &-lists {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
      &-item {
        margin: 0;
        img {
          max-height: 50px;
          transition: 0.3s;
          // filter: grayscale(100%);
        }

        .small-pay-image {
          max-height: 40px !important;
        }

        a,
        span {
          &:hover {
            img {
              // filter: grayscale(0);
            }
          }
        }
      }
    }

    .footer-bottom {
      border-top: 1px solid #2a2a2a;
      .bottom-flex {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;

        p {
          margin: 0;
          color: $white;
          font-size: $basic-font-size;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: 1.6px;
          order: 2;
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin: 0 0 20px;
          padding: 0;
          order: 1;
          li {
            color: $white;
            font-size: $basic-font-size;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 1.6px;

            a {
              color: $white;
              position: relative;
              text-decoration: none;
              padding: 13px 0;

              &:before {
                content: '';
                display: block;
                height: 1px;
                background: $white;
                position: absolute;
                width: 50px;
                transform: translateX(-50%) scale(0);
                bottom: 0;
                left: 50%;
                transition: 0.3s;
              }

              &:hover {
                &:before {
                  transform: translateX(-50%) scale(1);
                }
              }
            }

            &:after {
              display: none;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .social {
      &-links {
        display: flex;
        color: #ffffff;
        list-style: none;
        width: 100%;
        padding: 30px 0;
        margin: 0;
      }

      &-link {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0 !important;
      }

      &-icon {
        font-size: 22px;
        &.rbi {
          &-youtube {
            font-size: 28px;
          }
          &-google-plus {
            font-size: 28px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  #footer {
    margin-top: 20px;
    .pull-right,
    .pull-left {
      display: flex;
      align-items: center;
      float: none !important;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
    }

    .pull-left {
      position: relative;
      padding-bottom: 30px;
      justify-content: space-between;
      &:after {
        content: '';
        display: block;
        width: 300%;
        height: 1px;
        position: absolute;
        left: -100%;
        bottom: 0;
        background: #2a2a2a;
        z-index: 0;
      }
      &:before {
        content: '';
        display: block;
        width: 5%;
        height: 5px;
        position: absolute;
        left: 0;
        bottom: -2px;
        background: $red;
        z-index: 1;
      }
    }

    .footer-top {
      .footer_header {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        .footer-logo {
          .logo {
            width: 43px;
            height: 34px;
            background: url('/static/images/svg/logo_small.svg') no-repeat center center;
          }
        }

        &:after {
          display: none;
        }

        .footer_nav {
          .nav {
            li {
              margin-left: 15px;
              a {
                font-size: 14px;
                padding: 10px 0;
              }
            }
          }
        }
      }
    }

    .services {
      padding: 15px 0;
      &-lists {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      &-item {
        margin: 15px;
        img {
          max-height: 40px;
          transition: 0.3s;
          // filter: grayscale(100%);
        }

        .small-pay-image {
          max-height: 30px !important;
        }

        a,
        span {
          &:hover {
            img {
              // filter: grayscale(0);
            }
          }
        }
      }
    }

    .footer-bottom {
      border-top: 1px solid #2a2a2a;
      .bottom-flex {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;

        p {
          margin: 0;
          color: $white;
          font-size: 14px;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: 1.6px;
          order: 2;
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0 0 20px;
          padding: 0;
          order: 1;
          flex-direction: column;
          li {
            color: $white;
            font-size: 14px;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 1.6px;

            a {
              color: $white;
              position: relative;
              text-decoration: none;
              padding: 13px 0;

              &:hover {
                &:before {
                  transform: translateX(-50%) scale(1);
                }
              }
            }

            &:after {
              display: none;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .social {
      &-links {
        display: flex;
        color: #ffffff;
        list-style: none;
        width: 100%;
        padding: 30px 0;
        margin: 0;
        justify-content: flex-end;
      }

      &-link {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0 !important;
      }

      &-icon {
        font-size: 22px;
        &.rbi {
          &-youtube {
            font-size: 28px;
          }
          &-google-plus {
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .footer_header {
    .pull-left {
      flex-direction: column;
      .footer-logo {
        margin-bottom: 10px;
      }
      .footer_nav {
        margin-left: 0 !important;
      }
    }
  }
}
</style>

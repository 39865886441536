<template>
  <nav>
    <ul class="nav">
      <slot name="beforeNav" />
      <slot />
      <slot name="afterNav" />
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/_vars.scss";

  .header_nav {
    .nav {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-left: 47px;
        font-size: $basic-font-size;
        font-weight: 300;
        line-height: 26px;
        letter-spacing: 1.6px;

        a {
          color: $black;
          position: relative;
          text-decoration: none;
          padding: 13px 0;
          outline: none;

          &:before {
            content: '';
            display: block;
            height: 1px;
            background: $black;
            position: absolute;
            width: 100%;
            max-width: 50px;
            transform: translateX(-50%) scale(0);
            bottom: 0;
            left: 50%;
            transition: .3s;
          }

          &:hover {
            &:before {
              transform: translateX(-50%) scale(1);
            }
          }
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .header_nav_responsive{
    position: absolute;
    top: 135%;
    z-index: 10;
    left: -25px;
    visibility: hidden;
    transform: translateY(-10px);
    opacity: 0;
    transition: .3s;
    .nav{
      margin: 0;
      list-style: none;
      background: $white;
      padding: 15px;
      min-width: 130px;
      box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.3);
      position: relative;
      li{
        a{
          display: block;
          padding: 5px 0;
          color: $black;
        }
      }
      &:before{
        position: absolute;
        display: inline-block;
        content: '';
        top: -7px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #fff;
        left: 27px;
      }
    }
    &.open {
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 1720px) {
    .header{
      &_nav{
        .nav{
          li{
            margin-left: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1470px) {
    $basic-font-size: 14px;

    .header{
      &_nav{
        .nav{
          li{
            margin-left: 20px;
            a{
              font-size: $basic-font-size;
              padding: 10px 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1320px) {

    $basic-font-size: 14px;

    .header{
      &_nav{
        .nav{
          li{
            margin-left: 15px;
            a{
              font-size: $basic-font-size;
              padding: 10px;
            }
          }
        }
      }
    }

  }

  @media screen and (max-width: 1260px) {
    $basic-font-size: 14px;
    .header{
      &_nav{
        .nav{
          li{
            a{
              font-size: $basic-font-size;
              padding: 10px 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .header{
      &_nav{
        display: none;
        .nav{
          li{
            a{
              font-size: $basic-font-size;
              padding: 10px 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .header_nav_responsive{
      left: -10px;
      .nav{
        &:before{
          top: -7px;
          left: 12px;
        }
      }
    }
  }
</style>

/* eslint-disable no-shadow */
const state = { registeredOnChatServer: false };

const getters = {};
const mutations = {
  SET_REGISTERED_ON_CHAT_SERVER(state, payload) {
    state.registeredOnChatServer = payload;
  },
};
const actions = {
  registerUserSession(context, payload) {
    const ua = window.navigator.userAgent.toLowerCase();

    window.socketIo.emit('registration', {
      site_token: payload.token,
      type: 'site',
      front_v: payload.frontendVersion,
      user_agent: ua,
    });
  },
  socket_session_error(context) {
    context.commit('SET_REGISTERED_ON_CHAT_SERVER', false);
    /* window.location = context.getters.mediaServerUrl */
  },
  socket_session_success(context) {
    context.commit('SET_REGISTERED_ON_CHAT_SERVER', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

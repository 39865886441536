/**
 * Created by Alex Shvets on 10.05.2017.
 */

export const LOAD_PROFILE = {
  methods: {
    /**
     * загрузить свой профиль
     */
    loadProfile() {
      if (this.authorized) {
        this.$store.dispatch('getUserData', {});
      }
    },
  },
  computed: {
    authorized() {
      return !!window.localStorage['access-token'];
    },
  },
  beforeMount() {
    this.loadProfile();
  },
};

export const SELECTION = {
  computed: {
    isGuest() {
      return !window.localStorage['access-token'];
    },
  },
  methods: {
    /**
     * запретить взаимодействие с чем-либо, если пользователь - гость
     *
     * @param e
     */
    interact(e) {
      if (this.isGuest) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
  },
};

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

const NotFound = () => import('../components/Shared/NotFound.vue');

const Guest = () => import(/* webpackChunkName: "app-guest" */ '../components/Guest/Guest.vue');
const ForgetPass = () =>
  import(/* webpackChunkName: "app-guest" */ '../components/Shared/ForgetPass.vue');
const RbIndex = () => import(/* webpackChunkName: "app-guest" */ '../views/Index');

const Profile = () =>
  import(/* webpackChunkName: "user-profile" */ '../components/Shared/UpdatedProfile.vue');
  // import(/* webpackChunkName: "user-profile" */ '../components/Shared/Profile.vue');

/* import EmailApprove from '../components/Shared/EmailApprove.vue'; */

const Authorized = () =>
  import(/* webpackChunkName: "user-authorized" */ '../components/Authorized/Authorized.vue');
const Logout = () => import(/* webpackChunkName: "user-authorized" */ '../views/Logout');

// Settings
const Settings = () =>
  import(/* webpackChunkName: "user-settings" */ '../components/Authorized/Settings.vue');
const settingsGeneral = () =>
  import(/* webpackChunkName: "user-settings" */ '../components/Authorized/settings/General.vue');
const settingsNotification = () =>
  import(
    /* webpackChunkName: "user-settings" */ '../components/Authorized/settings/Notifications.vue'
  );

// Albums
const Albums = () =>
  import(/* webpackChunkName: "user-albums" */ '../components/Authorized/Albums.vue');
const Album = () =>
  import(/* webpackChunkName: "user-albums" */ '../components/Authorized/Albums/Album.vue');
const allAlbums = () =>
  import(/* webpackChunkName: "user-albums" */ '../components/Authorized/Albums/allAlbums.vue');
const AlbumsUpload = () =>
  import(/* webpackChunkName: "user-albums" */ '../components/Authorized/Albums/AlbumsUpload.vue');
const PurchasedList = () =>
  import(/* webpackChunkName: "user-albums" */ '../components/Authorized/Albums/PurchasedList.vue');

// Finance
const AddCredits = () =>
  import(/* webpackChunkName: "user-credits" */ '../components/Authorized/credits/AddCredits.vue');
// const Verify = () =>
//   import(/* webpackChunkName: "user-credits" */ '../components/Shared/modals/VerificationModal.vue');
const Payments = () =>
  import(/* webpackChunkName: "user-credits" */ '../components/Authorized/payments/Payments.vue');
const Orders = () =>
  import(/* webpackChunkName: "user-credits" */ '../components/Authorized/Orders.vue');

// Gifts
const Gifts = () =>
  import(/* webpackChunkName: "user-gifts" */ '../components/Authorized/Gifts.vue');
const MyGifts = () =>
  import(/* webpackChunkName: "user-gifts" */ '../components/Authorized/MyGifts.vue');

// Mail
const Mail = () =>
  import(/* webpackChunkName: "user-mailing" */ '../components/Authorized/Mail.vue');
const Inbox = () =>
  import(/* webpackChunkName: "user-mailing" */ '../components/Authorized/Mail/MailBox.vue');
const MailSingle = () =>
  import(/* webpackChunkName: "user-mailing" */ '../components/Authorized/Mail/MailSingle.vue');

// import Invitations from '../components/Authorized/Mail/Invitations.vue'

// Edit profile
const EditProfile = () =>
  import(
    /* webpackChunkName: "app-edit-profile" */ '../components/Shared/Profile/EditProfile/EditProfile.vue'
  );
const MainEdit = () =>
  import(
    /* webpackChunkName: "app-edit-profile" */ '../components/Shared/Profile/EditProfile/MainEdit.vue'
  );
const AboutEdit = () =>
  import(
    /* webpackChunkName: "app-edit-profile" */ '../components/Shared/Profile/EditProfile/AboutEdit.vue'
  );
const AppearanceEdit = () =>
  import(
    /* webpackChunkName: "app-edit-profile" */ '../components/Shared/Profile/EditProfile/AppearanceEdit.vue'
  );
const PrefersEdit = () =>
  import(
    /* webpackChunkName: "app-edit-profile" */ '../components/Shared/Profile/EditProfile/Prefers.vue'
  );

// static pages
const AboutUs = () =>
  import(/* webpackChunkName: "app-static" */ '../components/Shared/static/AboutUs.vue');
const FAQ = () =>
  import(/* webpackChunkName: "app-static" */ '../components/Shared/static/FAQ.vue');
const Feedback = () =>
  import(/* webpackChunkName: "app-static" */ '../components/Shared/static/Feedback.vue');

const TermsOfUse = () =>
  import(/* webpackChunkName: "app-policies" */ '../components/Shared/static/TermsOfUse.vue');
const TermsOfUseWU = () =>
  import(/* webpackChunkName: "app-policies" */ '../components/Shared/static/TermsOfUseWoman.vue');
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "app-policies" */ '../components/Shared/static/PrivacyPolicy.vue');
const PrivacyPolicyWU = () =>
  import(
    /* webpackChunkName: "app-policies" */ '../components/Shared/static/PrivacyPolicyWoman.vue'
  );
const PolicyCookie = () =>
  import(/* webpackChunkName: "app-policies" */ '../components/Shared/static/PolicyCookie');
const RefundPolicy = () =>
  import(/* webpackChunkName: "app-policies" */ '../components/Shared/static/RefundPolicy.vue');
const AntiScam = () =>
  import(/* webpackChunkName: "app-policies" */ '../components/Shared/static/AntiScam.vue');

// Content
const Search = () =>
  import(/* webpackChunkName: "user-content" */ '../components/Authorized/Search.vue');
const News = () =>
  import(/* webpackChunkName: "user-content" */ '../components/Authorized/News.vue');
const Winks = () =>
  import(/* webpackChunkName: "user-content" */ '../components/Authorized/Winks.vue');
const Guests = () =>
  import(
    /* webpackChunkName: "user-content" */
    '../components/Authorized/Guests.vue'
  );
const Bookmarks = () =>
  import(
    /* webpackChunkName: "user-content" */
    '../components/Authorized/Bookmarks.vue'
  );
const Favorites = () =>
  import(
    /* webpackChunkName: "user-content" */
    '../components/Authorized/Favorites.vue'
  );
const Notifications = () =>
  import(/* webpackChunkName: "user-content" */ '../components/Authorized/Notifications.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/about-us',
    component: AboutUs,
    name: 'about-us',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/faq',
    component: FAQ,
    name: 'faq',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/feedback',
    component: Feedback,
    name: 'feedback',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/terms',
    component: TermsOfUse,
    name: 'terms',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/terms-wu',
    component: TermsOfUseWU,
    name: 'terms-wu',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/privacy',
    component: PrivacyPolicy,
    name: 'privacy',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/cookie-policy',
    component: PolicyCookie,
    name: 'cookie',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/privacy-wu',
    component: PrivacyPolicyWU,
    name: 'privacy-wu',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/antiscam',
    component: AntiScam,
    name: 'antiscam',
    meta: {
      hideHumburger: true,
    },
  },
  {
    path: '/refund',
    component: RefundPolicy,
    name: 'refund',
    meta: {
      hideHumburger: true,
    },
  },
  { path: '/logout', component: Logout, name: 'logout' },
  {
    path: '',
    component: Guest,
    props: { isGuest: true },
    children: [
      { path: '', component: RbIndex, name: 'index' },
      {
        path: '/reset-password',
        beforeEnter(to, from, next) {
          if (!store.getters.isAuthenticated) {
            next();
          } else {
            next('/');
          }
          console.log(store.getters);
        },
        component: ForgetPass,
        name: 'passwordReset',
      },
      /*{
        path: '/approve-woman',
        beforeEnter(to, from, next) {
          if (!store.getters.isAuthenticated) {
            next();
          } else {
            next('/');
          }
        },
        component: EmailApprove,
        name: 'email-approve',
      },*/
    ],
  },
  {
    path: '',
    component: Authorized,
    children: [
      {
        path: '/search',
        component: Search,
        name: 'search-user',
        props: true,
      },
      {
        path: '/settings',
        component: Settings,
        children: [
          { path: '', component: settingsGeneral, name: 'settings' },
          {
            path: 'notifications',
            component: settingsNotification,
            name: 'settings-notifications',
          },
        ],
      },
      {
        path: '/albums',
        component: Albums,
        children: [
          { path: '', component: allAlbums, name: 'albums' },
          { path: 'purchased', component: PurchasedList, name: 'album-purchased' },
          { path: 'upload/:albumType', component: AlbumsUpload, name: 'albums-upload' },
          { path: ':albumType', component: Album, name: 'album' },
          {
            path: ':albumType/:id',
            component: Album,
            name: 'album-user',
            props: (route) => ({
              targetID: route.params.id,
            }),
          },
        ],
      },
      {
        path: '/edit',
        component: EditProfile,
        children: [
          { path: '', component: MainEdit, name: 'edit' },
          { path: 'about', component: AboutEdit, name: 'edit-about' },
          { path: 'appearance', component: AppearanceEdit, name: 'edit-appearance' },
          { path: 'prefers', component: PrefersEdit, name: 'edit-prefers' },
        ],
      },
      { path: '/winks', component: Winks, name: 'winks' },
      { path: '/guests', component: Guests, name: 'guests' },
      { path: '/bookmarks', component: Bookmarks, name: 'bookmarks' },
      { path: '/favorites', component: Favorites, name: 'favorites' },
      { path: '/credits', component: AddCredits, name: 'add-credits' },
      {
        path: '/orders',
        component: Orders,
        name: 'orders',
      },
      // { path: '/verification', component: Verify, name: 'verify' },
      { path: '/payments', component: Payments, name: 'payments' },
      { path: '/gifts', component: Gifts, name: 'gifts' },
      { path: '/my-gifts', component: MyGifts, name: 'my-gifts' },
      { path: '/notifications', component: Notifications, name: 'notifications' },
      { path: '/feed', component: News, name: 'feed' },
      {
        path: '/mail',
        component: Mail,
        children: [
          {
            path: '',
            component: Inbox,
            name: 'mailbox',
            props: (route) => ({
              routeView: route.query.view,
              routeIn: route.query.in,
            }),
          },
          // { path: 'invitations', component: Invitations, name: 'mail-invitations' },
          {
            path: 'dialog/:letter',
            component: MailSingle,
            name: 'dialog',
            props: { isDialog: true },
          },
          { path: ':letter', component: MailSingle, name: 'mail' },
        ],
      },
    ],
  },
  {
    path: '/:id',
    component: Profile,
    name: 'profile',
    props: (route) => ({
      token: route.query.token,
      referral: route.query.ref,
    }),
  },
  // {
  //   path: '/verification',
  //   component: Profile,
  //   name: 'profile',
  //   props: (route) => ({
  //     token: route.query.token,
  //     referral: route.query.ref,
  //   }),
  // },
  { path: '/not-found', component: NotFound, name: '404' },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;

<template>
  <div class="header-user--control">
    <el-button
      v-if="sex.isMan"
      :class="{ 'btn_animated b': questionnaireStatus }"
      class="currency-wrap btn"
      @click.native="handlerCreditsClick"
    >
      <template v-if="questionnaireStatus">
        <span class="rbi-money" />
        <span class="amount">Free!</span>
      </template>
      <template v-else>
        <span class="rbi-money" />
        <span :class="{ warning: !balance }" class="amount">{{ balance }}</span>
      </template>
      <div v-if="questionnaireStatus" class="gradient" />
    </el-button>

    <button class="sound" @click="$router.push('/settings/notifications')">
      <span class="rbi-volume" style="color: black" />
    </button>

    <ul v-click-away="closeDropdown" class="user-dropdown">
      <li class="dropdown">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          @click="toggleDropdown"
        >
          <span class="name">{{ user.name }}</span>
          <span class="dropdown-avatar">
            <img
              :alt="user.name"
              :src="user.avatar ? setPhotoSource(user.avatar.img_small) : ''"
              class="min-avatar"
            />
          </span>
          <span :class="{ open: dropdownStatus }" class="caret rbi-down-arrow" />
        </button>
        <transition name="slide-fade">
          <ul v-show="dropdownStatus" class="dropdown-menu">
            <li>
              <router-link :to="{ name: 'profile', params: { id: user.user_id || 0 } }">
                {{ $t('shared.menu.myPage') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'settings' }">
                {{ $t('shared.menu.settings') }}
              </router-link>
            </li>
            <li v-if="sex.isMan">
              <router-link :to="{ name: 'payments' }">
                {{ $t('actionPages.titles.payments') }}
              </router-link>
            </li>
            <li class="divider" />
            <li>
              <router-link to="/logout">
                {{ $t('shared.menu.logout') }}
              </router-link>
            </li>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { PHOTO_SRC } from '../../../mixins/utils';
import ElButton from '../../Button';

export default {
  name: 'HeaderUserControl',
  components: {
    ElButton,
  },

  mixins: [PHOTO_SRC],
  data() {
    return {
      dropdownStatus: false,
      code: ''
    };
  },
  computed: {
    questionnaireStatus() {
      return this.$store.getters.questionnaireStatus;
    },
    questionsForQuestionnaire() {
      return this.$store.getters.questionsForQuestionnaire;
    },
    user() {
      return this.$store.getters.user;
    },
    balance() {
      return this.$store.getters.balance;
    },
    sex() {
      return this.$store.getters.sex;
    },
    settings() {
      return this.$store.getters.settings;
    },
    sound() {
      return this.settings.sound;
    },
    /**
     * получить ссылку для логотипа
     *
     * @return {object}
     */
    link() {
      if (this.user.user_id) {
        return {
          name: 'profile',
          params: {
            id: this.user.user_id,
          },
        };
      }
      return { name: 'index' };
    },
  },
  // watch: {
  //   questionnaireStatus(newVal) {
  //     if (newVal) {
  //       setTimeout(() => {
  //         this.$store.commit('updateVerificationModal', {
  //           open: true,
  //         });
          // this.$store.commit('updateQuestionnaireModal', {
          //   open: true,
          // });
  //       }, 3000);
  //     }
  //   },
  // },
  mounted() {
      // console.log('verificationHeader');
    // if (this.questionnaireStatus) {
        this.$http
          // .post('https://api.redbini.fun/v1/user-additional-info/get', {
              .post('v1/user-additional-info/get', {
            access_token: window.localStorage['access-token'],
          })
          .then(
            (response) => {
              // const verification = response.data.result.verification_required
              // console.log('verification_required', response.data.result.verification_required);
              // console.log('response', response);
              // console.log('this.questionnaireStatus', this.questionnaireStatus);
              if ( response.data.result.verification_required == 1 ) {
                setTimeout(() => {
                  this.$store.commit('updateVerificationModal', {
                    open: true,
                  });
                }, 3000);
              } else {
                setTimeout(() => {
                if (this.questionnaireStatus) {
                    this.$store.commit('updateQuestionnaireModal', {
                      open: true,
                    });
                }
                }, 4000);
              }
            })
        // this.$store.commit('updateQuestionnaireModal', {
        //   open: true,
        // });

    // }
  },
  methods: {
    examVerification() {
      this.validator
        .validateAll({
          fPassword: this.newPassword,
          lPassword: this.repeatPassword,
        })
        .then((success) => {
          if (success) {
            this.$http
              .post('v1/user-settings/change-password', {
                access_token: window.localStorage['access-token'],
                new_password: this.newPassword,
                new_password_repeat: this.repeatPassword,
              })
              .then(
                (r) => {
                  if (r.body.status) {
                    this.$store.commit('addAlert', {
                      type: 'success',
                      text: this.$t('alerts.settingsSuccess'),
                    });
                  } else {
                    this.$store.commit('addAlert', {
                      type: 'error',
                      text: this.$t('alerts.someErr'),
                    });
                  }
                },
                () => {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                  });
                }
              );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handlerCreditsClick() {
      console.log(this.questionnaireStatus)
      if (this.questionnaireStatus) {
        this.$store.commit('updateQuestionnaireModal', {
          open: true,
        });
      } else {
        this.$router.push({ name: 'add-credits' });
      }
    },
    toggleDropdown() {
      this.dropdownStatus = !this.dropdownStatus;
    },
    closeDropdown() {
      this.dropdownStatus = false;
    },
    toggleSound() {
      this.$store.dispatch('setSettings', {
        sound: this.sound === 'off' ? 'custom' : 'off',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';

.header-user--control {
  display: flex;
  align-items: center;

  .currency-wrap {
    color: $black;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.63;
    padding: 12px 20px;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    text-decoration: none;

    span {
      &:first-child {
        color: $black;
        font-size: 20px;
        margin-right: 15px;
      }

      &.warning {
        color: $red;
      }
    }
  }

  .sound {
    margin: 0 55px;
    font-size: 20px;
    background-color: transparent;
    border: none;
    display: flex;
    cursor: pointer;
    transition: 0.3s;
    outline: none;

    &:hover {
      color: $red;
    }
  }

  .user-dropdown {
    position: relative;
    margin-bottom: 0;
    list-style: none;
    padding: 0;

    .dropdown-toggle {
      display: flex;
      align-items: center;
      color: $black;
      text-decoration: none;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;

      &:hover {
        .caret {
          color: $red;
        }
      }

      .dropdown-avatar {
        width: 53px;
        height: 53px;
        display: inline-block;
        margin: 0 20px;
        border: 1px solid transparent;
        border-right: 1px solid $black;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        padding: 3px;
        transform: rotate(45deg);
        overflow: hidden;

        img {
          width: 100%;
          transform: rotate(-45deg);
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
        }
      }

      .caret {
        transform-origin: 50% 50%;
        transition: 0.3s;

        &.open {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      width: 100%;
      right: 0;
      top: 100%;
      list-style: none;
      background: $white;
      box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.3);
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      border-radius: 0 !important;
      padding: 3px 0;
      z-index: 10;
      margin-top: 5px;

      &:before {
        right: 64px;
      }

      li {
        a {
          color: $black;
          display: block;
          padding: 5px 20px;
          transition: 0.3s;

          &:hover {
            color: $red;
          }
        }

        &.divider {
          border-bottom: 1px solid $light-grey;
        }
      }
    }
  }
}
</style>

<template>
  <div class="header_auth">
    <div v-click-away="closeNavForms" class="header_auth-wrap">
      <rb-button style="color: black" :class="'login_button'" @click.native="toggleSignInForm">
        {{ $t('guest.menu.btns.signIn') }}
      </rb-button>
      <div class="sign-in-wrap" :class="{ open: responsiveOpenForm }">
        <form v-if="signInForm || width > 991" class="header_auth_form" @submit.prevent="login()">
          <div class="form-group">
            <input
              v-model="forms.login.email"
              type="email"
              name="indNlog"
              class="form-control"
              :class="{ 'is-danger': validErrLog.has('indNlog') }"
              :placeholder="$t('shared.placeholders.email')"
              @input="forms.login.error = ''"
            />
            <transition name="slide-fade">
              <div v-show="validErrLog.has('indNlog') || forms.login.error" class="error">
                <span class="help is-danger">{{
                  validErrLog.first('indNlog') || forms.login.error
                }}</span>
              </div>
            </transition>
          </div>
          <div class="form-group">
            <input
              v-model="forms.login.password"
              type="password"
              name="indNPswd"
              class="form-control"
              :class="{ 'is-danger': validErrLog.has('indNPswd') }"
              :placeholder="$t('shared.placeholders.password')"
              @input="forms.login.error = ''"
            />
            <span
              class="forgot-password"
              :title="$t('guest.menu.titles.forgotPass')"
              @click="toggleForgotPassForm"
              >?</span
            >
            <transition name="slide-fade">
              <div v-show="validErrLog.first('indNPswd')" class="error">
                <span class="help is-danger">{{ validErrLog.first('indNPswd') }}</span>
              </div>
            </transition>
          </div>
          <div class="form_btn">
            <span class="toggle-forgot-pass" @click="switchForm">{{
              $t('guest.menu.btns.forgotPass')
            }}</span>
            <rb-button class="btn-outline" :class="{ bdn: entering }">
              <span v-if="entering" :class="{ 'wait-spin': entering }" />
              <span :class="{ wait: entering }">{{ $t('guest.menu.btns.signIn') }}</span>
            </rb-button>
          </div>
        </form>
        <transition name="slide-fade">
          <div v-if="forgotPassForm" class="forgot-pass">
            <form v-if="!forms.reset.success" class="forgot_form" @submit.prevent="resetPassword">
              <div
                class="form-group validation-container"
                :class="{ 'has-error': validErrRes.first('inResEmail') || forms.reset.serverError }"
              >
                <input
                  v-model="resEmail"
                  type="email"
                  name="inResEmail"
                  class="form-control"
                  :placeholder="$t('shared.placeholders.email')"
                  @input="forms.reset.serverError = ''"
                />
                <transition name="slide-fade">
                  <div
                    v-show="validErrRes.has('inResEmail') || forms.reset.serverError"
                    class="error"
                  >
                    <span class="help is-danger">
                      {{ validErrRes.first('inResEmail') || forms.reset.serverError }}
                    </span>
                  </div>
                </transition>
              </div>
              <rb-button
                class="btn-outline"
                :disabled="errors.has('inResEmail')"
                :class="{ bdn: entering }"
              >
                <span v-if="entering" :class="{ 'wait-spin': entering }" />
                <span :class="{ wait: entering }">{{ $t('guest.menu.btns.sendForgotPass') }}</span>
              </rb-button>
            </form>
            <template v-else>
              <div class="text-center text-success">
                <success />
                <p>{{ forms.reset.success }}</p>
                <rb-button class="btn" type="reset" @click.native="toggleForgotPassForm">
                  {{ $t('guest.modals.btnOK') }}
                </rb-button>
              </div>
            </template>
          </div>
        </transition>
      </div>
    </div>
    <div>
      <rb-button :class="'btn-black reg'" @click.native="openRegistrationModal">
        {{ $t('guest.menu.btns.join') }}
      </rb-button>
    </div>
  </div>
</template>

<script>
import { Validator } from 'vee-validate';
import { LOGIN, RESET_PASSWORD, REGISTRATION_MODAL } from '../../mixins/formsActions';

import RbButton from '../Button';
import Success from '../Shared/parts/success';

export default {
  name: 'AuthForm',
  resetValidator: null,
  loginValidator: null,
  components: {
    RbButton,
    Success,
  },
  mixins: [RESET_PASSWORD, LOGIN, REGISTRATION_MODAL],
  props: {
    isGuest: Boolean,
  },
  data() {
    return {
      entering: false,
      forgotPassForm: false,
      signInForm: false,
      responsiveOpenForm: false,
      showMenu: false,
      validErrRes: [],
      validErrLog: [],
      forms: {
        login: {
          email: '',
          password: '',
          error: '',
        },
        reset: {
          email: '',
          serverError: '',
          success: '',
        },
      },
    };
  },
  computed: {
    logEmail() {
      return this.forms.login.email;
    },
    logPswd() {
      return this.forms.login.password;
    },
    resEmail: {
      get() {
        return this.forms.reset.email;
      },
      set(v) {
        this.forms.reset.email = v;
      },
    },
    width() {
      return this.$store.getters.deviceWidth;
    },
  },
  watch: {
    /**
     * При изменении ширины браузера закрыть меню
     */
    width() {
      this.closeMenu();
    },

    /**
     * Валидация email при восстановлении пароля
     *
     * @param val {string}
     */
    resEmail(val) {
      this.resetValidator.validate('inResEmail', val).catch(() => {});
    },

    /**
     * Валидация email при логине
     *
     * @param val {string}
     */
    logEmail(val) {
      this.loginValidator.validate('indNlog', val).catch(() => {});
    },

    /**
     * Валидация пароля при логине
     *
     * @param val {string}
     */
    logPswd(val) {
      this.loginValidator.validate('indNPswd', val).catch(() => {});
    },
  },
  created() {
    this.initLoginValidation();
    this.initResetValidation();
  },
  mounted() {
    this.entering = false;
  },
  methods: {
    /**
     * Инициализировать валидатор для логин формы
     */
    initLoginValidation() {
      this.loginValidator = new Validator();
      this.loginValidator.attach('indNlog', 'required|email', {
        prettyName: this.$t('shared.placeholders.email'),
      });
      this.loginValidator.attach('indNPswd', 'required', {
        prettyName: this.$t('shared.placeholders.password'),
      });
      this.$set(this, 'validErrLog', this.loginValidator.errorBag); // update the data.
    },

    /**
     * Инициализировать валидатор для формы восстановления пароля
     */
    initResetValidation() {
      this.resetValidator = new Validator();
      this.resetValidator.attach('inResEmail', 'required|email', {
        prettyName: this.$t('shared.placeholders.email'),
      });
      this.$set(this, 'validErrRes', this.resetValidator.errorBag); // update the data.
    },

    /**
     * Очистить данные формы восстановления пароля
     */
    clearResetData() {
      this.forms.reset.email = '';
      this.forms.reset.serverError = '';
      this.validErrRes.clear();
      setTimeout(() => {
        this.forms.reset.success = '';
      }, 100); // prevent second request after closing modal with success message
    },

    /**
     * Переключить отображение формы восстановления пароля
     */
    toggleForgotPassForm() {
      this.forgotPassForm = !this.forgotPassForm;
      this.clearResetData();
    },

    /**
     * Переключить отображение формы логина
     */
    toggleSignInForm() {
      this.responsiveOpenForm = !this.responsiveOpenForm;
      if (this.responsiveOpenForm) {
        this.signInForm = true;
        this.forgotPassForm = false;
      } else {
        this.signInForm = false;
        this.forgotPassForm = false;
      }
    },

    /**
     * Инициализировать валидатор для логин формы и восстановления пароля
     */
    switchForm() {
      this.forgotPassForm = !this.forgotPassForm;
      this.signInForm = !this.signInForm;
    },

    /**
     * Закрыть форму сстановления пароля (респонзив)
     */
    closeNavForms() {
      this.responsiveOpenForm = false;
      this.forgotPassForm = false;
      this.clearResetData();
    },

    /**
     * Спрятать/показать отображение меню
     */
    toggleMenu() {
      this.showMenu = !this.showMenu;
      document.body.classList.toggle('ovh');
    },

    /**
     * Закрыть меню
     */
    closeMenu() {
      this.showMenu ? (this.showMenu = false) : '';
      document.body.classList.remove('ovh');
    },

    /**
     * Логин на сайт
     *
     * @param scope {string} область видимости для плагина валидации
     */
    login() {
      this.entering = true;
      this.loginValidator
        .validateAll({
          indNlog: this.logEmail,
          indNPswd: this.logPswd,
        })
        .then((success) => {
          if (success) {
            this.mixinLogin({
              email: this.forms.login.email,
              password: this.forms.login.password,
            })
              .then(() => {
                this.$gtag.event('login', {
                  method: 'Login and password',
                  email: this.logEmail,
                }); // Analytic
              })
              .catch((error) => {
                this.forms.login.error = error;
                this.entering = false;
              });
          }
        })
        .catch(() => {
          this.entering = false;
        });
    },

    /**
     * Восстановить пароль
     *
     * @param scope {string} область видимости для плагина валидации
     */
    resetPassword() {
      this.resetValidator
        .validateAll({
          inResEmail: this.resEmail,
        })
        .then((success) => {
          if (success) {
            if (!this.forms.reset.success) {
              this.mixinResetPasswordForm({
                email: this.forms.reset.email,
              })
                .then((response) => {
                  this.forms.reset.success = response;
                })
                .catch((error) => {
                  this.forms.reset.serverError = error;
                });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });

      /* this.$validator.validateAll( scope ).then( success => {
                 if ( success ) {

                 }
                 } ); */
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/vars';

.header_auth {
  display: flex;
  align-items: center;

  &_form {
    display: flex;
    align-items: center;

    .form-group {
      display: inline-block;
      margin-right: 2.5rem;

      .form-control {
        width: 250px;
      }

      &:last-child {
        margin-right: 0;
      }

      input[type='password'] {
        padding-right: 30px;
      }

      .is-danger {
        border-bottom-color: $red;
        white-space: nowrap;
      }

      .forgot-password {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
        font-weight: 700;
        border: 1px solid $black;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        opacity: 0.3;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:active {
          color: $red;
          opacity: 1;
          border-color: $red;
        }
      }
    }
  }

  .reg {
    margin-left: 1.875rem;
  }
}

.toggle-forgot-pass {
  display: none;
  cursor: pointer;
  font-size: 14px;
  color: $grey;
  transition: 0.3s;
  &:hover {
    color: $red;
  }
}

.forgot-pass {
  position: absolute;
  box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.3);
  background: #ffffff;
  padding: 20px;
  z-index: 15;
  right: 0;
  top: 110%;
  width: 70%;

  .text-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:before {
    position: absolute;
    display: inline-block;
    content: '';
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #fff;
    right: 33%;
  }
}

.forgot_form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;

  .form-group {
    flex: 1 0 65%;
    margin-right: 5%;

    .form-control {
      width: 100%;
    }
  }

  .btn {
    flex: 1 0 15%;
  }

  .error {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: -20px;
  }
}

.wait {
  opacity: 0;
}

.login_button {
  display: none !important;
}

.wait-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 20px;
  height: 20px;
  border: 2px solid $black;
  border-left-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}

.bdn {
  border-color: transparent !important;

  &:after {
    opacity: 0;
  }
}

.header_auth-wrap {
  position: relative;
}

.sign-in-wrap {
  position: relative;
}
.error {
  position: absolute;
  top: 100%;
  left: 0;
}

@media screen and (max-width: 1720px) {
  .header {
    &_auth {
      &_form {
        .form-group {
          margin-right: 20px;

          .form-control {
            width: 190px;
          }
        }
      }

      .reg {
        margin-left: 20px;
      }

      .btn {
        padding: 13px 15px;
      }
    }
  }

  .forgot-pass {
    padding: 15px;
    z-index: 15;
    &:before {
      right: 31.2%;
    }
  }
}

@media screen and (max-width: 1470px) {
  .header {
    &_auth {
      &_form {
        .form-group {
          margin-right: 15px;
          font-size: 14px;
          .error {
            padding: 5px 0;
          }
          .form-control {
            width: 180px;
            padding: 10px;
          }
        }
      }

      .reg {
        margin-left: 15px;
      }
    }
  }

  .forgot-pass {
    padding: 15px;
    z-index: 15;
    &:before {
      right: 32%;
    }
  }
}

@media screen and (max-width: 1320px) {
  .header {
    &_auth {
      &_form {
        .form-group {
          margin-right: 15px;

          .form-control {
            width: 180px;
            padding: 10px;
          }
        }
      }

      .reg {
        margin-left: 15px;
      }
    }

    .btn {
      padding: 10px 15px;
    }
  }
  .forgot-pass {
    padding: 10px;
  }
}

@media screen and (max-width: 1260px) {
  .header {
    &_auth {
      &_form {
        .form-group {
          .error {
            padding: 0;
          }
          .form-control {
            font-size: 14px;
            width: 175px;
            line-height: 21px;
          }
        }
      }
    }

    .btn {
      padding: 10px 10px;
      font-size: 14px;
    }
  }
  .forgot-pass {
    padding: 15px;
    z-index: 15;
    &:before {
      right: 30%;
    }
  }
  .forgot-pass {
    padding: 10px;
  }
}

@media screen and (max-width: 991px) {
  .sign-in-wrap {
    position: absolute;
    background: $white;
    padding: 15px;
    right: calc(-100% - 32px);
    top: 100%;
    margin-top: 10px;
    z-index: 15;
    width: 290px;
    box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.3);
    visibility: hidden;
    transform: translateY(-10px);
    opacity: 0;
    transition: 0.3s;

    &:before {
      position: absolute;
      display: inline-block;
      content: '';
      top: -7px;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 7px solid #fff;
      left: 50%;
      transform: translateX(-50%);
    }

    &.open {
      display: flex;
      visibility: visible;
      transform: translateY(0);
      opacity: 1;

      .error {
        position: relative;
        top: auto;
        left: auto;
        margin-bottom: -30px;
        padding: 5px 0;
      }
    }

    .toggle-forgot-pass {
      display: inline-block;
    }

    .header_auth_form {
      flex-direction: column;
      width: 100%;

      .form-group {
        margin: 0 0 20px;
        width: 100%;

        .form-control {
          width: 100%;
        }

        .forgot-password {
          display: none;
        }
      }

      .form_btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .btn {
      }
    }
  }
  .login_button {
    display: inline-block !important;
  }

  .forgot-pass {
    position: static;
    box-shadow: none;
    background: #ffffff;
    padding: 0;
    width: 100%;

    .forgot_form {
      width: 100%;
    }

    .text-success {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &:before {
      position: absolute;
      display: inline-block;
      content: '';
      top: -7px;
      display: none;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 7px solid #fff;
      right: 37%;
    }
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>

<template>
  <button class="btn">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    classList: String,
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/vars";

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #000;
    background: none;
    white-space: nowrap;
    padding: 13px 30px;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 3px;
    margin-right: 3px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      bottom: -4px;
      right: -4px;
      transition: .3s;
    }

    &:hover {
      &:after {
        width: 10px;
        height: 10px;
        left: auto;
        right: -4px;
        top: auto;
        bottom: -4px;
      }
    }
    &:disabled{
      border-color: lighten($black, 50% );
      &:after{
        border-color: lighten($black, 50% );
      }
      &:hover {
        &:after {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .disabled {
    &:hover {
      &:after {
        width: 100%;
        height: 100%;
      }
    }
  }

  .btn-outline {
    background: transparent;

    &-red {
      border-color: $red;
      color: $red;

      &:after {
        border-bottom-color: $red;
        border-right-color: $red;
      }
    }
  }

  .btn-black {
    background: #000;
    color: #fff;
    &:disabled{
      background: lighten($black, 50% );
      border-color: lighten($black, 50% );
      cursor: default;
      &:after{
        border-color: lighten($black, 50% );
      }
      &:hover{
        &:after{
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .btn-red {
    background: $red;
    border-color: $red;
    color: #fff;

    &:after {
      border-bottom-color: $red;
      border-right-color: $red;
    }
  }

  .btn-white {
    border-color: $white;
    color: #fff;

    &:after {
      border-bottom: 1px solid $white;
      border-right: 1px solid $white;
    }
  }
</style>

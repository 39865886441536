/**
 * Created by Alex Shvets on 17.02.2017.
 */

import watermark from 'watermarkjs';
import CONFIG from '../config/config';

var getScrollBarWidth = function () {
  // eslint-disable-next-line no-undef
  var outer = $('<div>')
    .css({ visibility: 'hidden', width: 100, overflow: 'scroll' })
    .appendTo('body');
  // eslint-disable-next-line no-undef
  var widthWithScroll = $('<div>').css({ width: '100%' }).appendTo(outer).outerWidth();
  outer.remove();
  return 100 - widthWithScroll;
};

export const PHOTO_SRC = {
  methods: {
    /**
     * получить урл фотографий.
     *
     * Остаток от доS3времен
     *
     * @param url
     * @return {*}
     */
    setPhotoSource(url) {
      return url;
    },
  },
};

export const AGE = {
  methods: {
    /**
     *  получить возраст пользователя из его даты рождения
     *
     * @param birthday {number} timestamp
     * @return {number}
     */
    getUserAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday * 1000);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
  },
};

export const WEBCAM = {
  methods: {
    /**
     * получить классы для HTML отображения иконки вебкамеры
     *
     * @param val
     * @return {{webcam-icon: boolean, hd: boolean}}
     */
    userWebcam(val) {
      return {
        status: val === 'hd' || val === 'yes',
        hd: val === 'hd',
      };
    },
  },
};

export const NO_DATA = {
  methods: {
    /**
     * Получить либо значение поля, либо заглугшку "не заполнено"
     *
     * @param val {string} значение поля
     * @return {*}
     */
    noData(val) {
      return !val || val === 'no' ? this.$t('profile.noFill') : val;
    },
  },
};

export const MINI_PROFILE = {
  methods: {
    // DO NOT CHANGE PROFILE'S DATA IF IT SHOULD ASK MY PROFILE
    /**
     * Спросить профиль пользователя
     *
     * @param id {number} ид пользователя
     * @return {Promise}
     */
    askMiniProfile(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('v1/profile/quick-search', {
            access_token: window.localStorage['access-token'],
            name_or_id: id,
          })
          .then((response) => {
            response.body.result.length
              ? resolve(response.body.result[0])
              : reject(this.$store.getters.user);
          });
      });
    },
  },
};

export const GALLERY_INDEX = {
  methods: {
    /**
     * открыть галерею с фотографией
     *
     * @param condition {*} условие
     * @param photos {array} фотографии
     * @param index {number} индекс фотографии
     * @param hideWatermark {boolean} прятать ли ватермарку
     */
    takePhotos(condition, photos, index, hideWatermark) {
      if (condition) {
        this.$store.commit('updateGallery', {
          photos,
          index,
          open: true,
          hideWatermark,
        });
      }
    },
  },
};

/**
 * обработка HTML классов на body при открытии / закрытии модалок
 *
 * @param open
 * @constructor
 */

export const TOGGLE_MODAL = function (open, skip) {
  if (open) {
    document.querySelector('html').style.overflow = 'hidden';
    // eslint-disable-next-line no-undef
    $('body').css({
      overflow: 'hidden',
      // eslint-disable-next-line no-undef
      'padding-right': getScrollBarWidth(),
    });
  } else if (open === false && !skip) {
    // eslint-disable-next-line no-undef
    $('body').css({
      overflow: 'auto',
      'padding-right': '0',
    });
    // eslint-disable-next-line no-undef
    $('html').css({
      overflow: '',
      'padding-right': '',
    });
  }
};

export const NOTIFICATION_SOUND = {
  sound: null,
  computed: {
    config() {
      return this.$store.getters.config;
    },
  },
  methods: {
    /**
     * Проиграть выбранный звук
     */
    playNotification() {
      const playPromise = this.sound.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            console.log('ok: ', _);
          })
          .catch((error) => {
            console.log('error: ', error);
          });
      }
    },
    /**
     * Выбрать звук для проигрывания. Запускать в хуке created()
     *
     * @param property {string} свойство с ссылкой на звук
     */
    selectSound(property) {
      // must be run in created() hook

      return this.config.sounds[property];
      // let audio = new Audio( this.config.sounds[ property ] )
      /*      const playPromise = audio.play();

            if (playPromise !== undefined) {
              playPromise.then(() => {
              })
                .catch(() => {
                });
            } */
    },
  },
};

export const MAIN_RESIZE_DETECTOR = {
  methods: {
    /**
     * установить ширину дисплея
     */
    setDeviceWidth() {
      const w = window.innerWidth;
      this.$store.commit('setDeviceWidth', w);
    },
  },
  mounted() {
    this.setDeviceWidth();
  },
};

export const WATERMARK = {
  data() {
    return {
      wmOptions: {
        init(img) {
          img.crossOrigin = 'anonymous';
        },
        modX: 0,
        modY: 0,
      },
    };
  },
  computed: {
    config() {
      return this.$store.getters.config;
    },
  },
  methods: {
    /**
     * получить отступ по оси Х
     *
     * @param parent {object} родительское фото
     * @param wm {object} ватермарка
     * @return {number}
     */
    wmGetX(parent, wm) {
      return parent.width - wm.width - this.wmOptions.modX;
    },
    /**
     * получить отступ по оси Y
     *
     * @param parent {object} родительское фото
     * @param wm {object} ватермарка
     * @return {number}
     */
    wmGetY(parent, wm) {
      return parent.height - wm.height - this.wmOptions.modY;
    },
    /**
     * получить изображение с ватермаркой
     *
     * @param targetObj {object} родительский объект для передачи поссылке
     * @param parentImg {string|Blob} картинка
     * @param wmImg {object} ватермарка
     * @param modifierX {number} модификатор отступа для позиционирования ватермарки
     * @param modifierY {number} модификатор отступа для позиционирования ватермарки
     * @param variable {string} название переменной, в которую птом записать картинку с ватермаркой
     * @param isBlob {boolean} если конвертировать нужно Blob
     * @return {Promise}
     */
    getWatermark({ targetObj, parentImg, wmImg, modifierX, modifierY, variable, isBlob }) {
      this.wmOptions.modX = modifierX;
      this.wmOptions.modY = modifierY;
      const self = this;
      return new Promise((resolve) => {
        if (isBlob) {
          watermark([parentImg, wmImg], self.wmOptions)
            .image(watermark.image.atPos(self.wmGetX, self.wmGetY, CONFIG.watermarkAlpha))
            .then((newImg) => {
              self.$set(targetObj, variable, newImg.src);
              resolve();
            });
        } else {
          watermark([`${parentImg}?${Date.now()}`, wmImg], self.wmOptions) // new Date() for chrome bug
            .image(watermark.image.atPos(self.wmGetX, self.wmGetY, CONFIG.watermarkAlpha))
            .then((newImg) => {
              self.$set(targetObj, variable, newImg.src);
              resolve();
            });
        }
      });
    },
  },
};

export const PHOTOS_UPLOAD = {
  data() {
    return {
      previousRequest: null,
      constants: {
        imageSize: 2097152, // 2Mb
        pseudoMaxHeight: 10000,
        pseudoMaxWidth: 10000,
        imgMaxWidth: 3000,
        imgMaxHeight: 3000,
        imgMinWidth: 500,
        imgMinHeight: 500,
        startQuality: 1,
        qualityStep: 0.1,
        imageMimeTypes: ['image/jpeg', 'image/pjpeg', 'image/png'],
      },
    };
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
  },
  methods: {
    /**
     * Проверка файла на соответствие с требованиями к картинке
     *
     * @param file {object} File
     * @return {Promise}
     */
    checkImage(file) {
      return new Promise((resolve, reject) => {
        let mimeTypeCheck = false;
        this.constants.imageMimeTypes.forEach((type) => {
          if (type === file.type) {
            mimeTypeCheck = true;
          }
        });
        if (!mimeTypeCheck) {
          reject({
            status: false,
            description: this.$t('alerts.incorrectImgExt'),
          });
          return false;
        }
        try {
          const temporaryImage = document.createElement('img');
          const arr = [];
          temporaryImage.src = URL.createObjectURL(file);
          arr.push(temporaryImage);

          temporaryImage.onload = (loadedImage) => {
            if (loadedImage.type === 'error') {
              reject({
                status: false,
                description: this.$t('alerts.corruptedFile'),
              });
              return false;
            }
            const imgTarget = loadedImage.target;
            if (file.size > this.constants.imageSize) {
              const allowfSize = Math.round(this.constants.imageSize / 1024 / 1024);
              reject({
                status: false,
                description: this.$t('alerts.incorrectFileSize', ['', allowfSize]),
              });
              return false;
            }
            if (
              imgTarget.naturalHeight > this.constants.imgMaxHeight ||
              imgTarget.naturalWidth > this.constants.imgMaxWidth
            ) {
              reject({
                status: false,
                description: this.$t('alerts.incorrectImgResMax', [
                  this.constants.imgMaxWidth,
                  this.constants.imgMaxHeight,
                ]),
              });
              return false;
            }
            if (
              imgTarget.naturalHeight < this.constants.imgMinHeight ||
              imgTarget.naturalWidth < this.constants.imgMinWidth
            ) {
              if (this.sex.isMan) {
                resolve({
                  status: true,
                });
                return false;
              }
              reject({
                status: false,
                description: this.$t('alerts.incorrectImgResMin', [
                  this.constants.imgMinWidth,
                  this.constants.imgMinHeight,
                ]),
              });
              return false;
            }
            resolve({
              status: true,
            });
            return false;
          };
          temporaryImage.onerror = () => {
            reject({
              status: false,
              description: this.$t('alerts.corruptedFile'),
            });
            return false;
          };
        } catch (Ex) {
          reject({
            status: false,
            description: this.$t('alerts.corruptedFile'),
          });
          return false;
        }
      });
    },
    /**
     * создать канвас для дальнейшей его обработки
     *
     * @param e {object} event загрузки фотографии
     * @return {Promise}
     */
    makeCanvas(e) {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const img = e.target || e;

        let width = img.naturalWidth;
        let height = img.naturalHeight;
        if (width > height) {
          if (width > this.constants.imgMaxWidth) {
            height *= this.constants.imgMaxWidth / width;
            width = this.constants.imgMaxWidth;
          }
        } else if (height > this.constants.imgMaxHeight) {
          width *= this.constants.imgMaxHeight / height;
          height = this.constants.imgMaxHeight;
        }

        if (!this.sex.isMan) {
          if (width < this.constants.imgMinWidth || height < this.constants.imgMinHeight) {
            reject({
              status: false,
              description: this.$t('alerts.incorrectImgRatio'),
            });
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve({ canvas });
      });
    },
    /**
     * Создать base64 представление и проверять его на размер. В случае провала - рекурсия и ухудшением качества
     *
     * @param canvas {object} карвас
     * @param quality {number} качество 0-1
     * @return {*}
     */
    reduceImage(canvas, quality) {
      const newFile = this.dataURItoBlob(canvas.toDataURL('image/jpeg', quality));
      return newFile.size <= this.constants.imageSize
        ? newFile
        : this.reduceImage(canvas, quality - this.constants.qualityStep);
    },
    /**
     * конвертация base64 в Blob
     *
     * @param dataURI {object} base64
     * @return {*}
     */
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      const byteString = atob(dataURI.split(',')[1]);
      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length);
      const dw = new DataView(ab);
      for (let i = 0; i < byteString.length; i++) {
        dw.setUint8(i, byteString.charCodeAt(i));
      }
      return new Blob([ab], { type: mimeString });
    },
    /**
     * Собрать формдату с блобом
     *
     * @param blob {blob}
     * @param albumType {string}
     * @return {Promise}
     */
    cancelUpload() {
      if (this.previousRequest) {
        this.previousRequest.abort();
      }
      this.previousRequest = null;
      this.fromCamera = false;
      this.refVideo = null;
      this.refCanvas = null;
      this.refImage = null;
      this.globalStream = null;
      this.imgSrc = null;
      this.fromCamera = false;
      this.streaming = false;
      this.width = 1024;
      this.height = 0;
    },
    sendBlob(blob, albumType) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('access_token', window.localStorage['access-token']);
        formData.append('album_type', albumType);
        formData.append('blob', blob);
        formData.append('is_blob', 1);

        this.$http
          .post('v1/photo/upload', formData, {
            before(request) {
              // abort previous request, if exists
              if (this.previousRequest) {
                this.previousRequest.abort();
              }

              // set previous request on Vue instance
              this.previousRequest = request;
            },
          })
          .then(
            (response) => {
              if (response.body.status) {
                resolve(response.body);
              } else {
                reject({
                  status: false,
                  description: this.$t('alerts.failUpload'),
                  type: 'error',
                });
              }
              this.previousRequest = null;
            },
            () => {
              if (!this.previousRequest) {
                reject({
                  status: false,
                  description: this.$t('alerts.failUpload'),
                  type: 'cancel',
                });
              } else {
                reject({
                  status: false,
                  description: this.$t('alerts.failUpload'),
                  type: 'error',
                });
              }
              this.previousRequest = null;
            }
          );
      });
    },
  },
};

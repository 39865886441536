<template>
  <header class="header" :class="{ index: isIndex }" @click="toggleSidebar(false)">
    <div class="rb-container container">
      <div class="row">
        <div class="col d-flex justify-content-between flex-wrap">
          <div class="pull-left">
            <div v-click-away="closeMenu" class="toogle-menu">
              <div
                v-if="!hideHumburger"
                ref="humburger"
                name="Menu"
                class="nav-menu"
                :class="{ open: headerMenu }"
                @click.stop="isAuth ? toggleSidebar(!sidebarStatus) : toggleMenu()"
              >
                <span />
                <span />
                <span />
                <span />
              </div>
              <rb-navbar ref="menu" class="header_nav_responsive" :class="{ open: headerMenu }">
                <li>
                  <router-link :to="{ name: 'about-us' }" :title="$t('shared.menu.titles.about')">
                    {{ $t('shared.menu.about') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'faq' }" :title="$t('shared.menu.titles.faq')">
                    {{ $t('shared.menu.faq') }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'feedback' }"
                    :title="$t('shared.menu.titles.contactUs')"
                  >
                    {{ $t('shared.menu.contactUs') }}
                  </router-link>
                </li>
              </rb-navbar>
            </div>
            <div class="header_logo">
              <router-link :to="link" class="logo">RB</router-link>
            </div>
            <div class="header-wrap-block">
              <rb-navbar v-if="!isAuth" class="header_nav">
                <li>
                  <router-link :to="{ name: 'about-us' }" :title="$t('shared.menu.titles.about')">
                    {{ $t('shared.menu.about') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'faq' }" :title="$t('shared.menu.titles.faq')">
                    {{ $t('shared.menu.faq') }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'feedback' }"
                    :title="$t('shared.menu.titles.contactUs')"
                  >
                    {{ $t('shared.menu.contactUs') }}
                  </router-link>
                </li>
              </rb-navbar>
              <quick-search
                v-else
                :placeholder="$t('shared.placeholders.nameId')"
                @selected="userSelected($event)"
              />
            </div>
          </div>
          <div class="pull-right">
            <sign-in-form v-if="!isAuth" />
            <header-user-control v-else />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import SignInForm from './AuthForms/SignIn';
import RbNavbar from './Navbar';
import QuickSearch from './Shared/parts/QuickSearch';
import HeaderUserControl from './Shared/parts/HeaderUserControl';

export default {
  name: 'HeaderComponent',

  components: {
    SignInForm,
    RbNavbar,
    QuickSearch,
    HeaderUserControl,
  },
  props: {
    isAuth: Boolean,
  },
  data() {
    return {
      headerMenu: false,
    };
  },
  computed: {
    hideHumburger() {
      return this.$route.meta.hideHumburger;
    },
    sidebarStatus() {
      return this.$store.getters.sidebarStatus;
    },
    isIndex() {
      return this.$route.name === 'index';
    },
    user() {
      return this.$store.getters.user;
    },
    balance() {
      return this.$store.getters.balance;
    },
    sex() {
      return this.$store.getters.sex;
    },
    settings() {
      return this.$store.getters.settings;
    },
    sound() {
      return this.settings.sound;
    },
    /**
     * получить ссылку для логотипа
     *
     * @return {object}
     */
    link() {
      if (this.user.user_id) {
        return {
          name: 'profile',
          params: {
            id: this.user.user_id,
          },
        };
      }
      return { name: 'index' };
    },
  },
  watch: {
    isAuth(authorized) {
      if (authorized) {
        this.processPurchaseAnalytic();
      }
    },
  },
  mounted() {
    if (this.isAuth) this.processPurchaseAnalytic();
    // document.addEventListener('click', e => {
    //   let target = e.target;
    //   let humburg = this.$refs.humburger;
    //   let menu = this.$refs.menu.$el;
    //   let its_menu = target === menu || menu.contains(target) ;
    //   let its_hamburger = target === humburg;
    //   let menu_is_active = menu.classList.contains('open');
    //   if (!its_menu && !its_hamburger && menu_is_active || target.tagName === "A") {
    //     this.closeMenu()
    //   }
    // })
  },

  methods: {
    processPurchaseAnalytic() {
      setTimeout(() => {
        const accessToken = window.localStorage.getItem('access-token');

        if (!accessToken) return;

        this.$http
          .post('v1/credit/get-just-purchased-pack', {
            access_token: accessToken,
          })
          .then(
            ({ body }) => {
              if (!body || !body.status || !body.result) return;

              const { credit_bought_id, credit_pack_id, name, promo_pack } = body.result;

              const localStorageAnalyticRecord = window.localStorage.getItem('purchased-analytic');

              const parsedData = localStorageAnalyticRecord
                ? JSON.parse(localStorageAnalyticRecord)
                : null;

              console.log(
                'To be or not to be:',
                parseInt(parsedData.transactionId, 10) === parseInt(credit_bought_id, 10)
              );

              if (
                !parsedData ||
                !(parseInt(parsedData.transactionId, 10) === parseInt(credit_bought_id, 10))
              ) {
                const newAnalyticStorageRecord = {
                  date: Date.now(),
                  transactionId: credit_bought_id,
                };

                const packageData = {
                  name,
                  type: promo_pack === '1' ? 'credit-promo' : 'credit-simple',
                  id: credit_pack_id,
                  transactionId: credit_bought_id,
                };

                this.sendAnalytic(packageData);

                window.localStorage.setItem(
                  'purchased-analytic',
                  JSON.stringify(newAnalyticStorageRecord)
                );
              }
            },
            (e) => {
              console.error(e);
            }
          );
      }, 1000 * 5);
    },
    sendAnalytic({ id, type, transactionId }) {
      if (type === 'credit-promo') {
        this.$gtag.event('promo-credit-pack-purchased', {
          event_category: 'credits',
          method: 'promo-pack',
          user: this.user.user_id,
          transactionId,
          pack_id: id,
        });
      } else if (type === 'credit-simple') {
        this.$gtag.event('simple-credit-pack-purchased', {
          event_category: 'credits',
          method: 'simple-pack',
          user: this.user.user_id,
          transactionId,
          pack_id: id,
        });
      }
    },
    toggleMenu() {
      this.headerMenu = !this.headerMenu;
    },
    closeMenu() {
      this.headerMenu = false;
    },
    toggleSidebar(status) {
      this.$store.dispatch('sidebarHandler', status);
    },
    userSelected(e) {
      this.$router.push({
        name: 'profile',
        params: {
          id: e.user_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/_vars.scss';

.header {
  .header_logo {
    .logo {
      color: transparent;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 165px;
  background: $white;
  position: relative;
  transition: 0.3s;

  &.index {
    margin-bottom: 0 !important;

    &:before,
    &:after {
      display: none;
    }
  }

  /*    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      height: 250px;
      width: 100%;
      background: url("/static/images/rb-page-bg.jpg") no-repeat center center;
      -webkit-background-size: cover;
      background-size: cover;
      z-index: -10;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: calc(100% + 250px);
      left: 0;
      height: 7px;
      width: 50%;
      background: $red;
      z-index: -10;
    }*/

  .pull-right,
  .pull-left {
    display: flex;
    align-items: center;
  }

  &-wrap-block {
    margin-left: 87px;
  }

  &_logo {
    .logo {
      width: 132px;
      height: 32px;
      display: inline-block;
      background: url('/static/images/svg/logo(dark).svg') no-repeat center center;
      -webkit-background-size: contain;
      background-size: contain !important;
    }
  }

  .toogle-menu {
    display: none;
    margin-right: 15px;
    position: relative;
  }

  .nav-menu {
    width: 18px;
    height: 16px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $black;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 7px;
      }

      &:nth-child(4) {
        top: 14px;
      }
    }

    &.open {
      span {
        &:nth-child(1) {
          top: 7px;
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 7px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 1720px) {
  .header {
    height: 140px;

    &_logo {
      .logo {
        width: 112px;
        height: 32px;
        display: inline-block;
        -webkit-background-size: contain;
        background-size: contain;
      }
    }
    &-wrap-block {
      margin-left: 40px;
    }
  }
}

@media screen and (max-width: 1470px) {
  .header {
    height: 120px;

    &-wrap-block {
      margin-left: 30px;
    }

    &_logo {
      .logo {
        width: 100px;
        height: 23px;
        display: inline-block;
        -webkit-background-size: contain;
        background-size: contain;
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .header {
    height: 100px;

    &-wrap-block {
      margin-left: 20px;
    }

    &_logo {
      .logo {
        width: 100px;
        height: 23px;
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .header {
    height: 90px;
    &-wrap-block {
      margin-left: 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .header {
    height: 74px;

    &:before {
      height: 150px;
      width: 100%;
    }

    &:after {
      top: calc(100% + 150px);
      height: 5px;
    }

    &_logo {
      display: flex;
      margin-top: -10px;

      .logo {
        width: 132px;
        height: 32px;
      }
    }

    .toogle-menu {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    &:before {
      height: 120px;
      width: 100%;
    }

    &:after {
      top: calc(100% + 120px);
      height: 5px;
    }

    &_logo {
      display: flex;
      margin-top: -10px;

      .logo {
        width: 40px;
        height: 32px;
        background: url('/static/images/svg/logo_small(dark).svg') no-repeat center center;
      }
    }

    .toogle-menu {
      display: flex;
      align-items: center;
    }
  }
}
</style>

/* eslint-disable no-useless-escape */
/**
 * Created by Work_2 on 20.02.2017.
 */

import uniqBy from 'lodash/uniqBy';

import CONFIG from '../config/config';

/**
 * авторизация пользователя и загрузка нужных данных
 *
 * @param scope {object} область видимости
 * @param response {object}
 * @param keepCaptcha {boolean} нужно ли сохранять данные о логине в localstorage после авторизации
 */

const auth = function (scope, response, keepCaptcha) {
  const rgxp = new RegExp(CONFIG.baseURL, 'g');

  if (CONFIG.production && !window.location.href.match(rgxp)) {
    window.location = `${CONFIG.baseURL}/${response.result.user_id}?token=${response.result.access_token}`;
  } else {
    scope.$store.commit('SET_TOKEN', response.result.access_token);
    window.localStorage.setItem('access-token', response.result.access_token);

    /* if (window.localStorage['access-token']) {
      try {
        Vue.use(
          new VueSocketIO({
            debug: false,
            connection: CONFIG.chatSocketURL,
            vuex: { store, actionPrefix: 'socket_', mutationPrefix: 'socket_' },
          })
        )

        setTimeout(() => {
          Vue.socket.emit(
            'registration',
            {
              user_id: response.body.user_id,
              front_v: store.getters.frontendVersion,
              type: 'site',
            },
            (data) => {
              window.localStorage.setItem('chat-token', data.chatToken)
            }
          )
        }, 1000)
      } catch (e) {
        console.log(e)
      }
    } */

    scope.$store.dispatch('getUserData', { targetId: response.result.user_id });
    console.log(response);
    response.result.user_type === 'man'
      ? scope.$router.push({ name: 'feed' })
      : scope.$router.push({
          name: 'profile',
          params: { id: response.result.user_id },
        });
    // scope.$router.push({name: 'profile', params: {id: response.result.user_id}}); //receive self profile
    // receive self profile
  }

  if (window.localStorage.ref) window.localStorage.removeItem('ref');
  if (!keepCaptcha) window.localStorage.removeItem('login');
};

const writeIP = function (ip) {
  if (!window.localStorage.login) {
    window.localStorage.setItem('login', JSON.stringify([{ time: Date.now(), ip }]));
  } else {
    try {
      let arr = JSON.parse(window.localStorage.login);
      if (arr.length > CONFIG.registration.triesCount - 1) {
        arr = arr.slice(0, CONFIG.registration.triesCount - 1);
      }
      arr.unshift({
        time: Date.now(),
        ip,
      });
      window.localStorage.setItem('login', JSON.stringify(arr));
    } catch (e) {
      window.localStorage.setItem('login', JSON.stringify([{ time: Date.now(), ip }]));
    }
  }
};

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`)
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const calcRef = function () {
  let result = {};
  if (window.localStorage.ref) {
    try {
      const tmp = JSON.parse(window.localStorage.ref);
      if (tmp.id && tmp.ref) {
        tmp.site_referrals_woman_id = tmp.id;
        delete tmp.id;
      }
      if (tmp.ref) {
        tmp.site_referrals_token = tmp.ref;
        delete tmp.ref;
      }
      result = tmp;
    } catch (e) {
      result = {};
    }
  }
  const visitorId = getCookie('PAPVisitorId');
  if (visitorId) {
    result.PAPVisitorId = visitorId;
  }
  return result;
};

export const RESET_PASSWORD = {
  methods: {
    /**
     * Отправка запроса на сервер на восстановление пароля
     *
     * @param email
     * @return {Promise}
     */
    mixinResetPasswordForm({ email }) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('v1/user/request-password-reset', {
            email,
          })
          .then(
            (response) => {
              response.json().then(
                (response) => {
                  response.status ? resolve(response.message) : reject(response.desc);
                },
                (error) => {
                  console.log(error);
                }
              );
            },
            (error) => {
              console.log(error);
            }
          );
      });
    },
  },
};

export const LOGIN = {
  methods: {
    /**
     * Получить IP пользователя
     *
     * @return {Promise}
     */
    getLoginIP() {
      return new Promise((resolve) => {
        this.$http.get('https://api.ipify.org?format=json').then(
          (r) => {
            resolve(r.body.ip || '');
          },
          () => {
            resolve({
              ip: '',
              user_agent: window.navigator.userAgent,
            });
          }
        );
      });
    },
    /**
     * Авторизация в системе и обработка запроса
     *
     * @param email {string}
     * @param password {string}
     * @param ip {string}
     * @return {Promise}
     */
    loginRequest({ email, password, ip }) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('v1/user/login', {
            email,
            password,
            ip,
          })
          .then(
            (response) => {
              response.json().then(
                (response) => {
                  if (response.status) {
                    const cb = () => {
                      resolve();
                      auth(this, response, false);
                    };
                    if (response.result.restore_profile) {
                      this.$store.commit('updateRestoreProfileModal', {
                        open: true,
                        callback: {
                          success: cb,
                          fail: reject,
                        },
                        token: response.result.access_token,
                      });
                    } else {
                      cb();
                    }
                  } else {
                    reject(response.desc);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            },
            (error) => {
              console.log(error);
            }
          );
      });
    },
    /**
     * Начало авторизации на сервисе. Получение ИП клиента и последующая авторизация
     *
     * @param email
     * @param password
     * @return {Promise}
     */
    mixinLogin({ email, password }) {
      return new Promise((resolve, reject) => {
        this.getLoginIP()
          .then((ip) => {
            // ip = {ip: ... }
            this.loginRequest({ email, password, ip })
              .then(() => {
                resolve();
              })
              .catch((e) => {
                reject(e);
              });
          })
          .catch(() => {
            reject();
          });
      });
    },
    /**
     * Начало авторизации через фейсбук
     *
     * @return {Promise}
     */
    FBLoginStatus() {
      return new Promise((reject) => {
        reject();
        // eslint-disable-next-line no-undef
        /*  FB.getLoginStatus((response) => {
          self
            .statusFBChangeCallback(response)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });*/
      });
    },
    /**
     * Получение IP пользователя
     *
     * @return {Promise}
     */
    getIP() {
      return new Promise((resolve) => {
        this.$http.get('https://api.ipify.org?format=json').then(
          (r) => {
            resolve({
              ip: r.body.ip || '',
              user_agent: window.navigator.userAgent,
            });
          },
          () => {
            resolve({
              ip: '',
              user_agent: window.navigator.userAgent,
            });
          }
        );
      });
    },
    /**
     * Отправка данных об авторизации на Facebook на нащ сервер
     * @param response {object}
     * @return {Promise}
     */
    sendFBRequestToServer(response) {
      return new Promise((resolve, reject) => {
        this.getIP().then((result) => {
          if (!window.localStorage['access-token']) {
            const data = {
              user_id: response.authResponse.userID,
              access_token: response.authResponse.accessToken,
              ...result,
              ...calcRef(),
            };
            this.$http.post('v1/user/login-facebook', data).then((r) => {
              if (r.body.status) {
                const cb = () => {
                  resolve();
                  auth(this, r.body, false);
                };
                if (r.body.result.restore_profile) {
                  this.$store.commit('updateRestoreProfileModal', {
                    open: true,
                    callback: {
                      success: cb,
                      fail: reject,
                    },
                    token: r.body.result.access_token,
                  });
                } else {
                  cb();
                }
              } else {
                this.$store.dispatch('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                  info: 'ask facebook login',
                });
                reject();
              }
            });
          } else {
            reject('already signed up');
          }
        });
      });
    },
    /**
     * Запрос статуса авторизации пользователя в Facebook
     *
     * @param response
     * @return {Promise}
     */
    statusFBChangeCallback(response) {
      return new Promise((resolve, reject) => {
        if (response.status === 'connected') {
          this.sendFBRequestToServer(response)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        } else {
          this.loginFacebook(response)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        }
      });
    },

    /**
     * Авторизация пользователя в Facebook
     *
     * @return {Promise}
     */
    loginFacebook() {
      return new Promise((reject) => {
        reject();
        /* // eslint-disable-next-line no-undef
        FB.login(
          (response) => {
            if (response.authResponse) {
              self
                .sendFBRequestToServer(response)
                .then(() => {
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            } else {
              self.$store.dispatch('addAlert', {
                type: 'error',
                text: self.$t('alerts.cancelFBLogin'),
                info: 'ask facebook login',
              });
              reject();
            }
          },
          {
            scope: 'email',
            return_scopes: true,
          }
        );*/
      });
    },
  },
};

export const REGISTRATION = {
  data: () => ({
    minAge: 20,
    maxAge: 90,
  }),
  computed: {
    years() {
      const yearsArr = [];
      const currentYear = new Date().getFullYear();

      if (!this.minAge || !this.maxAge) throw new Error('Missing age bounds data.');

      for (let i = currentYear - this.minAge; i >= currentYear - this.maxAge; i--) {
        yearsArr.push(i);
      }
      return yearsArr;
    },
  },
  methods: {
    /**
     * Начальная обработка запроса на регистрацию в системе
     *
     * @param name {string}
     * @param email {string}
     * @param password {string}
     * @param dob {string}
     * @param sex {number}
     * @return {Promise}
     */
    mixinRegistration({ name, email, password, sex, dob }) {
      return new Promise((resolve, reject) => {
        this.getIP()
          .then((r) => {
            // r = {ip, user_agent}

            this.registrationRequest({
              name,
              sex,
              dob,
              email,
              password,
              info: r,
            })
              .then(() => {
                resolve();
              })
              .catch((e) => {
                reject(e);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * Отправка запроса на регистрацию в системе
     *
     * @param sex {number}
     * @param name {string}
     * @param dob {string}
     * @param email {string}
     * @param password {string}
     * @param info {object}
     * @return {Promise}
     */
    registrationRequest({ sex, name, dob, email, password, info }) {
      return new Promise((resolve, reject) => {
        this.checkRegistrationOnBot()
          .then((r) => {
            if (r.status && r.showCaptcha) {
              // open captcha
              RECAPTCHA_MODAL.methods.openRecaptchaModal(registration, false, this);
            } else {
              // everything is ok, start registration
              registration(true);
            }
          })
          .catch(() => {
            // registration
            registration(true);
          });

        let registration = (keepCaptcha) => {
          const data = {
            sex,
            name,
            dob,
            email,
            password,
            ip: info.ip,
            user_agent: info.user_agent,
            ...calcRef(),
          };
          /* console.log('Request data: ',data) */
          this.$http.post('v1/user/registration', data).then(
            (response) => {
              response.json().then(
                (response) => {
                  if (response.status) {
                    console.log('response.valid_error.email', response);
                    auth(this, response, keepCaptcha);
                    this.$gtag.event('sign_up', {
                      method: 'Site form sign up',
                      event_category: 'engagement',
                      // eslint-disable-next-line no-prototype-builtins
                      is_duplicate: response.hasOwnProperty('is_duplicate')
                        ? response.is_duplicate
                          ? 'duplicate'
                          : 'not duplicate'
                        : 'no info',
                    });
                    resolve();
                  } else if (!response.valid_error && response.desc) {
                    console.log('response.valid_error.email', response);
                    reject(response.desc);
                  } else if (response.valid_error.email || response.valid_error.password) {
                    console.log('response.valid_error.email', response);
                    const passwordErr = response.valid_error.password
                      ? response.valid_error.password
                      : '';
                    const emailErr = response.valid_error.email ? response.valid_error.email : '';
                    reject(emailErr.toString() + passwordErr.toString());
                  } else if (response.valid_error.proofy) {
                    console.log('response.valid_error.proofy', response.valid_error.proofy);
                    const emailErrProofy = response.valid_error.proofy ? response.valid_error.proofy : '';
                    reject(emailErrProofy.toString());
                  } else {
                    reject('Some error happened');
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            },
            (error) => {
              console.log(error);
            }
          );
        };
      });
    },
    /**
     * Проверяет регистрацию на БОТа. Если регистрация с 1 IP больше 3 раз -
     * вызываем показ капчи
     */
    checkRegistrationOnBot() {
      return new Promise((resolve, reject) => {
        try {
          const arr = JSON.parse(window.localStorage.login);
          const uniq = uniqBy(arr, 'ip');
          if (uniq.length > 1) {
            resolve({
              // разные IP
              status: true,
              showCaptcha: false,
            });
          } else if (uniq.length && arr.length > CONFIG.registration.triesCount - 1) {
            const item = arr[arr.length - 1];
            const timeNow = Date.now();

            if (timeNow < +item.time + CONFIG.registration.captchaInterval * 60 * 1000) {
              // показать капчу
              resolve({
                status: true,
                showCaptcha: true,
              });
            } else {
              // прошло больше времени
              resolve({
                status: true,
                showCaptcha: false,
              });
            }
          } else {
            // разные ИП или входов меньше @CONFIG.registration.triesCount
            resolve({
              status: true,
              showCaptcha: false,
            });
          }
        } catch (e) {
          reject({
            status: false,
          });
        }
      });
    },
    /**
     * Получение IP пользователя
     *
     * @return {Promise}
     */
    getIP() {
      return new Promise((resolve) => {
        this.$http.get('https://api.ipify.org?format=json').then(
          (r) => {
            writeIP(r.body.ip || '');
            resolve({
              ip: r.body.ip || '',
              user_agent: window.navigator.userAgent,
            });
          },
          () => {
            resolve({
              ip: '',
              user_agent: window.navigator.userAgent,
            });
          }
        );
      });
    },
  },
};

export const MAIL_MODAL = {
  computed: {
    mailModal() {
      return this.$store.getters.mailModal;
    },
  },
  methods: {
    /**
     * Обновить состояние модалки с письмом
     *
     * @param user {object}
     * @param scope {object}
     */
    toggleMailModal(user, scope) {
      const self = scope || this; // allow to use it in other mixins with scope ONLY to OPEN modal
      self.$store.commit('updateMailModal', {
        open: !scope ? !this.mailModal.open : true,
        to: user || '',
      });
    },
    /**
     * Обновить состояние модалки с письмом c загрузкой данных о профиля с сервера
     *
     * @param user {number} user ID
     * @param scope {object}
     * @param data {object} - некоторые данные для письма
     * @param isAnswer {boolean}
     * @param womanCanAnswer {boolean}
     */
    toggleLoadMailUser(user, scope, { data, isAnswer, womanCanAnswer }) {
      const self = scope || this; // allow to use it in other mixins with scope ONLY to OPEN modal
      self.$store.dispatch('loadMailUser', {
        open: !scope ? !this.mailModal.open : true,
        to: user || '',
        data,
        isAnswer,
        womanCanAnswer,
      });
    },
  },
};

export const REGISTRATION_MODAL = {
  methods: {
    /**
     * Открыть модалку с регистрацией
     */
    openRegistrationModal() {
      this.$store.commit('updateRegisterModal', {
        open: true,
      });
    },
  },
};

export const PREVIEW_MODAL = {
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },
  },
  methods: {
    /**
     * Открыть модалку с предпросмотром профиля
     * Если пользователь - клиентка или ширина вьюпорта <= 768 пикс, то осуществляется
     * переход на профайл нужного пользователя
     *
     * @param id {number}
     * @param preview {boolean} - превью профиля (true) или формы регистрации / логина (false)
     */
    openPreviewModal(id, preview) {
      if (this.sex.isWoman || this.deviceWidth <= 991) {
        this.$router.push({ name: 'profile', params: { id } });
        return;
      }

      this.loadPreviewModalInfo(id, preview);
      this.$store.commit('editPrevModal', {
        open: true,
        loader: true,
      });
    },
    /**
     * открыть модалку с предпросмотром профиля с загрузкой данных о профиле с сервера
     * Если пользователь - клиентка или ширина вьюпорта <= 768 пикс, то осуществляется
     * переход на профайл нужного пользователя
     *
     * @param id {number}
     * @param preview {boolean} превью профиля (true) или формы регистрации / логина (false)
     */
    loadPreviewModalInfo(id, preview) {
      if (this.sex.isWoman || this.deviceWidth <= 991) {
        this.$router.push({ name: 'profile', params: { id } });
        return;
      }
      this.$store.dispatch('loadPreviewInfo', {
        id,
        preview,
      });
    },
  },
};

export const LOW_CREDITS_MODAL = {
  computed: {
    creditsModal() {
      return this.$store.getters.lowCreditsModal;
    },
  },
  methods: {
    /**
     * переключить отображение модалки о недостатке кредитов
     */
    toggleCreditsModal() {
      this.$store.commit('updateLowCreditModal', {
        open: !this.creditsModal.open,
      });
    },
    /**
     * открыть модалку
     */
    openCreditsModal() {
      this.$store.commit('updateLowCreditModal', {
        open: true,
      });
    },
  },
};

export const DISPATCH_MODAL = {
  computed: {
    dispatchModal() {
      return this.$store.getters.dispatchModal;
    },
  },
  methods: {
    /**
     * переключить отображение модалки с рассылкой
     */
    toggleDispatchModal() {
      this.$store.commit('updateDispatchModal', {
        open: !this.dispatchModal.open,
      });
    },
  },
};

export const RECAPTCHA_MODAL = {
  computed: {
    recaptchaModal() {
      return this.$store.getters.recaptchaModal;
    },
  },
  methods: {
    /**
     * Открыть модалку с проверкой капчи
     *
     * @param successCallback - функция, которая должна выполниться при успешной проверке капчи
     * @param failCallback - функция, которая должна выполниться при провальной проверке капчи
     */
    openRecaptchaModal(successCallback, failCallback, scope) {
      scope.$store.commit('updateRecaptchaModal', {
        open: true,
        callback: {
          success: successCallback,
          fail: failCallback,
        },
      });
    },
    closeRecaptchaModal() {
      this.$store.commit('updateRecaptchaModal', {
        open: false,
        callback: {
          success: '',
          fail: '',
        },
      });
    },
  },
};

/* eslint-disable no-shadow */
import CONFIG from '../../config/config';

const state = {
  config: {
    baseURL: CONFIG.baseURL,
    production: CONFIG.production,
    chatURL: CONFIG.chatURL,
    chatSocketURL: CONFIG.chatSocketURL,
    apiURL: CONFIG.apiURL,
    googleAnalyticsKey: CONFIG.googleAnalyticsKey,
    watermarkLarge: CONFIG.watermarkLarge,
    watermarkSmall: CONFIG.watermarkSmall,
    watermarkAlpha: CONFIG.watermarkAlpha,
    sounds: {
      chatInvite: CONFIG.sounds.chatInvite,
      toast: CONFIG.sounds.toast,
      sys_notification: CONFIG.sounds.sys_notification,
    },
    lifeTimes: {
      alert: CONFIG.lifeTimes.alert,
      notification: CONFIG.lifeTimes.notification,
      invite: CONFIG.lifeTimes.invite,
    },
    registration: {
      captchaInterval: CONFIG.registration.captchaInterval,
      triesCount: CONFIG.registration.triesCount,
    },
    wmnAgreementModal: {
      launchInterval: CONFIG.wmnAgreementModal.launchInterval,
    },
  },
};

const getters = {
  /**
   * получить конфиги сайта
   *
   * @param state
   * @return {state.config|{baseURL, production, chatURL, chatSocketURL,
   * apiURL, googleAnalyticsKey, watermarkLarge, watermarkSmall, watermarkAlpha, sounds, lifeTimes}}
   */
  config(state) {
    return state.config;
  },
};

export default {
  state,
  getters,
};

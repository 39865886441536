<template>
  <div />
</template>

<script>
import Vue from 'vue';

export default {
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    sex() {
      this.createRecord();
    },
  },
  mounted() {
    this.createRecord();
  },
  methods: {
    /**
			 * выбрать локализацию
			 *
			 * @param lang {string}
			 */
    setLocale(lang) {
      Vue.config.lang = lang;
    },
    /**
			 * создать запись в локальном хранилище о локализации пользователя
			 */
    createRecord() {
      if (this.sex.isWoman || window.localStorage.locale === 'ru') {
        window.localStorage.setItem('locale', 'ru');
        this.setLocale('ru');
      } else {
        window.localStorage.setItem('locale', 'en');
        this.setLocale('en');
      }
    },
  },
};
</script>

/**
 * Created by Alex Shvets on 22.04.2017.
 */

export const NEW_USERS = {
  data() {
    return {
      asked: false,
      showPreloader: true,
    };
  },
  methods: {
    /**
     * Загрузить с сервера последних присоединившихся девушек
     *
     * @param limit {number}
     * @return {Promise}
     */
    askWomen(limit) {
      return new Promise((resolve, reject) => {
        this.$http.post('v1/user-guest/last-joined-us-woman', { limit }).then(
          (response) => {
            if (response.body.status) {
              this.$store.commit('setRecentlyJoinedUsers', response.body.result);
              resolve(response.body.result);
            }
          },
          (error) => reject(error)
        );
      });
    },
    /**
     * Загрузить с сервера последних присоединившихся мужчин
     *
     * @param limit {number}
     * @return {Promise}
     */
    askMen(limit) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('v1/news/last-joined-us-man', {
            access_token: window.localStorage['access-token'],
            limit,
          })
          .then(
            (response) => {
              if (response.body.status) {
                this.$store.commit('setRecentlyJoinedUsers', response.body.result);
                resolve(response.body.result);
              } else {
                reject();
              }
            },
            (error) => reject(error)
          );
      });
    },
    /**
     * Вычислить, что именно нужно загружать: последних зарегистрированныж мужчин или девушек
     *
     * @return {Promise}
     */
    askData() {
      this.showPreloader = true;
      return new Promise((resolve) => {
        if (this.sex.isWoman) {
          this.askMen(this.limit).then((r) => {
            resolve(r, true);
          });
          this.asked = true;
        } else if (this.sex.isMan || !window.localStorage['access-token']) {
          this.askWomen(this.limit).then((r) => {
            resolve(r, true);
          });
          this.asked = true;
        }
      });
    },
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
  },
  mounted() {
    this.asked = false;
  },
};
